/* eslint-disable default-case */
import React from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Dialog } from '@material-ui/core';

import './style.css';

export default function SideBar() {
  const [classNameInicio, setClassNameInicio] = React.useState('sideBarUl');
  const [classNameColaboradores, setClassNameColaboradores] =
    React.useState('sideBarUl');
  const [classNameHistorial, setClassNameHistorial] =
    React.useState('sideBarUl');
  const [classNameConfig, setClassNameConfig] = React.useState('sideBarUl');
  const [classNameApproval, setClassNameApproval] = React.useState('sideBarUl');

  const location = useLocation();

  const history = useHistory();

  const handleClick = (path) => {
    history.push(path);
  };

  React.useEffect(() => {
    switch (location.pathname) {
      case '/home':
        setClassNameInicio('sideBarSelectedUl');
        break;
      case '/colaboradores':
        setClassNameColaboradores('sideBarSelectedUl');
        break;
      case '/historial':
        setClassNameHistorial('sideBarSelectedUl');
        break;
      case '/configuracion':
        setClassNameConfig('sideBarSelectedUl');
        break;
      case '/configuracion/datos':
        setClassNameConfig('sideBarSelectedUl');
        break;
      case '/configuracion/sectores':
        setClassNameConfig('sideBarSelectedUl');
        break;
      case '/configuracion/turnos':
        setClassNameConfig('sideBarSelectedUl');
        break;
      case '/alta':
        setClassNameApproval('sideBarSelectedUl');
        break;
    }
  }, [location]);

  const [showCode, setShowCode] = React.useState(false);
  const [commerceCode, setCommerceCode] = React.useState('');

  React.useEffect(() => {
    if (commerceCode?.length > 0) {
      return;
    }

    const commerceData = JSON.parse(localStorage.getItem('commerce_data'));

    setCommerceCode(commerceData?.invite_code);
  }, [commerceCode]);

  return (
    <div className='sideBarDiv'>
      <div className='sideBarDivUl'>
        <div className={classNameInicio} onClick={() => handleClick('/home')}>
          <li className='sideBarLi'>Inicio</li>
        </div>

        <div
          className={classNameColaboradores}
          onClick={() => handleClick('/colaboradores')}
        >
          <li className='sideBarLi'>Colaboradores</li>
        </div>

        <div
          className={classNameHistorial}
          onClick={() => handleClick('/historial')}
        >
          <li className='sideBarLi'>Historial</li>
        </div>

        <div
          className={classNameConfig}
          onClick={() => handleClick('/configuracion')}
        >
          <li className='sideBarLi'>Configuración</li>
        </div>

        <div className={classNameApproval} onClick={() => handleClick('/alta')}>
          <li className='sideBarLi'>Alta de colaboradores</li>
        </div>

        <div className='sideBarDivCode'>
          <button
            className='modalBtnSecondary'
            onClick={() => setShowCode(true)}
          >
            Código de alta
          </button>
        </div>
      </div>

      <div className='codeDialogBtn'>
        {/* <button
                    className="modalBtnSecondary"
                    onClick={() => setShowCode(true)}
                >
                    Código de alta
                </button> */}

        <Dialog open={showCode} onClose={() => setShowCode(false)}>
          <div className='codeDialogOuter'>
            <h2>Código de alta</h2>
            <p>
              Para dar de alta a los colaboradores, ellos deben ingresar el
              código de tu comercio desde su{' '}
              <span style={{ fontWeight: 'bold' }}>App de Tipie</span>. No
              olvides confirmar el alta desde el{' '}
              <span style={{ fontWeight: 'bold' }}>Panel de control</span> en{' '}
              <span style={{ fontWeight: 'bold' }}>Alta de colaboradores</span>.
            </p>
            <div className='codeDialogInner'>
              {/* Add the QR later on */}
              <div>
                <div className='manualCode'>{commerceCode}</div>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
}
