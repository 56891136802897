/* eslint-disable default-case */
import React, { createElement } from 'react';
import ColaboradorItem from '../../ColaboradorItem';
import ModalActionBar from '../../ModalActionBar';
import SuccessModal from '../SuccessModal';
// import Loading from '../../Loading';

import './style.css';

import { AbstractService } from '../../../services/abstractService';

export default function ColaboradoresModal(props) {
  const [display, setDisplay] = React.useState('none');
  const [searchWord, setSearchWord] = React.useState('');
  const [isSearching, setIsSearching] = React.useState(false);
  const [filteredArray, setFilteredArray] = React.useState([]);
  const [hasError, setHasError] = React.useState(false);
  const [visibleSuccess, setVisibleSuccess] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState('');
  const [modalBtn, setModalBtn] = React.useState('');
  const [disable, setDisable] = React.useState(true);
  const [showLoading, setShowLoading] = React.useState(false);

  const [arrayAddEmployee, setArrayAddEmployee] = React.useState([]);
  const [arrayRemoveEmployee, setArrayRemoveEmployee] = React.useState([]);

  const [users, setUsers] = React.useState([]);

  const sectorData = props.sector;

  if (users) {
    users.sort((a, b) => {
      const textA = a.active;
      const textB = b.active;
      return textB ? 1 : textA ? -1 : 0;
    });
  }

  const modalRef = React.useRef();

  const scrollBackToTop = () => {
    modalRef.current.scrollTop = 0;
  };

  const handleDisplay = () => {
    scrollBackToTop();
    setDisplay('none');
    setUsers([]);
  };

  const handleSearch = (e) => {
    const searchWord = e.target.value.toLowerCase();

    setFilteredArray(
      users.filter((x) => {
        return (
          x.nickname?.toLowerCase().includes(searchWord) ||
          `${x.user.name} ${x.user.last_name}`
            .toLowerCase()
            .includes(searchWord)
        );
      }),
    );
    setIsSearching(true);
  };

  //* Alta/baja empleados del turno activo
  const handleCancel = () => {
    arrayRemoveEmployee.length = 0;
    arrayAddEmployee.length = 0;
    setIsSearching(false);
    handleDisplay();
  };

  const handleChangeArray = (value, employeeId) => {
    if (value === 'ADD') {
      setArrayAddEmployee((prevArray) => [...prevArray, employeeId]);
    } else if (value === 'REMOVE') {
      setArrayRemoveEmployee((prevArray) => [...prevArray, employeeId]);
    }
  };

  const handleConfirm = async () => {
    setDisable(true);
    setShowLoading(true);
    const body = {
      shift_id: props.activeShift,
      employees_in: arrayAddEmployee,
      employees_out: arrayRemoveEmployee,
    };

    const editCurrentEmployeeList = {
      url: `${process.env.REACT_APP_BFF_WEB_URL}/commerce/active_shift/employee_activity`,
      method: 'POST',
      data: body,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('loggedUser')}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      // setShowLoading(true);
      setHasError(false);
      await AbstractService(editCurrentEmployeeList);

      // Id de empleados presentes y ausentes en el turno activo por sector
      const updateEmployees = {
        sector: props.sector.id,
        employeesIn: arrayAddEmployee,
        employeesOut: arrayRemoveEmployee,
      };
      localStorage.setItem('updateEmployees', JSON.stringify(updateEmployees));

      // Largo del array de empleados actualizado
      const employeesInSector = props.sector.employees?.filter(
        (employee) => employee.active,
      ).length;
      const arrayAddEmployeeLength = arrayAddEmployee.length;
      const arrayRemoveEmployeeLength = arrayRemoveEmployee.length;
      const arrayEmployeesUpdate =
        arrayAddEmployeeLength - arrayRemoveEmployeeLength;
      const updateEmployeesLength = {
        sector: props.sector.id,
        employeesArrayUpdated: employeesInSector + arrayEmployeesUpdate,
      };

      localStorage.setItem(
        'updateEmployeesLength',
        JSON.stringify(updateEmployeesLength),
      );

      setShowLoading(false);
    } catch (error) {
      setHasError(true);
      // setShowLoading(false);
    }

    setModalTitle('¡Colaboradores actualizados con éxito!');
    setModalBtn('Aceptar');
    setVisibleSuccess(true);
  };

  React.useEffect(() => {
    if (props.visible) {
      setDisplay('block');
    } else if (sectorData) {
      setUsers(sectorData?.employees);
    }
  }, [props.visible, sectorData]);

  React.useEffect(() => {
    if (arrayAddEmployee.length > 0 || arrayRemoveEmployee.length > 0) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [arrayAddEmployee, arrayRemoveEmployee]);

  return (
    <div
      style={{
        display,
        position: 'fixed',
        zIndex: 22,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        overflow: 'auto',
        backgroundColor: 'rgb(0,0,0)',
        backgroundColor: 'rgba(0,0,0,0.4)',
      }}
      onClick={handleDisplay}
    >
      <div
        className={
          props.firstLogin
            ? 'modalColaboradoresContentFirstLogin'
            : 'modalColaboradoresContent'
        }
        ref={modalRef}
        onClick={(e) => {
          // do not close modal if anything inside modal content is clicked
          e.stopPropagation();
        }}
      >
        <p className='modalColaboradoresTitle'>
          Colaboradores en {props.sector?.name ?? '-'}
        </p>
        <p className='modalColaboradoresSubtitle'>
          Indica los colaboradores que estén{' '}
          <span style={{ fontWeight: 'bold' }}>activos</span> en este turno.
        </p>
        <div className='modalColaboradoresDivInput'>
          <label className='modalColaboradoresLabel'>
            <ion-icon name='search-outline' />
          </label>
          <input
            type='text'
            className='modalColaboradoresInput'
            placeholder='Buscar por colaborador'
            onChange={handleSearch}
          />
        </div>

        <div className='modalColaboradoresDiv'>
          {props.firstLogin || !props.activeShift ? (
            <div className='modalColaboradoresNoAvailableDiv'>
              <h5 className='modalColaboradoresNoAvailableText'>
                Todavía no hay colaboradores
              </h5>
            </div>
          ) : isSearching ? (
            filteredArray.map((z) =>
              z.status === 'APPROVED' ? (
                z.active ? (
                  <div className='itemAndButtonContainer' key={z.user_id}>
                    <ColaboradorItem
                      isPresent
                      status={z.active}
                      modal
                      name={`${z.user.name}`}
                      lastName={`${z.user.last_name}`}
                      id={z.user_id}
                      nickname={z.nickname}
                      key={z.user_id}
                      photo={z.user.profile_photo}
                      ausentEmployeesList={props.notPresentEmployeesList}
                      handleChangeArray={handleChangeArray}
                      updateArray={handleChangeArray}
                      arrayAddEmployee={arrayAddEmployee}
                      arrayRemoveEmployee={arrayRemoveEmployee}
                    />
                  </div>
                ) : (
                  <div className='itemAndButtonContainer' key={z.user_id}>
                    <ColaboradorItem
                      isPresent={false}
                      status={z.active}
                      modal
                      name={`${z.user.name}`}
                      lastName={`${z.user.last_name}`}
                      id={z.user_id}
                      nickname={z.nickname}
                      key={z.user_id}
                      photo={z.user.profile_photo}
                      activeShiftEmployeeList={props.allEmployeesOfShift}
                      handleChangeArray={handleChangeArray}
                      arrayAddEmployee={arrayAddEmployee}
                      updateArray={handleChangeArray}
                    />
                  </div>
                )
              ) : null,
            )
          ) : (
            users.map((x) =>
              x.status === 'APPROVED' ? (
                x.active ? (
                  <div className='itemAndButtonContainer' key={x.user_id}>
                    <ColaboradorItem
                      isPresent
                      status={x.active}
                      modal
                      name={`${x.user.name}`}
                      lastName={`${x.user.last_name}`}
                      id={x.user_id}
                      nickname={x.nickname}
                      photo={x.user.profile_photo}
                      handleChangeArray={handleChangeArray}
                      updateArray={handleChangeArray}
                      arrayAddEmployee={arrayAddEmployee}
                      arrayRemoveEmployee={arrayRemoveEmployee}
                    />
                  </div>
                ) : (
                  <div className='itemAndButtonContainer' key={x.user_id}>
                    <ColaboradorItem
                      isPresent={false}
                      status={x.active}
                      modal
                      name={`${x.user.name}`}
                      lastName={`${x.user.last_name}`}
                      id={x.user_id}
                      nickname={x.nickname}
                      key={x.user_id}
                      photo={x.user.profile_photo}
                      handleChangeArray={handleChangeArray}
                      arrayRemoveEmployee={arrayRemoveEmployee}
                      updateArray={handleChangeArray}
                      arrayAddEmployee={arrayAddEmployee}
                    />
                  </div>
                )
              ) : null,
            )
          )}
        </div>

        {props.firstLogin ? (
          <div className='modalColaboradoresBtnDiv'>
            <button onClick={handleDisplay} className='modalColaboradoresBtn'>
              Aceptar
            </button>
          </div>
        ) : (
          <ModalActionBar
            onPrimaryClick={handleConfirm}
            primaryDisabled={disable}
            onSecondaryClick={handleCancel}
            loading={showLoading}
          />
        )}
      </div>
      <SuccessModal
        visible={visibleSuccess}
        title={modalTitle}
        button={modalBtn}
        refresh
      />
    </div>
  );
}
