/* eslint-disable default-case */
import React from 'react';
import ModalActionBar from '../../../ModalActionBar';
import './style.css';
import { AbstractService } from '../../../../services/abstractService';

import ConfirmPasswordModal from '../../ConfirmPasswordModal';

export default function TurnosModal(props) {
  const [display, setDisplay] = React.useState('none');
  const [displayPassword, setDisplayPassword] = React.useState(false);

  const [startHour, setStartHour] = React.useState('');
  const [startMinutes, setStartMinutes] = React.useState('');

  const [finishHour, setFinishHour] = React.useState('');
  const [finishMinutes, setFinishMinutes] = React.useState('');

  const [startScheduleFinal, setStartScheduleFinal] = React.useState('');
  const [finishScheduleFinal, setFinishScheduleFinal] = React.useState('');

  const [updateShiftName, setUpdateShiftName] = React.useState('');

  const refStartHour = React.useRef(null);
  const refStartMinutes = React.useRef(null);
  const refFinishHour = React.useRef(null);
  const refFinishMinutes = React.useRef(null);
  const refUpdateShiftName = React.useRef(null);

  const [disable, setDisable] = React.useState(true);

  const [succesful, setSuccesful] = React.useState(false);

  const [hasError, setHasError] = React.useState(false);
  const [errorNoNumber, setErrorNoNumber] = React.useState(false);

  const [onInput, setOnInput] = React.useState(false);

  const [placeholderColorOne, setPlaceholderColorOne] = React.useState(false); // gris placeholder hora
  const [placeholderColorTwo, setPlaceholderColorTwo] = React.useState(false); // gris placeholder minuto
  const [placeholderColorThree, setPlaceholderColorThree] =
    React.useState(false); // gris placeholder hora
  const [placeholderColorFour, setPlaceholderColorFour] = React.useState(false); // gris placeholder minuto

  // EYE ICON
  const [visibilityPassword, setVisibilityPassword] = React.useState(false);

  const togglePassword = () => {
    if (visibilityPassword) {
      setVisibilityPassword(false);
    } else {
      setVisibilityPassword(true);
    }
  };
  // EYE ICON

  const { fromHour } = props;
  const { toHour } = props;

  // Horarios a modificar del turno
  // fromHour
  const firstScheduleHour = `${fromHour[0]}${fromHour[1]}`;
  const firstScheduleMinutes = `${fromHour[3]}${fromHour[4]}`;
  // toHour
  const lastScheduleHour = `${toHour[0]}${toHour[1]}`;
  const lastScheduleMinutes = `${toHour[3]}${toHour[4]}`;

  // const firstScheduleHour = '9';
  // const firstScheduleMinutes = '9';

  const handleDisplay = () => {
    refUpdateShiftName.current.value = '';
    refStartHour.current.value = '';
    refStartMinutes.current.value = '';
    refFinishHour.current.value = '';
    refFinishMinutes.current.value = '';
    setUpdateShiftName('');
    setStartHour('');
    setStartMinutes('');
    setFinishHour('');
    setFinishMinutes('');
    setDisplay('none');
    setPlaceholderColorOne(false);
    setPlaceholderColorTwo(false);
    setOnInput(false);

    if (props.hideModal) {
      props.hideModal();
    }
  };

  React.useEffect(() => {
    if (props.visible) {
      setDisplay('block');
    }
  }, [props.visible]);

  React.useEffect(() => {
    setDisable(true);

    if (updateShiftName.length > 0) {
      setDisable(false);
    }

    if (
      startHour.length === 2 ||
      startMinutes.length === 2 ||
      finishHour.length === 2 ||
      finishMinutes.length === 2
    ) {
      setDisable(false);
    }

    if (errorNoNumber) {
      setDisable(true);
    }
  }, [updateShiftName, startHour, startMinutes, finishHour, finishMinutes]);

  const handleValue = (e, value) => {
    switch (value) {
      case 'updateShiftName':
        setUpdateShiftName(e.target.value);
        break;
      case 'startHour':
        setStartHour(e.target.value);
        break;
      case 'startMinutes':
        setStartMinutes(e.target.value);
        break;
      case 'finishHour':
        setFinishHour(e.target.value);
        break;
      case 'finishMinutes':
        setFinishMinutes(e.target.value);
        break;
    }
  };

  const handleFocus = (e, value) => {
    if (e === 'inicio') {
      if (value === 1) {
        // hora inicio
        setPlaceholderColorOne(true);
      } else if (value === 2) {
        // minutos inicio
        setPlaceholderColorTwo(true);
      }
    } else if (e === 'fin') {
      if (value === 3) {
        // hora fin
        setPlaceholderColorThree(true);
      } else if (value === 4) {
        // minutos fin
        setPlaceholderColorFour(true);
      }
    }
  };

  const handleBlur = (e) => {
    if (e === 'fin') {
      if (finishHour === '' && finishMinutes === '') {
        setPlaceholderColorThree(false);
        setPlaceholderColorFour(false);
      } else {
        setPlaceholderColorThree(true);
        setPlaceholderColorFour(true);
      }
    } else if (e === 'inicio') {
      if (startHour === '' && startMinutes === '') {
        setPlaceholderColorOne(false);
        setPlaceholderColorTwo(false);
      } else {
        setPlaceholderColorOne(true);
        setPlaceholderColorTwo(true);
      }
    }
  };

  const handleOnInput = (e, type) => {
    setOnInput(true);
    if (e.target.value.length > 2) {
      const finalInput = [];
      finalInput.push(e.target.value[0]);
      finalInput.push(e.target.value[1]);
      return (e.target.value = finalInput.toString().replace(',', ''));
    }
    if (e.target.value === '') {
      return (e.target.value = '');
    }

    if (type === 'hour') {
      if (e.target.value > 24) {
        return (e.target.value = '');
      }
      return e.target.value;
    }
    if (type === 'minutes') {
      if (e.target.value > 59) {
        return (e.target.value = '');
      }
      return e.target.value;
    }
  };

  const handleDeleteShift = () => {
    if (props.activeShift === props.shiftId) {
      handleDisplay(disable);
    } else {
      handleDisplay();
      props.handleDeleteTurno(props.shift, props.shiftId);
    }
  };

  // Validación de horarios (acepta 00:00 a 23:59)
  const validateSchedule = () => {
    const numberRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;

    let startSchedule;
    let finishSchedule;

    if (startHour && startMinutes && finishHour && finishMinutes) {
      startSchedule = `${startHour}:${startMinutes}`;
      finishSchedule = `${finishHour}:${finishMinutes}`;
    } else {
      // En caso de no recibir alguno de los parametros se usa el anterior (fromHour ó toHour)
      if (startHour && !startMinutes) {
        startSchedule = `${startHour}:${firstScheduleMinutes}`;
      } else if (!startHour && startMinutes) {
        startSchedule = `${firstScheduleHour}:${startMinutes}`;
      } else if (startHour && startMinutes) {
        startSchedule = `${startHour}:${startMinutes}`;
      }

      if (finishHour && !finishMinutes) {
        finishSchedule = `${finishHour}:${lastScheduleMinutes}`;
      } else if (!finishHour && finishMinutes) {
        finishSchedule = `${lastScheduleHour}:${finishMinutes}`;
      } else if (finishHour && finishMinutes) {
        finishSchedule = `${finishHour}:${finishMinutes}`;
      }
    }

    if (numberRegex.test(startSchedule) || numberRegex.test(finishSchedule)) {
      setStartScheduleFinal(startSchedule);
      setFinishScheduleFinal(finishSchedule);
      setErrorNoNumber(false);
      setDisplayPassword(true);
    } else {
      if (onInput) {
        return setErrorNoNumber(true);
      }
      return setErrorNoNumber(false);
    }
  };

  const updateShift = async (startScheduleFinal, finishScheduleFinal) => {
    try {
      const body = {
        shift_id: props.shiftId,
        name: updateShiftName || props.shift,
        from_hour: startScheduleFinal || fromHour,
        to_hour: finishScheduleFinal || toHour,
      };

      const editShiftService = {
        url: `${process.env.REACT_APP_BFF_WEB_URL}/commerce/shifts/update`,
        method: 'PATCH',
        data: body,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('loggedUser')}`,
          'Content-Type': 'application/json',
        },
      };

      const res = await AbstractService(editShiftService);
      const shiftEditedData = {
        id: props.shiftId,
        name: updateShiftName || props.shift,
        from_hour: startScheduleFinal || fromHour,
        to_hour: finishScheduleFinal || toHour,
      };

      localStorage.setItem('shift_editedData', JSON.stringify(shiftEditedData));
      props.handleChange();
    } catch {
      setHasError(false);
    }
  };

  const hideDisplayPassword = () => {
    setDisplayPassword(false);
  };

  const handleConfirmShift = async () => {
    setDisplayPassword(true);
    validateSchedule(startScheduleFinal, finishScheduleFinal);
    if (!errorNoNumber) {
      setDisplayPassword(true);
    } else {
      setDisplayPassword(false);
    }
  };

  const handleEditShift = async () => {
    try {
      await updateShift(startScheduleFinal, finishScheduleFinal);
      handleDisplay();
      setOnInput(false);
      props.handleSuccessTurno('editShift', props.shift, { id: props.shiftId });
      setSuccesful(true);
    } catch (error) {
      setHasError(true);
    }
  };

  const handleDisplayErrorNumber = () => {
    setDisplay('none');
    setErrorNoNumber(false);
    if (props.hideModal) {
      props.hideModal();
    }
  };

  return (
    <div
      style={{
        display,
        position: 'fixed',
        zIndex: 22,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        overflow: 'auto',
        backgroundColor: 'rgb(0,0,0)',
        backgroundColor: 'rgba(0,0,0,0.4)',
      }}
      onClick={handleDisplay}
    >
      <div
        className='modalTurnosContent'
        onClick={(e) => {
          // do not close modal if anything inside modal content is clicked
          e.stopPropagation();
        }}
      >
        <p className='modalTurnosTitle'>Turno {props.shift}</p>
        <p className='modalTurnosParagraph'>
          Acá podes configurar el horario de inicio y finalización del turno.
        </p>

        <div className='modalTurnosDivInput'>
          <div className='modalTurnosDiv'>
            <input
              className='modalTurnosInputName'
              type='text'
              placeholder='Cambiar nombre del turno'
              ref={refUpdateShiftName}
              onChange={(e) => handleValue(e, 'updateShiftName')}
            />

            <div className='modalTurnosDivOne'>
              <p className='modalTurnosInputParagraph'>Inicio</p>
              <div className='modalTurnosDivRow'>
                <input
                  type='number'
                  className={
                    placeholderColorOne
                      ? 'modalTurnosScheduleInput'
                      : 'modalTurnosScheduleInputGray'
                  }
                  placeholder={firstScheduleHour}
                  onInput={(e) => handleOnInput(e, 'hour')}
                  ref={refStartHour}
                  onChange={(e) => handleValue(e, 'startHour')}
                  onBlur={() => handleBlur('inicio', 1)}
                  onFocus={() => handleFocus('inicio', 1)}
                />
                <input
                  type='number'
                  className={
                    placeholderColorTwo
                      ? 'modalTurnosScheduleInput'
                      : 'modalTurnosScheduleInputGray'
                  }
                  placeholder={firstScheduleMinutes}
                  onInput={(e) => handleOnInput(e, 'minutes')}
                  ref={refStartMinutes}
                  onChange={(e) => handleValue(e, 'startMinutes')}
                  onBlur={() => handleBlur('inicio', 2)}
                  onFocus={() => handleFocus('inicio', 2)}
                />
              </div>
            </div>

            <div className='modalTurnosDivOne'>
              <p className='modalTurnosInputParagraph'>Fin</p>
              <div className='modalTurnosDivRow'>
                <input
                  type='number'
                  className={
                    placeholderColorThree
                      ? 'modalTurnosScheduleInput'
                      : 'modalTurnosScheduleInputGray'
                  }
                  placeholder={lastScheduleHour}
                  onInput={(e) => handleOnInput(e, 'hour')}
                  ref={refFinishHour}
                  onChange={(e) => handleValue(e, 'finishHour')}
                  onBlur={() => handleBlur('fin', 3)}
                  onFocus={() => handleFocus('fin', 3)}
                />
                <input
                  type='number'
                  className={
                    placeholderColorFour
                      ? 'modalTurnosScheduleInput'
                      : 'modalTurnosScheduleInputGray'
                  }
                  placeholder={lastScheduleMinutes}
                  onInput={(e) => handleOnInput(e, 'minutes')}
                  ref={refFinishMinutes}
                  onChange={(e) => handleValue(e, 'finishMinutes')}
                  onBlur={() => handleBlur('fin', 4)}
                  onFocus={() => handleFocus('fin', 4)}
                />
              </div>
            </div>
          </div>
        </div>
        {props.activeShift === props.shiftId ? (
          <ModalActionBar
            onPrimaryClick={handleConfirmShift}
            primaryDisabled={disable}
            onSecondaryClick={handleDisplay}
          />
        ) : (
          <ModalActionBar
            onPrimaryClick={handleConfirmShift}
            primaryDisabled={disable}
            onSecondaryClick={handleDeleteShift}
            secondaryLabel='Eliminar turno'
          />
        )}
      </div>

      <ConfirmPasswordModal
        visiblePassword={displayPassword}
        hideVisiblePassword={hideDisplayPassword}
        onSuccess={handleEditShift}
      />

      {errorNoNumber ? (
        <div
          style={{
            display: 'block',
            position: 'fixed',
            zIndex: 22,
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            overflow: 'auto',
            backgroundColor: 'rgb(0,0,0)',
            backgroundColor: 'rgba(0,0,0,0.4)',
          }}
          onClick={handleDisplay}
        >
          <div
            className='modalEditTurnoContent'
            onClick={(e) => {
              // do not close modal if anything inside modal content is clicked
              e.stopPropagation();
            }}
          >
            <h2 className='modalEditTurnoTitle'>Error</h2>
            <p>Debes ingresar números</p>

            <button
              className='modalErrorNumber'
              onClick={handleDisplayErrorNumber}
            >
              Volver
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
}
