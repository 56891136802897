import React from 'react';

import './style.css';

export default function PreconfigShiftSectorModal(props) {
  return (
    <div
      style={{
        display: props.displayPreconf,
        position: 'fixed',
        zIndex: 22,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        overflow: 'auto',
        backgroundColor: 'rgba(0,0,0,0.4)',
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        className='modalPreconfigContent'
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div>
          <h2 className='modalPreconfigTitle'>
            {props.shiftsData ? 'Turnos' : 'Sectores'}
          </h2>
          <p className='modalPreconfigParagraph'>
            {props.shiftsData
              ? 'Los horarios de los turnos están predeterminados, recordá modificarlos de acuerdo a la necesidad de tu comercio.'
              : 'Los sectores y sus porcentajes están predeterminados, recordá modificarlos de acuerdo a la necesidad de tu comercio.'}
          </p>

          <div className='modalPreconfigButtonDiv'>
            <button className='modalPreconfigButton' onClick={props.onClick}>
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
