import './style.css';
import CircularProgress from '@mui/material/CircularProgress';

function ModalButton(props) {
  let className = 'modalBtn';
  className += props.primary ? 'Primary' : 'Secondary';
  className += props.disabled ? ' modalBtnDisabled' : '';

  if (props.classOverride) {
    className += ` ${props.classOverride}`;
  }

  return (
    <button
      className={className}
      onClick={props.onClick}
      disabled={props.disabled}
      type={props.type}
    >
      {props.children}
    </button>
  );
}

export default function ModalActionBar(props) {
  const primaryLabel = props.primaryLabel ?? 'Confirmar';
  const secondaryLabel = props.secondaryLabel ?? 'Cancelar';

  const primaryOverride = props.primaryClass ?? null;
  const secondaryOverride = props.secondaryClass ?? null;

  return (
    <div className='modalActionBar'>
      <ModalButton
        onClick={props.onSecondaryClick}
        disabled={props.secondaryDisabled}
        type={props.secondaryType}
        classOverride={secondaryOverride}
      >
        {secondaryLabel}
      </ModalButton>
      <ModalButton
        onClick={props.onPrimaryClick}
        primary
        disabled={props.primaryDisabled}
        type={props.primaryType}
        classOverride={primaryOverride}
      >
        {props.loading || props.btnLoading ? (
          <div
            style={{
              width: 68,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress color='inherit' size={14} />
          </div>
        ) : (
          <>{primaryLabel}</>
        )}
      </ModalButton>
    </div>
  );
}
