import React from 'react';
import numbro from 'numbro';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import iconOK from '../../assets/historial-transaction-ok.svg';
import iconPending from '../../assets/historial-transaction-pending.svg';
import iconError from '../../assets/historial-transaction-error.svg';

function formatTime(ts) {
  const date = new Date(ts);
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
}

function StatusWidget({ status }) {
  const containerStyle = { display: 'flex', flexDirection: 'row' };
  const iconStyle = { marginRight: '6.7px' };
  switch (status) {
    case 'OK':
    case 'FINISHED':
      return (
        <div style={containerStyle}>
          <img src={iconOK} alt='ok' style={iconStyle} />
          Exitoso
        </div>
      );
    case 'AWAITING':
      return (
        <div style={containerStyle}>
          <img src={iconPending} alt='pendiente' style={iconStyle} />
          Pendiente
        </div>
      );
    case 'ERROR':
      return (
        <div style={containerStyle}>
          <img src={iconError} alt='error' style={iconStyle} />
          Error
        </div>
      );
    default:
      return <></>;
  }
}

export default function OpsTable(props) {
  const { opsList } = props;

  let filteredOps = (opsList ?? []).filter((op) => {
    if (op.type === 'TRANSACTION') {
      return true;
    }
    if (op.type === 'SHIFT_END' && op.arguments.fee) {
      return true;
    }
    return false;
  });

  filteredOps = filteredOps?.sort((a, b) => b.timestamp - a.timestamp);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Hora</TableCell>
            <TableCell>N° de operación</TableCell>
            <TableCell>Monto</TableCell>
            <TableCell>Estado</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredOps.map((op, i) => (
            <TableRow key={i}>
              <TableCell>{formatTime(op.timestamp)}</TableCell>
              <TableCell>
                {op.type === 'SHIFT_END' ? 'Cierre de turno' : op.uuid}
              </TableCell>
              <TableCell>
                {op.type === 'SHIFT_END'
                  ? `Comisión - ${numbro(op.arguments.totalFee.toFixed(2))
                      .formatCurrency({
                        thousandSeparated: true,
                        mantissa: 2,
                      })
                      .replace('.', '#')
                      .replace(',', '.')
                      .replace('#', ',')}`
                  : `${numbro(op.arguments.amount)
                      .formatCurrency({
                        thousandSeparated: true,
                        mantissa: 2,
                      })
                      .replace('.', '#')
                      .replace(',', '.')
                      .replace('#', ',')}`}
              </TableCell>
              <TableCell>
                {op.type === 'SHIFT_END' ? (
                  <StatusWidget status='OK' />
                ) : (
                  <StatusWidget status={op.arguments.status} />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
