/* eslint-disable default-case */
import React from 'react';
import { Link } from 'react-router-dom';
import TopNavBar from '../../components/TopNavBar';
import SideBar from '../../components/Sidebar';
import EnhancedTable from '../../components/Table/ColaboradoresTable';
import DisabledColaboradoresModal from '../../components/Modal/DisabledColaboradores';
// import NewColaborador from '../../components/Modal/ColaboradoresModal/NewColaborador';
import './style.css';
import EditColaboradorModal from '../../components/Modal/ColaboradoresModal/EditColaborador';
import SuccessModal from '../../components/Modal/SuccessModal';
import CommerceMenu from '../../components/CommerceDropdown';

export default function Colaboradores({ content }) {
  const [visible, setVisible] = React.useState(false);
  // const [visibleNew, setVisibleNew] = React.useState(false);
  const [visibleEdit, setVisibleEdit] = React.useState(false);
  const [visibleSuccessModal, setVisibleSuccessModal] = React.useState(false);

  const [filteredArray, setFilteredArray] = React.useState([]);
  const [coincidence, setCoincidenceSearch] = React.useState([]);
  const [editId, setEditId] = React.useState('');
  const [editName, setEditName] = React.useState('');
  const [editNickname, setEditNickname] = React.useState('');
  const [editFromHour, setEditFromHour] = React.useState('');
  const [editToHour, setEditToHour] = React.useState('');
  const [editPercentage, setEditPercentage] = React.useState('');
  const [editShift, setEditShift] = React.useState('');
  const [editSector, setEditSector] = React.useState('');
  const [editPfp, setEditPfp] = React.useState(null);
  const [editEmail, setEditEmail] = React.useState('');
  const [showSearch, setShowSearch] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState('');
  const [modalBtn, setModalBtn] = React.useState('');

  const commerceName = localStorage.getItem('commerceName');

  const [colaboradoresList, setColaboradoresList] = React.useState(
    content[0].employeesList,
  );
  const [sectorsData, setSectorsData] = React.useState(content[0].sectorsData);
  const [shiftsData, setShiftsData] = React.useState(content[0].shiftsData);

  React.useEffect(() => {
    if (visible) {
      setVisible(false);
    } else if (visibleEdit) {
      setVisibleEdit(false);

      // } else if (visibleNew) {
      //   setVisibleNew(false);
    } else if (visibleSuccessModal) {
      setVisibleSuccessModal(false);
    }
  }, [
    visible,
    visibleEdit,
    // visibleNew,
    visibleSuccessModal,
  ]);

  const handleEdit = (
    value,
    nickname,
    id,
    shift,
    sector,
    pfp,
    fromHour,
    toHour,
    percentage,
    email,
  ) => {
    if (value === '-') {
      setVisible(true);
    } else {
      setEditName(value);
      setEditId(id);
      setEditPfp(pfp);
      setEditNickname(nickname);
      setEditFromHour(fromHour);
      setEditToHour(toHour);
      setEditPercentage(percentage);
      setEditShift(shift);
      setEditSector(sector);
      setVisibleEdit(true);
      setEditEmail(email);
    }
  };

  const handleSearch = (e) => {
    const setSearchInput = e.target.value.toLowerCase();

    setFilteredArray(
      colaboradoresList.filter((x) => {
        return (
          x.nickname?.toLowerCase().includes(setSearchInput) ||
          `${x.user.name ?? ''} ${x.user.last_name}`
            .toLowerCase()
            .includes(setSearchInput) ||
          `${x.sector?.name ?? ''}`.toLowerCase().includes(setSearchInput) ||
          `${x.shift?.name ?? ''}`.toLowerCase().includes(setSearchInput)
        );
      }),
    );

    setShowSearch(true);

    if (e.target.value < 1) {
      setShowSearch(false);
    }
  };

  const handleBlur = () => {
    setShowSearch(false);
  };

  // const showModalNew = () => {
  //   setVisibleNew(true);
  // }

  const successEditColaborador = () => {
    setVisibleEdit(false);
    setModalTitle('Cambio realizado con éxito');
    setModalBtn('Volver');
    setVisibleSuccessModal(true);
  };

  // const successNewColaborador = () => {
  //   setVisibleNew(false);
  //   setModalTitle('¡Agregaste un nuevo colaborador!');
  //   setModalBtn('Aceptar');
  //   setVisibleSuccessModal(true);
  // };

  return (
    <div className='colaboradoresDiv'>
      <TopNavBar>
        <CommerceMenu>
          <ion-icon name='person-outline' className='userIcon' />
          <h4 className='topNavBarChildrenTitle'>{commerceName}</h4>
          <ion-icon name='chevron-down-outline' className='arrowDownIcon' />
        </CommerceMenu>
      </TopNavBar>
      <div className='mainContentColaboradoresDiv'>
        <SideBar />

        <div className='colaboradoresCardContainerDiv'>
          <div className='colaboradoresHeader'>
            <h1 className='colaboradoresTitle'>Colaboradores</h1>
            <p className='colaboradoresParagraph'>
              Estas son todas las personas que trabajan en tu comercio
            </p>

            <div className='colaboradoresCardContent'>
              <div className='colaboradoresDivInput'>
                <label className='colaboradoresLabel'>
                  <ion-icon name='search-outline' />
                </label>
                <input
                  type='search'
                  className='colaboradoresInput'
                  placeholder='Buscar por colaborador, sector o turno'
                  onChange={handleSearch}
                  onBlur={handleBlur}
                />

                {/* <div className={showSearch ? "colaboradoresSearchDiv" : "colaboradoresSearchDivNone"}>

                </div> */}
              </div>
              {/* <button className="nuevoColaboradorBtn" onClick={showModalNew}>Nuevo colaborador</button> */}
            </div>
          </div>

          <div className='colaboradoresTableDiv'>
            <EnhancedTable
              handleEdit={handleEdit}
              employeesList={colaboradoresList}
              searchArray={filteredArray}
            />
          </div>
        </div>
      </div>
      {/* <NewColaborador visible={visibleNew} successNewColaborador={successNewColaborador} sectorsData={content[0].sectorsData}
        shiftsData={content[0].shiftsData} /> */}
      <DisabledColaboradoresModal visible={visible} />
      <EditColaboradorModal
        visible={visibleEdit}
        name={editName}
        id={editId}
        nickname={editNickname}
        profilePhoto={editPfp}
        currentShift={editShift}
        currentSector={editSector}
        currentFromHour={editFromHour}
        currentToHour={editToHour}
        currentPercentage={editPercentage}
        successEditColaborador={successEditColaborador}
        sectorsData={sectorsData}
        shiftsData={shiftsData}
        email={editEmail}
      />
      <SuccessModal
        visible={visibleSuccessModal}
        title={modalTitle}
        button={modalBtn}
        refresh
      />
    </div>
  );
}
