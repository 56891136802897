import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import CircularProgress from '@mui/material/CircularProgress';
import Logo from '../../../assets/tipie-vectores-01.png';
import { AbstractService } from '../../../services/abstractService';
import { parseCurrencyStringToNumber } from '../../../utils/numbers';
import './style.css';

export default function AddTipQRForm({ content }) {
  const history = useHistory();
  const location = useLocation();
  const { commerce_id } = location.state;
  const [disabled, setDisabled] = React.useState(true);

  const [amount, setAmount] = React.useState('');
  const [names, setNames] = React.useState('');
  const [docNumber, setDocNumber] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const handleChange = (event, set) => {
    set(event.target.value);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleOnInput = (e) => {
    const numberRegex = /^\d+$/;
    const number = parseInt(e.target.value);

    if (numberRegex.test(number)) {
      return e.target.value;
    }
    return (e.target.value = '');
  };

  const handleSubmit = async () => {
    setLoading(true);
    setDisabled(true);
    const addTipService = {
      url: `${process.env.REACT_APP_BFF_WEB_URL}/transactions/create`,
      method: 'POST',
      data: {
        name: names,
        doc_number: docNumber,
        email,
        total: parseCurrencyStringToNumber(amount),
        commerce_id,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await AbstractService(addTipService);
      window.location.href = response.url;
    } catch (error) {
      setDisabled(false);
      setLoading(false);
      history.push('/payment/error');
    }
    setDisabled(false);
    setLoading(false);
  };

  React.useEffect(() => {
    if (names != '' && amount != '' && docNumber != '') {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [names, amount, docNumber]);

  return (
    <div className='QRMainDiv'>
      <header className='QRFormHeaderDiv'>
        <p className='QRMainSubtitle'>
          Para agregar propina, necesitamos que completes los siguientes datos:
        </p>
      </header>

      <form className='QRForm'>
        <NumericFormat
          className='QRFormInput'
          onChange={handleAmountChange}
          value={amount}
          prefix='$'
          allowLeadingZeros
          thousandSeparator='.'
          decimalSeparator=','
          decimalScale={2}
          placeholder='Monto de la propina'
        />
        <input
          className='QRFormInput'
          placeholder='Nombre y apellido'
          value={names}
          onChange={(e) => handleChange(e, setNames)}
        />
        <input
          className='QRFormInput'
          placeholder='DNI'
          value={docNumber}
          type='number'
          onInput={(e) => handleOnInput(e)}
          onChange={(e) => handleChange(e, setDocNumber)}
        />
        <input
          className='QRFormInput'
          placeholder='Mail (opcional)'
          value={email}
          type='email'
          onChange={(e) => handleChange(e, setEmail)}
        />
      </form>

      <footer className='QRFooter'>
        <button
          className={disabled ? 'QRFormConfirmDisabled' : 'QRFormConfirm'}
          disabled={disabled}
          onClick={handleSubmit}
        >
          {loading ? (
            <CircularProgress color='inherit' size={14} />
          ) : (
            'Continuar'
          )}
        </button>
        <img src={Logo} className='QRPrimaryLogo' />
      </footer>
    </div>
  );
}
