/* eslint-disable default-case */
import { useState, useEffect } from 'react';
import TopNavBar from '../../../components/TopNavBar';
import SideBar from '../../../components/Sidebar';
import CommerceMenu from '../../../components/CommerceDropdown';
import './style.css';
import { Grid } from '@mui/material';
import Loading from '../../../components/Loading';
import { useHistory } from 'react-router-dom';

import AddSectorTurnoModal from '../../../components/Modal/ConfiguracionModal/AddSectorShift';
import SuccessModal from '../../../components/Modal/SuccessModal';
import ConfirmSectorTurnoModal from '../../../components/Modal/ConfiguracionModal/AddSectorShift/ConfirmSectorShift';
import TurnosModal from '../../../components/Modal/ConfiguracionModal/Turnos';
import DeleteSectorTurnoModal from '../../../components/Modal/ConfiguracionModal/DeleteSectorTurno';
import PreconfigShiftSectorModal from '../../../components/Modal/ConfiguracionModal/preconfigShiftsSectors';
import { AbstractService } from '../../../services/abstractService';
import ErrorModal from '../../../components/Modal/ErrorModal';

export default function TurnosView() {
  const history = useHistory();
  const [commerceName, setCommerceName] = useState('');
  const [preConfigShift, setPreConfigShift] = useState(false);
  const [preConfigShiftState, setPreConfigShiftState] = useState(false);

  // NUEVO TURNO
  const [addShift, setAddShift] = useState(false);
  const [visibleAdd, setVisibleAdd] = useState(false);

  const [newShiftInfo, setNewShiftInfo] = useState({});

  // CONFIRMAR CONTRASEÑA
  const [visibleConfirmAdd, setVisibleConfirmAdd] = useState(false);
  const [visiblePassword, setVisiblePassword] = useState(false);

  // MODAL SUCCESS
  const [visibleSuccess, setVisibleSuccess] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBtn, setModalBtn] = useState('');

  const [visible, setVisible] = useState(false);

  const [fromHour, setFromHour] = useState('');
  const [toHour, setToHour] = useState('');
  const [shift, setShift] = useState('');
  const [shiftId, setShiftId] = useState('');

  const [shiftToDelete, setShiftToDelete] = useState('');
  const [shiftIdToDelete, setShiftIdToDelete] = useState('');
  const [visibleDelete, setVisibleDelete] = useState(false);

  const [activeShift, setActiveShift] = useState('');
  const [shiftConfigData, setShiftConfigData] = useState('');

  const [newCommerce, setNewCommerce] = useState(false);
  const [displayPreConfig, setDisplayPreConfig] = useState('none');
  const [hasError, setHasError] = useState(false);

  const [modalBlobError, setModalBlobError] = useState(false);

  function sortByFromHour(arr) {
    arr.sort(function (a, b) {
      // Extract hours and minutes from the "from_hour" property
      const [aHours, aMinutes] = a.from_hour.split(':').map(Number);
      const [bHours, bMinutes] = b.from_hour.split(':').map(Number);

      // Compare hours first
      if (aHours !== bHours) {
        return aHours - bHours;
      }

      // If hours are the same, compare minutes
      return aMinutes - bMinutes;
    });
  }

  useEffect(() => {
    const commerceDataName = localStorage.getItem('commerceName');
    setCommerceName(commerceDataName);

    const activeShiftConfig = localStorage.getItem('activeShift');

    setActiveShift(activeShiftConfig);

    const shiftConfig = JSON.parse(localStorage.getItem('shift_config'));
    const newArray = shiftConfig;
    sortByFromHour(newArray);
    setShiftConfigData(newArray);
  }, []);

  useEffect(() => {
    const blob = JSON.parse(localStorage.getItem('shiftsAcceptedBlob'));

    setPreConfigShift(blob);
    setPreConfigShiftState(true);

    if (!preConfigShift && preConfigShiftState) {
      setNewCommerce(true);
      setDisplayPreConfig('block');
    } else {
      setNewCommerce(false);
      setDisplayPreConfig('none');
    }
  }, [preConfigShift, preConfigShiftState]);

  const handleGoBack = () => {
    history.push('/configuracion');
  };

  const handleAddTurno = () => {
    setAddShift(true);
    setVisibleAdd(true);
  };

  const hideAddTurno = () => {
    setAddShift(false);
    setVisibleAdd(false);
  };

  const handleConfirmAddSectorShift = (obj) => {
    setVisibleAdd(false);

    setNewShiftInfo(obj);

    setVisibleConfirmAdd(true);
    setVisiblePassword(true);
  };

  const showModalErrorBlob = () => {
    setModalBlobError(true);
  };

  const hideModalErrorBlob = () => {
    setModalBlobError(false);
  };

  // CONFIRMAR CONTRASEÑA MODAL

  const hideConfirmAdd = () => {
    setVisibleConfirmAdd(false);
    setVisiblePassword(false);
  };

  const hidePassword = () => {
    setVisiblePassword(false);
  };

  const hideDelete = () => {
    setVisibleDelete(false);
    setShiftToDelete('');
    setShiftIdToDelete('');
  };

  const showModal = (shiftName, shiftId, shiftFromHour, shiftToHour) => {
    setVisible(true);
    setShift(shiftName);
    setShiftId(shiftId);
    setFromHour(shiftFromHour);
    setToHour(shiftToHour);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const handleSuccess = () => {
    setVisibleConfirmAdd(false);
    setModalTitle('Cambio realizado con éxito');
    setModalBtn('Aceptar');
    setVisibleSuccess(true);
  };

  const hideSuccess = () => {
    setVisibleSuccess(false);
  };

  const handleAddArray = (arg) => {
    const arrayIds = [];
    shiftConfigData.forEach((x) => arrayIds.push(parseInt(x.id)));

    const maxId = Math.max(...arrayIds);
    const newId = maxId + 1;

    const shiftToAdd = {
      id: newId,
      // label: arg.label,
      name: arg.name,
      from_hour: arg.from_hour,
      to_hour: arg.to_hour,
    };
    const newArray = [...shiftConfigData, shiftToAdd];
    // setConfigshiftsData(newArray);
    const finalArray = newArray;
    sortByFromHour(finalArray);
    setShiftConfigData(finalArray);
    localStorage.setItem('shift_config', JSON.stringify(newArray));
  };

  const handleDeleteTurno = (shift, id) => {
    setVisible(false);
    setShiftToDelete(shift);
    setShiftIdToDelete(id);
    setVisibleDelete(true);
  };

  const handleFilterArray = (id) => {
    const newArray = shiftConfigData.filter((x) => x.id !== id);
    const finalArray = newArray;
    sortByFromHour(finalArray);
    setShiftConfigData(finalArray);
    // guardar en storage **
    localStorage.setItem('shift_config', JSON.stringify(newArray));
  };

  const renderGridItem = (x, id) => {
    return (
      <Grid item xs={4} key={id} paddingBottom={10}>
        <div className='turnosYSectoresMainDiv'>
          <div className='turnosYSectoresTitleDiv'>
            <h2 className='turnosYSectoresCenterTitle'>{x.name}</h2>
            <p className='turnosYSectoresCenterParagraph'>
              {x.from_hour} hs-{x.to_hour} hs
            </p>
          </div>
          <p
            className='turnosYSectoresEditar'
            onClick={() => showModal(x.name, x.id, x.from_hour, x.to_hour)}
          >
            Editar
          </p>
        </div>
      </Grid>
    );
  };

  const handleChangeArray = () => {
    const shiftsEditedData = JSON.parse(
      localStorage.getItem('shift_editedData'),
    );

    const filteredArray = shiftConfigData.map((item) => {
      if (item.id === shiftsEditedData.id) {
        return { ...item, ...shiftsEditedData };
      }
      return item;
    });

    const sortedArray = filteredArray.sort((a, b) =>
      a.description > b.description
        ? 1
        : a.description < b.description
        ? -1
        : 0,
    );
    localStorage.setItem('shift_config', JSON.stringify(sortedArray));
    const newArray = sortedArray;
    sortByFromHour(newArray);
    setShiftConfigData(newArray);
  };

  const handleAcceptPreconf = async (value) => {
    try {
      const body = {
        [`${value}_accepted`]: true,
        tc_accepted_datetime: new Date(),
      };

      const updatePreconfigService = {
        url: `${process.env.REACT_APP_BFF_WEB_URL}/commerce/blobs/update`,
        method: 'PATCH',
        data: body,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('loggedUser')}`,
          'Content-Type': 'application/json',
        },
      };

      await AbstractService(updatePreconfigService);
      setDisplayPreConfig('none');
      setNewCommerce(false);
      const finalBlob = true;
      localStorage.setItem('shiftsAcceptedBlob', JSON.stringify(finalBlob));
    } catch (error) {
      setHasError(true);
      setDisplayPreConfig('none');
      showModalErrorBlob();
    }
  };

  if (
    !commerceName ||
    !activeShift ||
    !shiftConfigData ||
    !preConfigShiftState
  ) {
    return <Loading />;
  }

  return (
    <div className='configuracionDiv'>
      <TopNavBar>
        <CommerceMenu>
          <ion-icon name='person-outline' className='userIcon' />
          <h4 className='topNavBarChildrenTitle'>{commerceName}</h4>
          <ion-icon name='chevron-down-outline' className='arrowDownIcon' />
        </CommerceMenu>
      </TopNavBar>
      <div className='mainContentConfiguracionDiv'>
        <SideBar />

        <div className='turnosYSectoresCardContainerDiv'>
          <div className='turnosYSectoresHeaderDiv'>
            <div>
              <div className='turnosYSectoresGoBackDiv' onClick={handleGoBack}>
                <ion-icon name='chevron-back-outline' size={16} />
                <p className='turnosYSectoresGoBackParagraph'>Volver</p>
              </div>

              <h1 className='turnosYSectoresTitle'>Turnos</h1>
              <p className='turnosYSectoresParagraph'>
                A continuación podes modificar los horarios de los distintos
                turnos.
              </p>
            </div>

            <div className='nuevoTurnoBtnDiv'>
              <button className='nuevoTurnoBtn' onClick={handleAddTurno}>
                Nuevo turno
              </button>
            </div>
          </div>

          <Grid container spacing={2} paddingX='50px'>
            {shiftConfigData
              .sort((a, b) =>
                a.description > b.description
                  ? 1
                  : a.description < b.description
                  ? -1
                  : 0,
              )
              .map((x, id) => renderGridItem(x, id))}
          </Grid>

          <AddSectorTurnoModal
            visible={visibleAdd}
            array={shiftConfigData}
            addShift
            hideModal={hideAddTurno}
            handleConfirmAddSectorShift={handleConfirmAddSectorShift}
          />

          <ConfirmSectorTurnoModal
            visible={visibleConfirmAdd}
            addShift
            newShiftInfo={newShiftInfo}
            onSuccess={handleSuccess}
            handleAddArray={handleAddArray}
            visiblePassword={visiblePassword}
            hideConfirmAdd={hideConfirmAdd}
            hidePassword={hidePassword}
          />

          <SuccessModal
            visible={visibleSuccess}
            hideSuccess={hideSuccess}
            title={modalTitle}
            button={modalBtn}
          />

          <TurnosModal
            visible={visible}
            hideModal={hideModal}
            shift={shift}
            shiftId={shiftId}
            handleSuccessTurno={handleSuccess}
            handleDeleteTurno={handleDeleteTurno}
            activeShift={activeShift.shiftId}
            fromHour={fromHour}
            toHour={toHour}
            handleChange={handleChangeArray}
          />

          <DeleteSectorTurnoModal
            visible={visibleDelete}
            deleteShift={shiftToDelete}
            deleteShiftId={shiftIdToDelete}
            handleSuccess={handleSuccess}
            hideDelete={hideDelete}
            handleFilterArray={handleFilterArray}
          />

          <ErrorModal visible={modalBlobError} hideModal={hideModalErrorBlob} />

          {newCommerce && (
            <PreconfigShiftSectorModal
              shiftsData
              displayPreconf={displayPreConfig}
              onClick={() => handleAcceptPreconf('shifts')}
            />
          )}
        </div>
      </div>
    </div>
  );
}
