import { useContext, useState } from 'react';
import UsersContext from '../../context/users';
import Loading from '../../components/Loading';
import ErrorMessage from '../../components/ErrorMessage';
import LoginForm from '../../components/Forms/Login';
import TopNavBar from '../../components/TopNavBar';
import SuccessModal from '../../components/Modal/SuccessModal';
import ErrorModal from '../../components/Modal/ErrorModal';
import './style.css';

export default function Login() {
  // PROVIDER DE CONTEXT API
  const { isLoading, hasError, errorMessage } = useContext(UsersContext);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [showModalError, setShowModalError] = useState(false);

  const showModal = (value) => {
    switch (value) {
      case 'success':
        setShowModalSuccess(true);
        break;
      case 'error':
        setShowModalError(true);
        break;
      default:
        break;
    }
  };

  return (
    <div className='mainLoginView'>
      <TopNavBar />
      {isLoading ? (
        <Loading title='Cargando...' />
      ) : hasError ? (
        <ErrorMessage message={errorMessage} />
      ) : (
        <LoginForm showModal={showModal} />
      )}
      <SuccessModal
        visible={showModalSuccess}
        title='¡Cambiaste tu contraseña!'
        text='Recordá usar esta contraseña la próxima vez que ingreses.'
        button='Continuar'
        refresh
      />
      <ErrorModal visible={showModalError} redirect='/' />
    </div>
  );
}
