import './style.css';

export default function TermsBlob(props) {
  return (
    <article
      className='terms'
      style={{
        padding: props.standalone ? '25px 35px' : '0px',
      }}
    >
      <h1>Términos y Condiciones</h1>

      <p>
        Los presentes términos y condiciones generales de uso (los{' '}
        <strong>"Términos y Condiciones"</strong>) para el uso de esta
        aplicación móvil (<strong>"TIPIE"</strong>), los que constituyen un
        contrato entre Usted (en adelante <strong>“Usted”</strong>,{' '}
        <strong>"Usuario"</strong> o, en plural, <strong>"Usuarios"</strong>) y{' '}
        <strong>BOILCA S.A.</strong>, CUIT {/* TODO: complete */} on domicilio
        en Sanchez de Loria 2343, piso 2, oficina “B”, CABA (
        <strong>"BOILCA S.A."</strong> y junto con Usted{' '}
        <strong>"Usuario"</strong>, las <strong>"Partes"</strong>).
      </p>
      <p>
        BOILCA S.A. no es una entidad financiera ni una billetera electrónica,
        sino un intermediario de servicios tecnológicos entre los usuarios
        adheridos, cuyo fin es facilitar el procesamiento y distribución del
        pago de propinas, en comercios gastronómicos. Esta intermediación será
        realizada a través de la aplicación o página web denominada "TIPIE", (en
        adelante <strong>"TIPIE"</strong>). El pago puede ser efectuado con
        cualquier medio de pago conectado electrónicamente con TIPIE.
      </p>
      <p>
        BOILCA S.A. ofrece a los Usuarios la intermediación para el cobro de
        propinas mediante un algoritmo programado de acuerdo a lo solicitado y
        aceptado por estos, el que realizará la división del dinero percibido a
        cada Usuario conforme a los Proyectos de Distribución solicitados y
        aceptados. Estos Proyectos de Distribución son pactados entre los
        Usuarios. BOILCA S.A. es ajeno a ellos, por lo que no es responsable por
        las operaciones realizadas por los Usuarios. Los Proyectos de
        Distribución y porcentuales cargados en TIPIE (en adelante{' '}
        <strong>"Proyectos de Distribución"</strong>) son exclusiva
        responsabilidad de los Usuarios.
      </p>
      <p>
        Los Términos y Condiciones detallan los derechos, responsabilidades y
        obligaciones de los Usuarios y de BOILCA S.A. Usted declara que ha
        leído, conoce y acepta todos los Términos y Condiciones, los cuales son
        obligatorios y vinculantes para las Partes. En caso de no estar de
        acuerdo con los Términos y Condiciones, por favor no utilice TIPIE y
        absténgase de utilizar los servicios que se ofrecen. El mero hecho de la
        navegación y/o utilización de TIPIE atribuye a quien lo realice la
        condición de Usuario de la misma e implica la aceptación de estos
        Términos y Condiciones en todas y cada una de sus partes.
      </p>
      <p>
        La utilización por los Usuarios de TIPIE se entenderá como aceptación
        plena y sin reservas de los Términos y Condiciones aquí establecidos.
        Usted expresamente acepta que la documentación e información relacionada
        con estos Términos y Condiciones, la cual será publicada únicamente en
        formato digital.
      </p>
      <p>
        Las comunicaciones serán fehacientes desde los domicilios electrónicos
        constituidos en el presente, siendo el de BOILCA S.A.{' '}
        {/* TODO: add BOILCA email address */}, mientras que el del Usuario
        (comprendiendo todas las categorías y definiciones de este) el
        constituido al momento del registro. Las partes pueden modificar sus
        domicilios electrónicos constituidos en cualquier momento, mediante
        previa notificación fehaciente a la otra parte con acuse de recibo como
        requisito fundamental para la aceptación del nuevo domicilio
        destinatario de cualquier comunicación que se pretenda realizar en
        virtud del presente.
      </p>

      <h2>1. DEFINICIONES Y PARTES</h2>

      <p>
        <strong>BOILCA S.A.</strong>: intermediario de servicios tecnológicos
        entre los usuarios adheridos, cuyo fin es facilitar el acceso a la
        transferencia de propinas en comercios gastronómicos.
      </p>
      <p>
        <strong>TIPIE</strong>: aplicación o página web que permite la
        transferencia de propinas de los comercios gastronómicos registrados
        mediante algoritmos.
      </p>
      <p>
        <strong>Usuario Creador</strong>: persona humana o jurídica que accede
        y/o utiliza la aplicación o página web desde una cuenta registrada que
        le permite generar Proyectos de Distribución y administrar la división
        de los porcentuales de cada operación.
      </p>
      <p>
        <strong>Usuario Registrado</strong>: persona humana o jurídica que
        accede y/o utiliza la aplicación o página web desde una cuenta
        registrada.
      </p>
      <p>
        <strong>Usuario No Registrado</strong>: persona humana o jurídica que
        accede y/o utiliza la aplicación o página web sin una cuenta registrada.
      </p>
      <p>
        <strong>Datos Personales</strong>: información personal suministrada por
        los Usuarios Registrados.
      </p>
      <p>
        <strong>Cuenta Personal</strong>: cuenta de identificación personal,
        única e intransferible que permite identificar a los usuarios, acceder
        y/o utilizar los servicios de TIPIE.
      </p>
      <p>
        <strong>Clave de Seguridad</strong>: contraseña alfanumérica personal,
        única, confidencial e intransferible que le permite a los Usuarios
        Registrados ingresar en su cuenta personal
      </p>
      <p>
        <strong>Proyecto de Distribución</strong>: planificación y formas de
        distribución de las propinas percibidas, dispuestas por el Usuario
        Creador, a fin de distribuir el porcentaje de propina correspondiente a
        cada Usuario, conforme a las modalidades pactadas y aceptadas por los
        Usuarios relacionados a cada empresa gastronómica.
      </p>
      <p>
        <strong>Medios de pago</strong>: tarjetas de crédito, débito, tarjetas
        de regalo, prepagas, billeteras electrónicas y/o cualquier otro
        instrumento de pago utilizado por los Usuarios No Registrados para
        abonar las propinas a los Usuarios Registrados.
      </p>
      <p>
        <strong>Procesadores</strong>: las entidades que se encuentran
        conectadas tecnológicamente con TIPIE y se encargan de procesar las
        transacciones recibidas con los distintos Medios de Pago ofrecidos a los
        Usuarios No Registrados.
      </p>

      <h2>2. CAPACIDAD</h2>

      <p>
        Por medio de la aceptación de los presentes Términos y Condiciones, el
        Usuario declara:
      </p>
      <p>
        Que ha leído y comprende lo expuesto en el presente instrumento; que es
        una persona con capacidad suficiente para contratar conforme a la
        legislación Argentina, con el fin de dar plena validez, eficacia y
        utilidad práctica a todas y cada una de las estipulaciones de los
        presentes Términos y Condiciones y que asume todos los derechos,
        responsabilidades y obligaciones aquí dispuestos.
      </p>
      <p>
        No podrán navegar ni utilizar los servicios de TIPIE las personas que no
        tengan capacidad para contratar, los menores de edad, que no cuenten con
        capacidad para efectuar ese acto, conforme a las normas y principios de
        autonomía progresiva y/o quienes hayan sido suspendidos o inhabilitados
        por BOILCA S.A. para utilizar el servicio.
      </p>

      <h2>3. MODIFICACIONES</h2>
      <p>
        El Usuario se compromete a leer atenta y comprensivamente los presentes
        Términos y Condiciones cada vez que acceda a la página web o utilice la
        aplicación, ya que los mismos pueden sufrir modificaciones.
      </p>
      <p>
        BOILCA S.A. se reserva el derecho de ampliar y modificar la aplicación y
        el servicio a efectos de mejorar la calidad del mismo, y en consecuencia
        se reserva el derecho de modificar, los Términos y Condiciones en
        cualquier momento.
      </p>
      <p>
        Dentro de las facultades de BOILCA S.A. para la modificación del
        presente, y sin representar una enumeración taxativa, se incluyen la
        suspensión de cuentas, cambio o terminación del servicio, modificación
        de valor de comisión.
      </p>
      <p>
        Toda modificación será comunicada con una antelación de 10 (diez) días
        corridos a su entrada en vigencia. En caso de no estar de acuerdo con
        dichas modificaciones, dentro del plazo de 10 (diez) días corridos de
        comunicada la modificación, el Usuario deberá comunicar vía e-mail a la
        casilla {/* TODO: add email address */} que no acepta las mismas; en ese
        caso quedará disuelto el vínculo contractual y será inhabilitado como
        Usuario. Vencido este plazo, se considerará que el Usuario acepta los
        nuevos Términos y Condiciones, los que regirán la relación contractual a
        partir de su entrada en vigencia.
      </p>

      <h2>4. CONDICIONES DE USO</h2>
      <h2>4.1. Accesso a TIPIE.</h2>
      <p>
        El acceso y registro en TIPIE es gratuito, salvo en lo relativo al coste
        de la conexión a través de la red de telecomunicaciones suministrada por
        el proveedor de acceso contratado (ISP) por el Usuario, que estará a su
        exclusivo cargo.
      </p>

      <h2>4.2. Necesidad de Registro.</h2>
      <p>
        Por regla general, para el acceso a los contenidos de TIPIE no será
        necesario el registro del Usuario. No obstante ello, la utilización de
        determinados servicios estará condicionada al registro previo del
        Usuario, quien deberá completar todos los campos del formulario de
        inscripción con datos válidos (en adelante el{' '}
        <strong>"Usuario Registrado"</strong>).
      </p>
      <p>
        Quien aspire a convertirse en Usuario Registrado, deberá dar fe que la
        información que pone a disposición de BOILCA S.A. a fin de registrarse
        en el sistema sea exacta, precisa y verdadera (en adelante los{' '}
        <strong>"Datos Personales"</strong>); asimismo asumirá el compromiso de
        actualizar los Datos Personales cada vez que los mismos sufran
        modificaciones. BOILCA S.A. podrá utilizar diversos medios para
        identificar a los Usuarios Registrados, pero no se responsabiliza por la
        certeza de los Datos Personales que sus Usuarios Registrados pongan a su
        disposición. Los Usuarios Registrados garantizan bajo juramento y
        responden, en cualquier caso, de la veracidad, exactitud, vigencia y
        autenticidad de los Datos Personales puestos a disposición de BOILCA
        S.A. mediante TIPIE.
      </p>
      <p>
        Los Términos y Condiciones serán de aplicación tanto para los Usuarios
        Creadores, Usuarios Registrados como para los Usuarios No Registrados.
      </p>

      <h2>4.3. Obligación de mantener actualizados los Datos Personales.</h2>
      <p>
        Los Datos Personales introducidos por todo Usuario Registrado en TIPIE,
        deberán ser exactos, actuales y veraces en todo momento. BOILCA S.A. se
        reserva el derecho de solicitar algún comprobante y/o dato adicional a
        efectos de corroborar los Datos Personales, y de suspender temporal y/o
        definitivamente a aquellos Usuarios Registrados cuyos datos no hayan
        podido ser confirmados. La baja o inhabilitación de un Usuario
        Registrado podrá implicar la baja de todos los Proyectos de Distribución
        en los que esté incluido o hubiere cargado en TIPIE, sin que ello genere
        derecho a resarcimiento o indemnización alguna.
      </p>

      <h2>
        4.4. Acceso a la cuenta personal y obligación de confidencialidad de la
        Clave de Seguridad.
      </h2>
      <p>
        El Usuario Registrado tendrá acceso a una cuenta personal (
        <strong>"Cuenta Personal"</strong>) mediante el ingreso de una cuenta de
        mail y clave de seguridad personal elegida (
        <strong>"Clave de Seguridad"</strong>). Esta Clave de Seguridad es
        personal e intransferible. El Usuario Registrado se obliga a mantener en
        estricta confidencialidad su Clave de Seguridad. El Usuario Registrado
        será, en todo caso, responsable por todo daño, perjuicio, lesión o
        detrimento que del incumplimiento de esta obligación de confidencialidad
        se genere por cualquier causa.
      </p>
      <p>
        Cuando un Usuario se registre en TIPIE a través de su cuenta personal de
        un correo electrónico, una red social u otra página web, y acceda a
        TIPIE a través de ella, el Usuario consiente expresa y voluntariamente a
        que TIPIE acceda en cualquier momento a la información contenida en la
        Cuenta Personal y que dicha información sea otorgada a TIPIE al momento
        de su registro.
      </p>
      <p>
        La Cuenta es personal, única e intransferible, y está prohibido que un
        mismo Usuario Registrado registre o posea más de una Cuenta. En caso que
        BOILCA S.A. detecte distintas Cuentas que contengan datos coincidentes o
        relacionados, podrá cancelarlas, suspenderlas o inhabilitarlas, a su
        sola discreción, siendo el mismo motivo suficiente para dar de baja al
        Usuario Registrado, en la totalidad de sus cuentas. El Usuario
        Registrado será responsable por todas las operaciones efectuadas desde
        su Cuenta, pues el acceso a la misma está restringido al ingreso y uso
        de su Clave de Seguridad, de conocimiento exclusivo del Usuario y cuya
        confidencialidad es de su exclusiva responsabilidad. El Usuario
        Registrado se compromete a notificar a BOILCA S.A., en forma inmediata y
        por medio idóneo, fehaciente, eficiente y eficaz, cualquier uso no
        autorizado de su Cuenta, así como el ingreso por terceros no autorizados
        a la misma. La notificación de ningún modo liberará de responsabilidad
        por su Cuenta al Usuario ni transferirá responsabilidad a BOILCA S.A..
        Se encuentra prohibida la venta, cesión, transferencia o transmisión de
        la Cuenta bajo cualquier título, ya sea a título oneroso o gratuito.
      </p>
      <p>
        BOILCA S.A. se reserva el derecho de rechazar cualquier solicitud de
        registro o de cancelar un registro previamente aceptado, cuando
        consideren que no se ha dado cumplimiento a la totalidad de las pautas
        establecidas en los Términos y Condiciones, sin que esté obligado a
        comunicar o exponer las razones de su decisión y sin que ello genere
        derecho a indemnización o resarcimiento alguno a favor del Usuario
        Registrado alcanzado por dicha decisión.
      </p>

      <h2>4.5. Normas generales de utilización de TIPIE.</h2>
      <p>
        El Usuario y/o el Usuario Registrado, se obliga a utilizar la Página
        Web, Aplicación y todo su contenido y servicios conforme a lo
        establecido en la ley, la moral, el orden público y los presentes
        Términos y Condiciones que en cada caso resulten aplicables. Asimismo,
        se obliga a hacer un uso adecuado de los servicios y/o contenidos de
        TIPIE y a no emplearlos para realizar actividades ilícitas o
        constitutivas de delito, que atenten contra los derechos de terceros y/o
        que infrinjan la regulación sobre propiedad intelectual e industrial, o
        cualesquiera otras normas del ordenamiento jurídico que puedan resultar
        aplicables y, en especial, el principio de buena fe que obliga a actuar
        leal, correcta y honestamente tanto en los tratos preliminares,
        celebración y ejecución de todo contrato. Como consecuencia de lo
        anterior, el Usuario se obliga a no difundir, transmitir, introducir y
        poner a disposición de terceros cualquier tipo de material e información
        (datos, contenidos, mensajes, dibujos, archivos de sonido e imagen,
        fotografías, software, etc.) que sean contrarios a la ley, la moral, el
        orden público y los presentes Términos y Condiciones.
      </p>

      <h2>5. OBLIGACIONES DE LOS USUARIOS</h2>
      <p>
        <strong>5.1.</strong> A título meramente enunciativo, y en ningún caso
        limitativo, taxativo o excluyente, el Usuario creador, Usuario
        registrado y/o Usuario no registrado, no podrá ceder, transferir,
        delegar o disponer de los derechos u obligaciones derivados de estos
        Términos y Condiciones, total o parcialmente, por lo que se compromete
        a:
      </p>
      <p>
        <strong>(a)</strong> No introducir o difundir contenidos o propaganda de
        carácter racista, xenófobo o, en general, discriminatorio, pornográfico,
        de apología del terrorismo o que atenten, vulneren o pudieren atentar o
        vulnerar los derechos humanos. <strong>(b)</strong> No introducir o
        difundir en la red programas de datos (virus y/o software nocivos)
        susceptibles de provocar daños en los sistemas informáticos de TIPIE,
        sus proveedores, terceros o, en general, cualquier usuario de la red
        Internet. <strong>(c)</strong> No difundir, transmitir o poner a
        disposición de terceros cualquier tipo de información, elemento o
        contenido que atente contra los derechos fundamentales y las libertades
        públicas reconocidos constitucionalmente y en los tratados
        internacionales. <strong>(d)</strong> No difundir, transmitir o poner a
        disposición de terceros cualquier tipo de información, elemento o
        contenido que constituya publicidad ilícita o desleal.{' '}
        <strong>(e)</strong> No introducir o difundir cualquier información y
        contenidos falsos, ambiguos o inexactos de forma que induzca a error a
        los receptores de la información. <strong>(f)</strong> No difundir,
        transmitir o poner a disposición de terceros cualquier tipo de
        información, elemento o contenido que suponga una violación de los
        derechos de propiedad intelectual e industrial, patentes, marcas o
        copyright que correspondan a la Compañía o a terceros.{' '}
        <strong>(g)</strong> No difundir, transmitir o poner a disposición de
        terceros cualquier tipo de información, elemento o contenido que suponga
        una violación del secreto de las comunicaciones y la legislación de
        datos de carácter personal y, en general, toda las normas jurídicas que
        regulen la protección y promoción del respeto a la vida privada e
        intimidad de las personas y sus familias. <strong>(h)</strong> Mantener
        actualizado el domicilio electrónico denunciado, al que serán válidas
        todas las comunicaciones que rigen la relación contractual. La
        modificación del domicilio debe ser debidamente notificada a BOILCA S.A.
        al domicilio electrónico denunciado ({/* TODO: Add email address */}),
        siendo válida luego de acuse de recibo. <strong>(i)</strong> No realizar
        acción alguna, lícita o ilícita, que se aparte de la causa del presente
        contrato, significando esta la intermediación de propinas. BOILCA S.A.
        está facultado para iniciar acciones penales y/o civiles en caso de
        tomar conocimiento del uso indebido de los medios tecnológicos
        proporcionados.
      </p>
      <p>
        <strong>5.2.</strong> El Usuario creador, Usuario registrado y/o Usuario
        no registrado, se obliga a mantener indemne a BOILCA S.A. ante cualquier
        posible reclamación, multa, pena, sanción o indemnización que pueda
        venir obligada a soportar como consecuencia del incumplimiento por parte
        del Usuario creador, Usuario registrado y/o Usuario no registrado de
        cualquiera de las normas de utilización antes indicadas, reservándose
        BOILCA S.A., además, el derecho a solicitar la indemnización por daños y
        perjuicios que corresponda. Asimismo, se reserva el derecho de anular la
        cuenta de Usuarios Creadores y Usuarios Registrados.
      </p>

      <h2>5. RESPONSABILIDAD DE BOILCA S.A.</h2>
      <p>
        Dado que BOILCA S.A. sólo pone a disposición de los Usuarios un espacio
        virtual denominado TIPIE que permite la administración de Proyectos (
        <em>ut supra</em> definido), transferencia y distribución de propinas, a
        disposición de los Usuarios creadores, Usuarios registrados y/o Usuarios
        no registrados mediante internet:
      </p>
      <p>
        <strong>(a)</strong> BOILCA S.A. no asume ninguna responsabilidad sobre
        la actualización de la página web o aplicación, en especial, respecto
        del contenido, información o datos personales, ni garantiza que los
        mencionados sean precisos ni completos. Por lo tanto, cada Usuario será
        responsable de que la información puesta a disposición sea precisa y
        completa antes de tomar alguna decisión relacionada con cualquier
        servicio o contenido descrito en la misma. <strong>(b)</strong> El
        acceso del Usuario a TIPIE no implica para BOILCA S.A. la obligación de
        informar, controlar o, actuación alguna referente a la ausencia o
        presencia de virus, gusanos o cualquier otro elemento y/o software
        informático dañino. Los Usuarios no podrán imputar responsabilidad
        alguna a BOILCA S.A., ni exigir indemnización bajo ningún concepto, por
        los perjuicios resultantes de dificultades técnicas o fallas en los
        sistemas o en internet. <strong>(c)</strong> BOILCA S.A. no garantiza el
        acceso y uso continuado e ininterrumpido de TIPIE, debido a que
        eventualmente puede no estar disponible debido a dificultades técnicas o
        fallas de internet, o por cualquier otra circunstancia ajena y no
        imputable a BOILCA S.A. BOILCA S.A. no será responsable por ningún error
        u omisión ocasionado en TIPIE por motivos inculpables.{' '}
        <strong>(d)</strong> Corresponde al Usuario, en todo caso, la
        disponibilidad de herramientas adecuadas para la detección y
        desinfección de programas informáticos dañinos. <strong>(e)</strong>{' '}
        BOILCA S.A. no se responsabiliza por los daños o perjuicios de cualquier
        tipo producidos al Usuario a causa de fallos o desconexiones en las
        redes de telecomunicaciones que produzcan la suspensión, cancelación o
        interrupción del servicio de TIPIE durante la prestación del mismo o con
        carácter previo, significando estas contingencias ajenas al diligente
        actuar de BOILCA S.A. <strong>(f)</strong> BOILCA S.A. no se hace
        responsable de la actuación de los Usuarios en TIPIE. BOILCA S.A. no es
        propietaria ni poseedora de los Proyectos de Distribución, Cuentas
        Personales ni de las operaciones de distribución que se establezcan, las
        cuales son propiedad y posesión de los distintos usuarios de TIPIE.
        BOILCA S.A. no interviene en las transferencias ni distribución de
        propinas, solamente habilitan un espacio virtual para tales operaciones,
        por ello no serán responsables respecto de la existencia, calidad,
        cantidad, disponibilidad, estado, integridad, legitimidad o condiciones
        de entrega de las Propinas ofrecidas, así como de la capacidad para
        contratar de los Usuarios Creadores y/o Usuarios Registrados o de la
        veracidad de los Datos Personales por ellos ingresados. El Usuario
        Creador y/o Usuario Registrado conoce y acepta ser el exclusivo
        responsable por los Proyectos de distribución, usuarios y cuentas
        relacionadas, y que no existe ninguna restricción y/o que cuenta con las
        autorizaciones necesarias para operar con TIPIE.
      </p>
      <p>
        Dado que la Página Web y la Aplicación es un intermediario entre
        Usuarios y/o Usuarios Registrados, y siendo que BOILCA S.A. no participa
        de las prestaciones que se realizan entre aquellos, los Usuarios
        creadores, Usuarios registrados y/o Usuarios no registrados serán
        responsables por todas las obligaciones fiscales y cargas impositivas
        que correspondan por su Actividad, sin que pudiera imputarse a BOILCA
        S.A. algún tipo de responsabilidad por incumplimientos en tal sentido.
      </p>
      <p>
        BOILCA S.A. se reservan el derecho de cancelar los pagos de propinas,
        cuando considere que no se ha dado cumplimiento a la totalidad de las
        pautas establecidas, o han sido efectuados en perjuicio y/o desmedro de
        los Términos y Condiciones.
      </p>

      <h2>6. PROPIEDAD INTELECTUAL E INDUSTRIAL</h2>
      <p>
        Todos los contenidos de la Página Web y Aplicación, entendiendo por
        éstos, a título meramente enunciativo y no taxativo, los textos,
        fotografías, gráficos, imágenes, iconos, tecnología, software, links y
        demás contenidos audiovisuales o sonoros, así como su diseño gráfico y
        códigos fuente, son propiedad intelectual de BOILCA S.A., sin que puedan
        entenderse cedidos al Usuario ninguno de los derechos de explotación
        reconocidos por la normativa vigente en materia de propiedad intelectual
        sobre los mismos. Asimismo, las marcas, nombres comerciales o signos
        distintivos de los Usuarios Creadores y Usuarios Registrados son
        propiedad de los mencionados, sin que pueda entenderse que el acceso a
        la Aplicación y/o Página Web atribuye algún derecho sobre los mismos.
      </p>
      <p>
        El Usuario Creador y/o Usuario Registrado declara y garantiza que las
        marcas, nombres comerciales o signos distintivos que publique en la
        Página Web y/o Aplicación serán únicamente obras originales, creadas por
        él mismo y que de ningún modo se tratará de copias o reproducciones que
        violen derechos de propiedad intelectual de terceros. BOILCA S.A. podrá
        cancelar las Cuentas de aquellos Usuarios que infrinjan cualquier
        derecho de propiedad intelectual de terceros. BOILCA S.A. podrá eliminar
        los materiales constitutivos de infracción de conformidad con la
        normativa vigente de propiedad intelectual e industrial.
      </p>
      <p>
        BOILCA S.A. no poseerá ningún derecho de propiedad sobre las marcas,
        nombres comerciales o signos distintivos de los Usuarios Creadores y/o
        Usuarios Registrados en la Página Web y/o Aplicación. Sin embargo, el
        Usuario Registrado otorga a BOILCA S.A. una licencia gratuita y no
        exclusiva, para todo el ámbito territorial mundial y por el máximo
        período legal de protección, en virtud de la cual podrán: comunicar
        públicamente, reproducir, distribuir y transformar el contenido de sus
        marcas, nombres comerciales, signos distintivos o publicaciones para
        poder desempeñar el servicio.
      </p>
      <p>
        Cualquier intromisión, tentativa o actividad violatoria o contraria a
        las leyes sobre derecho de propiedad intelectual y/o a las prohibiciones
        estipuladas en este contrato harán pasible a los Usuarios responsables
        de las acciones legales pertinentes, y a las sanciones previstas por
        este acuerdo, así como lo hará responsable de indemnizar los daños
        ocasionados.
      </p>

      <h2>7. SANCIONES</h2>
      <p>
        Sin perjuicio de otras medidas, BOILCA S.A. podrán advertir, suspender
        en forma temporal o inhabilitar definitivamente la cuenta de un Usuario
        Creador y/o Usuario Registrado, iniciar las acciones que estimen
        pertinentes y/o suspender la prestación de sus servicios, si;{' '}
        <strong>(a)</strong> se quebranta o incumple alguna ley, o cualquiera de
        las estipulaciones de los presentes Términos y Condiciones y/o demás
        políticas de TIPIE; <strong>(b)</strong> incumple sus obligaciones como
        Usuario Creador y/o Usuario Registrado; <strong>(c)</strong> no pudiere
        verificarse la identidad del Usuario Registrado o cualquier información
        proporcionada por el mismo fuere errónea; o, <strong>(d)</strong> si, a
        criterio de BOILCA S.A., se incurriera, en conductas o actos dolosos o
        fraudulentos. En el caso de suspensión o inhabilitación de un Usuario
        Creador y/o Usuario Registrado, todas sus cuentas e información asociada
        podrá ser removida por BOILCA S.A. de la Página Web y/o Aplicación.
      </p>

      <h2>8. TARIFAS</h2>
      <p>
        TIPIE no es un procesador de pagos, es un mero intermediario entre
        Usuarios Registrados y/o Usuarios No Registrados. Los pagos son hechos
        por el Usuario No Registrado a los Usuarios Registrado a través los
        servicios de un tercero, ya sea bancos, billeteras electrónicas, etc.,
        que se encuentren conectados tecnológicamente con TIPIE (
        <strong>"Procesadores"</strong>). Al usar la Página Web el Usuario No
        Registrado y/o el Usuario Registrado aceptan los términos y condiciones
        del procesador de pagos, que será el único responsable por cualquier
        asunto relacionado con el pago. BOILCA S.A. se reserva el derecho a
        cobrar una tarifa del 5% del monto de la operación por cualquier tipo de
        transacción realizada a través de TIPIE. Dicho monto es independiente a
        cualquier tarifa que pueda ser cobrada por el procesador de pagos
        elegido por el Usuario No Registrado.
      </p>
      <p>
        Cualquier referencia a dinero en TIPIE se entenderá hecha a la moneda de
        curso legal en la República Argentina, salvo en los casos que se indique
        expresamente otra moneda.
      </p>

      <h2>9. IMPUESTOS</h2>
      <p>
        El Usuario No Registrado y/o el Usuario Registrado es responsable de
        determinar cuáles impuestos aplican y/o deberá pagar, asimismo, es
        responsable de pagar cualquier impuesto asociado con el uso de la Página
        Web o Aplicación. Se deja constancia que BOILCA S.A. no es agente de
        retención.
      </p>

      <h2>10. INDEMNIDAD</h2>
      <p>
        El Usuario indemnizará y mantendrá indemnes a BOILCA S.A. sus
        administradores, sus filiales, empresas controladas y/o controlantes,
        directores administradores, representantes y empleados, por cualquier
        reclamo o demanda de otros Usuarios o de terceros por sus actividades en
        TIPIE o por el incumplimiento de los Términos y Condiciones, de las
        demás políticas que se entienden incorporadas al presente instrumento
        incluyendo los honorarios de abogados en una cantidad razonable.
      </p>

      <h2>11. NULIDAD E INEFICACIA DE LAS CLÁUSULAS</h2>
      <p>
        Si cualquier cláusula incluida en los Términos y Condiciones fuese
        declarada total o parcialmente, nula o ineficaz en alguna de las
        jurisdicciones en las que BOILCA S.A. y/o los Administradores tienen
        presencia, tal nulidad o ineficacia tan sólo afectará a dicha
        disposición o a la parte de la misma que resulte nula o ineficaz,
        subsistiendo los Términos y Condiciones en todo lo demás, considerándose
        tal disposición total o parcialmente no incluida o aplicable en aquella
        jurisdicción.
      </p>

      <h2>12. PRIVACIDAD DE LA INFORMACIÓN</h2>
      <p>
        Para utilizar los Servicios ofrecidos por BOILCA S.A. en TIPIE, los
        Usuarios que deseen convertirse en Usuarios Registrados, se obligan a
        aportar determinados datos de carácter personal prestando su
        consentimiento para el uso de la Aplicación y/o Página Web. La
        información solo se utiliza para el registro de la cuenta, sin contar
        con servidores que almacenen.
      </p>

      <h2>13. ALCANCE DE LOS SERVICIOS DE TIPIE</h2>
      <p>
        Este acuerdo no crea ningún contrato de sociedad, de mandato, de
        franquicia, o relación laboral entre el Usuario y BOILCA S.A. El Usuario
        reconoce y acepta que BOILCA S.A. no es parte en ninguna operación, ni
        tienen control alguno sobre transacciones efectuadas por los Usuarios No
        Registrados en el pago de las propinas ni los porcentajes de
        distribución de las mismas. BOILCA S.A. no puede asegurar que los
        Usuarios Creadores efectúen adecuadamente el Proyecto de Distribución,
        ni podrán verificar la identidad o Datos Personales ingresados por los
        Usuarios Registrados. BOILCA S.A. no será responsable por las
        transacciones efectuadas entre los Usuarios ni tampoco por los pagos
        efectuados con los Procesadores.
      </p>

      <h2>14. PREGUNTAS FRECUENTES</h2>
      <p>
        Las respuestas a preguntas frecuentes vinculadas a la participación de
        los Usuarios constituyen una descripción genérica que sólo tiene
        propósitos de información general. Las respuestas a las preguntas
        frecuentes no hacen referencia a todas las consecuencias legales,
        impositivas, cambiarias posibles relacionadas con la participación de
        los Usuarios Creadores, Usuarios Registrados y/o de los Usuarios No
        Registrados. Las respuestas a las preguntas frecuentes no implican en
        ningún caso asesoramiento legal o impositivo por parte de BOILCA S.A..
        Estos Términos y Condiciones prevalecerán siempre sobre el contenido de
        la información relativa a Preguntas Frecuentes.
      </p>

      <h2>15. LEGISLACIÓN APLICABLE Y JURISDICCIÓN COMPETENTE</h2>
      <p>
        Estos Términos y Condiciones se regirán o interpretarán conforme a la
        legislación de la República Argentina. BOILCA S.A. y el Usuario
        someterán cualquier controversia que pudiera suscitarse de la prestación
        de los productos o servicios objeto de éstos Términos y Condiciones, a
        los Juzgados Ordinarios de la Capital Federal.
      </p>
      <p>
        Los Términos y Condiciones detallan todos los derechos,
        responsabilidades y obligaciones de Usted y de BOILCA C.A.. Usted
        declara que ha leído, conoce y acepta los Términos y Condiciones, los
        cuales son obligatorios y vinculantes para las Partes. En caso de no
        estar de acuerdo con los Términos y Condiciones, por favor no utilice
        TIPIE y absténgase de utilizar los Servicios que se ofrecen. La
        utilización por Usted de TIPIE se entenderá como aceptación plena y sin
        reservas de los Términos y Condiciones aquí establecidos. Usted
        expresamente acepta que la documentación e información relacionada con
        estos Términos y Condiciones será publicada únicamente en formato
        digital, pudiendo solicitar soporte físico en el domicilio legal de
        BOILCA S.A.
      </p>
    </article>
  );
}
