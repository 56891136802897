import React from 'react';
import Loading from '../../components/Loading';
import { AbstractService } from '../../services/abstractService';
import Configuracion from './Configuracion';
import Unauthorized from '../../hoc/unauthorized';
import ErrorHoc from '../../components/ErrorMessage';

export default function ConfiguracionIndex() {
  // Informacion gral del comercio
  const [configCommerceData, setConfigCommerceData] = React.useState();
  const [responseCommerceData, setResponseCommerceData] = React.useState('');

  const [configCommerceSectors, setConfigCommerceSectors] = React.useState();
  const [responseCommerceSectors, setResponseCommerceSectors] =
    React.useState('');

  const [configCommerceShifts, setConfigCommerceShifts] = React.useState();
  const [responseCommerceShifts, setResponseCommerceShifts] =
    React.useState('');

  const [configCommerceActiveShift, setConfigCommerceActiveShift] =
    React.useState();
  const [responseCommerceActiveShift, setResponseCommerceActiveShift] =
    React.useState('');

  const [loading, setLoading] = React.useState(true);
  const [hasError, setHasError] = React.useState(false);
  const [unauthorized, setUnauthorized] = React.useState(false);

  if (
    (!configCommerceData,
    !configCommerceSectors,
    !configCommerceShifts,
    !configCommerceActiveShift)
  ) {
    const user = localStorage.getItem('loggedUser');

    if (!user) {
      setUnauthorized(true);
      setHasError(false);
      setLoading(false);
    }

    setConfigCommerceData({
      url: `${process.env.REACT_APP_BFF_WEB_URL}/profile/commerce`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user}`,
        'Content-Type': 'application/json',
      },
    });

    setConfigCommerceSectors({
      url: `${process.env.REACT_APP_BFF_WEB_URL}/commerce/sectors/list`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user}`,
        'Content-Type': 'application/json',
      },
    });

    setConfigCommerceShifts({
      url: `${process.env.REACT_APP_BFF_WEB_URL}/commerce/shifts/list`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user}`,
        'Content-Type': 'application/json',
      },
    });

    setConfigCommerceActiveShift({
      url: `${process.env.REACT_APP_BFF_WEB_URL}/commerce/active_shift`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user}`,
        'Content-Type': 'application/json',
      },
    });
  }

  React.useEffect(() => {
    if (
      !(
        configCommerceData &&
        configCommerceSectors &&
        configCommerceShifts &&
        configCommerceActiveShift
      )
    ) {
      // Incomplete configs
      return;
    }

    const promiseCommerceData = AbstractService(configCommerceData)
      .then((res) => ({ commerceData: res }))
      .catch((err) => {
        setHasError(true);
      });

    const promiseApiUsersSectors = AbstractService(configCommerceSectors)
      .then((res) => ({ sectorsData: res }))
      .catch((err) => {
        setHasError(true);
      });

    const promiseApiUsersShifts = AbstractService(configCommerceShifts)
      .then((res) => ({ shiftsData: res }))
      .catch((err) => {
        setHasError(true);
      });

    const promiseActiveShift = AbstractService(configCommerceActiveShift)
      .then((res) => ({ activeShift: res }))
      .catch((err) => {
        setHasError(true);
      });

    Promise.all([
      promiseCommerceData,
      promiseApiUsersSectors,
      promiseApiUsersShifts,
      promiseActiveShift,
    ])
      .then((responses) => {
        responses.forEach((res) => {
          if (res?.commerceData) {
            setResponseCommerceData(res.commerceData);
          } else if (res?.sectorsData) {
            setResponseCommerceSectors(res.sectorsData);
          } else if (res?.shiftsData) {
            setResponseCommerceShifts(res.shiftsData);
          } else if (res?.activeShift) {
            setResponseCommerceActiveShift(res.activeShift);
          }
        });
        setLoading(false);
      })
      .catch((err) => {
        setHasError(true);
        setLoading(false);
      });
  }, [
    configCommerceData,
    configCommerceSectors,
    configCommerceShifts,
    configCommerceActiveShift,
  ]);

  if (unauthorized) {
    return <Unauthorized />;
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : hasError ? (
        <ErrorHoc views />
      ) : (
        <Configuracion
          content={[
            {
              commerceData: responseCommerceData,
              sectorsData: responseCommerceSectors,
              shiftsData: responseCommerceShifts,
              activeShift: responseCommerceActiveShift,
            },
          ]}
        />
      )}
    </>
  );
}
