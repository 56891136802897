import React from 'react';
import CustomizedInputs from '../../../Forms/CustomizedInputs';
import ModalActionBar from '../../../ModalActionBar';
import './style.css';

export default function AddSectorTurnoModal(props) {
  const [display, setDisplay] = React.useState('none');
  const [disabled, setDisabled] = React.useState(true);
  const [shiftValue, setShiftValue] = React.useState('');
  const [sectorValue, setSectorValue] = React.useState('');

  const [startHour, setStartHour] = React.useState('');
  const [startMinutes, setStartMinutes] = React.useState('');

  const [finishHour, setFinishHour] = React.useState('');
  const [finishMinutes, setFinishMinutes] = React.useState('');

  const refStartHour = React.useRef(null);
  const refStartMinutes = React.useRef(null);
  const refFinishHour = React.useRef(null);
  const refFinishMinutes = React.useRef(null);

  const [placeholderColorOne, setPlaceholderColorOne] = React.useState(false); // gris placeholder hora
  const [placeholderColorTwo, setPlaceholderColorTwo] = React.useState(false); // gris placeholder minuto
  const [placeholderColorThree, setPlaceholderColorThree] =
    React.useState(false); // gris placeholder hora
  const [placeholderColorFour, setPlaceholderColorFour] = React.useState(false); // gris placeholder minuto

  const handleDisplay = () => {
    setDisplay('none');
    if (props.hideModal) {
      props.hideModal();
    }
    if (props.addShift) {
      setShiftValue('');
      refStartHour.current.value = '';
      refStartMinutes.current.value = '';
      refFinishHour.current.value = '';
      refFinishMinutes.current.value = '';
      setStartHour('');
      setStartMinutes('');
      setFinishHour('');
      setFinishMinutes('');
    } else {
      setSectorValue('');
    }

    setShiftValue('');
    setSectorValue('');
  };

  React.useEffect(() => {
    if (props.visible) {
      setDisplay('block');
    }
  }, [props.visible]);

  React.useEffect(() => {
    if (
      sectorValue.length > 1 ||
      (shiftValue.length > 1 &&
        startHour.length > 0 &&
        startMinutes.length > 0 &&
        finishHour.length > 0 &&
        finishMinutes.length > 0)
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [
    shiftValue,
    sectorValue,
    startHour,
    startMinutes,
    finishHour,
    finishMinutes,
  ]);

  const handleValue = (e) => {
    if (props.addShift) {
      setShiftValue(e.target.value);
    } else {
      setSectorValue(e.target.value);
    }
  };

  const handleFocus = (e, value) => {
    if (e === 'inicio') {
      if (value === 1) {
        // hora inicio
        setPlaceholderColorOne(true);
      } else if (value === 2) {
        // minutos inicio
        setPlaceholderColorTwo(true);
      }
    } else if (e === 'fin') {
      if (value === 3) {
        // hora fin
        setPlaceholderColorThree(true);
      } else if (value === 4) {
        // minutos fin
        setPlaceholderColorFour(true);
      }
    }
  };

  const handleBlur = (e) => {
    if (e === 'fin') {
      if (finishHour === '' && finishMinutes === '') {
        setPlaceholderColorThree(false);
        setPlaceholderColorFour(false);
      } else {
        setPlaceholderColorThree(true);
        setPlaceholderColorFour(true);
      }
    } else if (e === 'inicio') {
      if (startHour === '' && startMinutes === '') {
        setPlaceholderColorOne(false);
        setPlaceholderColorTwo(false);
      } else {
        setPlaceholderColorOne(true);
        setPlaceholderColorTwo(true);
      }
    }
  };

  const handleOnInput = (e, type) => {
    if (e.target.value.length > 2) {
      const finalInput = [];
      finalInput.push(e.target.value[0]);
      finalInput.push(e.target.value[1]);
      return (e.target.value = finalInput.toString().replace(',', ''));
    }
    if (e.target.value === '') {
      return (e.target.value = '');
    }

    if (type === 'hour') {
      if (e.target.value > 24) {
        return (e.target.value = '');
      }
      return e.target.value;
    }
    if (type === 'minutes') {
      if (e.target.value > 59) {
        return (e.target.value = '');
      }
      return e.target.value;
    }
  };

  const handleNewShiftValue = (e, value) => {
    switch (value) {
      case 'startHour':
        setStartHour(e.target.value);
        break;
      case 'startMinutes':
        setStartMinutes(e.target.value);
        break;
      case 'finishHour':
        setFinishHour(e.target.value);
        break;
      case 'finishMinutes':
        setFinishMinutes(e.target.value);
        break;
      default:
        throw new Error('Input inválido!');
    }
  };

  const handleAdd = () => {
    if (!props.addShift) {
      handleDisplay();
      const newSector = {
        name: sectorValue,
        percentage: 0,
      };
      props.handleConfirmAddSectorShift(newSector);
      setSectorValue('');
      // }
    } else {
      const newShift = {
        name: shiftValue,
        from_hour: `${startHour}:${startMinutes}`,
        to_hour: `${finishHour}:${finishMinutes}`,
      };
      handleDisplay();
      props.handleConfirmAddSectorShift(newShift);
      setShiftValue('');
    }
  };

  return (
    <div
      style={{
        display,
        position: 'fixed',
        zIndex: 22,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        overflow: 'auto',
        backgroundColor: 'rgb(0,0,0)',
        backgroundColor: 'rgba(0,0,0,0.4)',
      }}
      onClick={handleDisplay}
    >
      <div
        className='modalAddSectorTurnoContent'
        onClick={(e) => {
          // do not close modal if anything inside modal content is clicked
          e.stopPropagation();
        }}
      >
        {props.addShift ? (
          <div>
            <h2 className='modalAddSectorTurnoTitle'>
              Agregá un {props.addShift ? 'turno' : 'sector'}
            </h2>

            <div className='modalAddSectorTurnoInput'>
              <CustomizedInputs
                label={
                  props.addShift ? 'Nombre del turno' : 'Nombre del sector'
                }
                value={props.addShift ? shiftValue : sectorValue}
                handleUsername={handleValue}
              />

              <div className='modalAddTurnosDiv'>
                <div>
                  <p className='modalTurnosInputParagraph'>Inicio</p>
                  <div className='modalTurnosDivRow'>
                    <input
                      type='text'
                      className={
                        placeholderColorOne
                          ? 'modalTurnosScheduleInput'
                          : 'modalTurnosScheduleInputGray'
                      }
                      placeholder='00'
                      maxLength='2'
                      ref={refStartHour}
                      onChange={(e) => handleNewShiftValue(e, 'startHour')}
                      onBlur={() => handleBlur('inicio', 1)}
                      onFocus={() => handleFocus('inicio', 1)}
                      onInput={(e) => handleOnInput(e, 'hour')}
                    />
                    <input
                      type='text'
                      className={
                        placeholderColorTwo
                          ? 'modalTurnosScheduleInput'
                          : 'modalTurnosScheduleInputGray'
                      }
                      placeholder='00'
                      maxLength='2'
                      ref={refStartMinutes}
                      onChange={(e) => handleNewShiftValue(e, 'startMinutes')}
                      onInput={(e) => handleOnInput(e, 'minutes')}
                      onBlur={() => handleBlur('inicio', 2)}
                      onFocus={() => handleFocus('inicio', 2)}
                    />
                  </div>
                </div>

                <div>
                  <p className='modalTurnosInputParagraph'>Fin</p>
                  <div className='modalTurnosDivRow'>
                    <input
                      type='text'
                      className={
                        placeholderColorThree
                          ? 'modalTurnosScheduleInput'
                          : 'modalTurnosScheduleInputGray'
                      }
                      placeholder='00'
                      maxLength='2'
                      ref={refFinishHour}
                      onChange={(e) => handleNewShiftValue(e, 'finishHour')}
                      onInput={(e) => handleOnInput(e, 'hour')}
                      onBlur={() => handleBlur('fin', 3)}
                      onFocus={() => handleFocus('fin', 3)}
                    />
                    <input
                      type='text'
                      className={
                        placeholderColorFour
                          ? 'modalTurnosScheduleInput'
                          : 'modalTurnosScheduleInputGray'
                      }
                      placeholder='00'
                      maxLength='2'
                      ref={refFinishMinutes}
                      onChange={(e) => handleNewShiftValue(e, 'finishMinutes')}
                      onInput={(e) => handleOnInput(e, 'minutes')}
                      onBlur={() => handleBlur('fin', 4)}
                      onFocus={() => handleFocus('fin', 4)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='modalAddSectorDiv'>
            <h2 className='modalAddSectorTurnoTitle'>Nuevo sector</h2>

            <p className='modalAddSectorParagraph'>
              El nuevo sector se agregará con un porcentaje de 0%. Recordá
              modificarlo para que empiece a funcionar.
            </p>

            <div className='modalAddSectorInputDiv'>
              <CustomizedInputs
                label='Nombre del sector'
                value={sectorValue}
                handleUsername={handleValue}
              />
            </div>
          </div>
        )}

        <ModalActionBar
          onPrimaryClick={handleAdd}
          primaryDisabled={disabled}
          primaryLabel={props.addShift ? 'Agregar' : 'Crear sector'}
          onSecondaryClick={handleDisplay}
        />
      </div>
    </div>
  );
}
