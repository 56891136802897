/* eslint-disable default-case */
import React from 'react';
import './style.css';

export default function DisabledColaboradoresModal(props) {
  const [display, setDisplay] = React.useState('none');

  const handleDisplay = () => {
    setDisplay('none');
  };

  React.useEffect(() => {
    if (props.visible) {
      setDisplay('block');
    }
  }, [props.visible]);

  return (
    <div
      style={{
        display,
        position: 'fixed',
        zIndex: 22,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        overflow: 'auto',
        backgroundColor: 'rgb(0,0,0)',
        backgroundColor: 'rgba(0,0,0,0.4)',
      }}
      onClick={handleDisplay}
    >
      <div
        className='modalDisabledColabContent'
        onClick={(e) => {
          // do not close modal if anything inside modal content is clicked
          e.stopPropagation();
        }}
      >
        <p className='modalDisabledColabTitle'>Colaborador deshabilitado</p>
        <p className='modalDisabledColabParagraph'>
          Este usuario aún no ha ingresado a su cuenta, y por lo tanto sus datos
          todavía no han sido cargados.
        </p>

        <div className='modalDisabledColabBtnDiv'>
          <button
            className='modalDisabledColabBtnCancelar'
            onClick={handleDisplay}
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
}
