import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Home from '../views/Home';
import Login from '../views/Login/Login';
import Colaboradores from '../views/Colaboradores';
import Approval from '../views/Approval';
import Historial from '../views/Historial';

import Configuracion from '../views/Configuracion';
import TurnosView from '../views/Configuracion/Turnos';
import SectoresView from '../views/Configuracion/Sectores';
import DatosView from '../views/Configuracion/Datos';

import LandingPage from '../views/Landing';
import Terms from '../views/Terms';
import Support from '../views/Support';
import Contact from '../views/Contact';
import EndShift from '../views/EndShift';

import ScrollToTop from '../components/ScrollToTop/index.js';
import FourOFour from '../hoc/404';
import ProcessingTransaction from '../views/ProcessingTransaction/ProcessingTransaction';

import AddTipQR from '../views/AddTipQR';
import AddTipQRForm from '../views/AddTipQR/form';
import AddTipQRSuccess from '../views/AddTipQR/success';
import AddTipQRError from '../views/AddTipQR/error';

export default function Routes() {
  function ExternalRedirect() {
    window.location = 'https://youtu.be/hl9K3g7gtSk';
    return null;
  }

  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path='/' exact>
          <LandingPage />
        </Route>

        <Route path='/tyc'>
          <Terms />
        </Route>

        <Route path='/soporte'>
          <Support />
        </Route>

        <Route path='/contacto'>
          <Contact />
        </Route>

        <Route path='/login'>
          <Login />
        </Route>

        <Route path='/home'>
          <Home />
        </Route>

        <Route path='/cierre-turno'>
          <EndShift />
        </Route>

        <Route path='/colaboradores'>
          <Colaboradores />
        </Route>

        <Route path='/alta'>
          <Approval />
        </Route>

        <Route path='/historial'>
          <Historial />
        </Route>

        <Route path='/configuracion' exact>
          <Configuracion />
        </Route>

        <Route path='/configuracion/sectores'>
          <SectoresView />
        </Route>

        <Route path='/configuracion/turnos'>
          <TurnosView />
        </Route>

        <Route path='/configuracion/datos'>
          <DatosView />
        </Route>

        <Route path='/procesando/transaccion'>
          <ProcessingTransaction />
        </Route>

        <Route path='/qr/checkout/:id' exact>
          <AddTipQR />
        </Route>

        <Route path='/payment/form' exact>
          <AddTipQRForm />
        </Route>

        <Route path='/payment/success' exact>
          <AddTipQRSuccess />
        </Route>

        <Route path='/payment/error' exact>
          <AddTipQRError />
        </Route>

        <Route path='/instructivo' component={ExternalRedirect} />

        <Route>
          <FourOFour />
        </Route>
      </Switch>
    </Router>
  );
}
