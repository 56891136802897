function parseCurrencyStringToNumber(currencyString) {
  // Remove the currency symbol and any whitespace
  let numericString = currencyString.replace(/[^0-9,\.]/g, '');

  // Replace periods (thousand separators) with nothing
  numericString = numericString.replace(/\./g, '');

  // Replace comma (decimal separator) with a period
  numericString = numericString.replace(/,/g, '.');

  // Convert the string to a floating-point number
  const number = parseFloat(numericString);

  // Return the number, or NaN if the conversion failed
  return number;
}

module.exports = {
  parseCurrencyStringToNumber,
};
