import React from 'react';
import ConfirmPasswordModal from '../../../ConfirmPasswordModal';
import './style.css';

import { AbstractService } from '../../../../../services/abstractService';

export default function ConfirmSectorTurnoModal(props) {
  const [display, setDisplay] = React.useState('none');
  const [error, setError] = React.useState(false);
  const [succesfulCreate, setSuccesfulCreate] = React.useState(false);

  const handleDisplay = () => {
    setDisplay('none');
    props.hideConfirmAdd();
  };

  const handleAdd = async () => {
    setSuccesfulCreate(false);

    if (props.addShift) {
      props.handleAddArray(props.newShiftInfo);
      handleDisplay();

      const createShiftService = {
        url: `${process.env.REACT_APP_BFF_WEB_URL}/commerce/shifts/create`,
        method: 'POST',
        data: props.newShiftInfo,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('loggedUser')}`,
          'Content-Type': 'application/json',
        },
      };
      try {
        setError(false);

        await AbstractService(createShiftService);
      } catch (err) {
        setError(true);
      }
      props.onSuccess('addShift');
    } else {
      // props.handleAddArray('sectorsData', props.newSectorInfo);

      const createSectorService = {
        url: `${process.env.REACT_APP_BFF_WEB_URL}/commerce/sectors/create`,
        method: 'POST',
        data: props.newSectorInfo,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('loggedUser')}`,
          'Content-Type': 'application/json',
        },
      };
      try {
        setError(false);

        await AbstractService(createSectorService);
      } catch (err) {
        setError(true);
      }

      handleDisplay();
      props.onSuccess('addSector');
    }
    setSuccesfulCreate(true);
  };

  return (
    <ConfirmPasswordModal
      visiblePassword={props.visiblePassword}
      onSuccess={handleAdd}
    />
  );
}
