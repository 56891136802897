import React from 'react';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

import { AbstractService } from '../../services/abstractService';

import Loading from '../../components/Loading';
import TopNavBar from '../../components/TopNavBar';
import CommerceMenu from '../../components/CommerceDropdown';
import SideBar from '../../components/Sidebar';
import ProfilePic from '../../components/ProfilePic';
import ApprovalColaboradoresModal from '../../components/Modal/ApprovalColaboradoresModal';
import Unauthorized from '../../hoc/unauthorized';
import ErrorHoc from '../../components/ErrorMessage';

import './style.css';

function NoPending() {
  return <h3 className='approvalNoPending'>No hay pendientes</h3>;
}

function ApprovalCard(props) {
  const [enabled, setEnabled] = React.useState(true);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);

  function handleOpenModal() {
    setModalIsOpen(true);
  }

  const handleDismissModal = () => {
    setModalIsOpen(false);
  };

  const employeeUpdate = async (s) => {
    const employeePatch = await AbstractService({
      url: `${process.env.REACT_APP_BFF_WEB_URL}/employees/${props.employeeId}`,
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('loggedUser')}`,
        'Content-Type': 'application/json',
      },
      data: {
        status: s,
      },
    });
    props.onChange(props.employeeId);
  };

  const bindEmployeeSendEmail = async () => {
    const body = {
      reason: 'bind_to_commerce_email',
      receptor_email: props.employeeEmail,
      commerce_name: localStorage.getItem('commerceName'),
      commerce_address: localStorage.getItem('commerceAddress'),
    };
    const bindEmployeeEmail = {
      // url: `${process.env.REACT_APP_BFF_WEB_URL}/notification/commerce/bind`,
      method: 'POST',
      data: body,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('loggedUser')}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      setHasError(false);

      await AbstractService(bindEmployeeEmail);
    } catch (error) {
      setHasError(true);
    }
  };

  const handleReject = () => {
    if (!enabled) {
      return;
    }
    setEnabled(false);
    employeeUpdate('DENIED');
  };

  const handleAccept = () => {
    if (!enabled) {
      return;
    }
    setEnabled(false);
    employeeUpdate('APPROVED');
    bindEmployeeSendEmail();
  };

  return (
    <div>
      <div className='approvalMainCard'>
        <div className='approvalCardNameDiv'>
          {props.pfp ? (
            <img
              src={props.pfp}
              alt='foto de perfil'
              className='datosConfigImg datosConfigProfilePhoto'
            />
          ) : (
            <ProfilePic name={`${props.name[0]}${props.lastName[0]}`} />
          )}
          <div className='approvalCardFullNameDiv'>
            <h4 className='approvalCardFullName'>{props.name}</h4>
            <h4 className='approvalCardFullName'>{props.lastName}</h4>
            <p className='approvalCardDoc'>CUIT: {props.doc ?? '-'}</p>
          </div>
        </div>

        <div className='approvalCardButtonDiv'>
          <button className='approvalBtn' onClick={handleOpenModal}>
            Confirmar
          </button>

          <button className='approvalBtnSmall' onClick={handleReject}>
            Rechazar
          </button>
        </div>
      </div>
      {modalIsOpen && (
        <ApprovalColaboradoresModal
          display
          name={props.name}
          lastName={props.lastName}
          doc={props.doc}
          pfp={props.profile_photo}
          employeeId={props.employeeId}
          dismiss={handleDismissModal}
          handleAccept={handleAccept}
        />
      )}
    </div>
  );
}

function PendingList(props) {
  return (
    <Grid container rowSpacing={2}>
      {props.list.map((e) => {
        return (
          <Grid xs={4} key={e.id} item>
            <ApprovalCard
              name={e.user.name}
              lastName={e.user.last_name}
              doc={e.user.user_documents?.document_number}
              pfp={e.user.profile_photo}
              onChange={props.onChange}
              employeeId={e.id}
              employeeEmail={e.user.email}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}

export default function Approval() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [pendingList, setPendingList] = React.useState([]);
  const [commerceData, setCommerceData] = React.useState({});

  const [unauthorized, setUnauthorized] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);

  React.useEffect(() => {
    const user = localStorage.getItem('loggedUser');

    if (!user) {
      setUnauthorized(true);
      setHasError(false);
      setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    if (commerceData.name) {
      return;
    }

    setCommerceData(JSON.parse(localStorage.getItem('commerce_data')));
  }, [commerceData]);

  const fetchEmployees = async () => {
    try {
      const employeeList = await AbstractService({
        url: `${process.env.REACT_APP_BFF_WEB_URL}/employees/pending`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('loggedUser')}`,
          'Content-Type': 'application/json',
        },
      });
      setPendingList(employeeList ?? []);
    } catch (error) {
      setUnauthorized(false);
      setHasError(true);
    }
  };

  React.useEffect(() => {
    if (!isLoading) {
      return;
    }
    fetchEmployees().then(() => setIsLoading(false));
  }, [isLoading, pendingList]);

  const handleChanges = (removedId) => {
    setPendingList(pendingList.filter((e) => e.id !== removedId));
  };

  if (unauthorized) {
    return <Unauthorized />;
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : hasError ? (
        <ErrorHoc views />
      ) : (
        <div className='approvalDiv'>
          <TopNavBar>
            <CommerceMenu>
              <ion-icon name='person-outline' className='userIcon' />
              <h4 className='topNavBarChildrenTitle'>{commerceData.name}</h4>
              <ion-icon name='chevron-down-outline' className='arrowDownIcon' />
            </CommerceMenu>
          </TopNavBar>
          <div className='mainContentApprovalDiv'>
            <SideBar />
            <div className='innerApprovalDiv'>
              <h2 className='approvalMainTitle'>Alta de Colaboradores</h2>
              <p className='approvalSubtitle'>
                Las siguientes personas se quieren vincular a tu comercio.
              </p>
              {pendingList.length === 0 ? (
                <NoPending />
              ) : (
                <PendingList list={pendingList} onChange={handleChanges} />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
