/* eslint-disable default-case */
import React from 'react';
import './style.css';
import SelectShiftDropdown from '../../SelectShiftDropdown';
import ModalActionBar from '../../ModalActionBar';

import { AbstractService } from '../../../services/abstractService';

export default function SelectShiftModal(props) {
  const [display, setDisplay] = React.useState('none');
  const [isEnabled, setIsEnabled] = React.useState(true);
  const [showLoading, setShowLoading] = React.useState(false);

  const modalRef = React.useRef();

  const [activeShift, setActiveShift] = React.useState('');

  React.useEffect(() => {
    setIsEnabled(!!activeShift.id);
  }, [activeShift]);

  const handleActiveShift = (shift) => {
    setActiveShift(shift);
  };

  const handleDisplay = () => {
    setDisplay('none');
  };

  const handleStartShift = async () => {
    if (!activeShift.id) {
      return;
    }

    setShowLoading(true);
    setIsEnabled(false);

    const configNewShift = {
      url: `${process.env.REACT_APP_BFF_WEB_URL}/commerce/shift/start`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('loggedUser')}`,
        'Content-Type': 'application/json',
      },
      data: {
        commerce: localStorage.getItem('commerce_id'),
        shift_id: activeShift.id,
        shift_name: activeShift.name,
        employees: activeShift.employees.map((employee) => employee.user_id),
      },
    };

    try {
      const newShiftResponse = await AbstractService(configNewShift);
      setShowLoading(false);
      window.location.reload();
    } catch (err) {
      setShowLoading(false);
    }

    handleDisplay();
  };

  const handleClose = () => {
    handleDisplay();
  };

  React.useEffect(() => {
    if (props.visible) {
      setDisplay('block');
    }
  }, [props.visible]);

  return (
    <div
      style={{
        display,
        position: 'fixed',
        zIndex: 22,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        overflow: 'auto',
        backgroundColor: 'rgb(0,0,0)',
        backgroundColor: 'rgba(0,0,0,0.4)',
      }}
      className='modalSelectShiftFrame'
    >
      <div
        className='modalSelectShiftContent'
        ref={modalRef}
        onClick={(e) => {
          // do not close modal if anything inside modal content is clicked
          e.stopPropagation();
        }}
      >
        <h2 className='modalSelectShiftTitle'>¡Bienvenido!</h2>
        <p className='modalSelectShiftP'>¿Qué turno vas a iniciar?</p>

        <div className='modalSelectShiftDivInput'>
          <SelectShiftDropdown
            shifts={props.shifts}
            activeShift={activeShift}
            handleActiveShift={handleActiveShift}
          />
        </div>

        <ModalActionBar
          onPrimaryClick={handleStartShift}
          primaryDisabled={!isEnabled}
          primaryLabel='Confirmar'
          loading={showLoading}
          onSecondaryClick={handleClose}
          secondaryClass='borderless'
        />
      </div>
    </div>
  );
}
