import Card from '../../components/Card';
import HorizontalBox from '../../components/HorizontalBox';
import './style.css';

export default function Faq() {
  return (
    <div>
      <h2 className='faqFirstQuestion'>¿Cómo se procesa la propina?</h2>
      <HorizontalBox>
        <Card className='faqCard'>
          <span className='whatIsSubtitle'>Por código QR</span>
          <p className='faqText'>
            Sin necesidad de descargarse una app.
            <br />
            Los comensales son los que cargan las propinas a tu cuenta de Tipie
            desde su teléfono celular, el QR los lleva directamente a un link de
            pago.
          </p>
        </Card>
        <Card className='faqCard'>
          <span className='whatIsSubtitle'>Ambientes formales</span>
          <p className='faqText'>
            El comercio carga las propinas desde el Panel de Control de Tipie.
            Al llevarle la cuenta al comensal, se agrega la opción de sumarle la
            propina a la tarjeta.
          </p>
        </Card>
      </HorizontalBox>
      <HorizontalBox>
        <Card className='faqCard'>
          <h2 className='faqQuestion'>¿Cómo se distribuye el pago?</h2>
          <span className='whatIsSubtitle'>¡Como vos quieras!</span>
          <p className='faqText'>
            Vas a poder configurar en el Panel de Control la distribución de
            propina que el comercio elija.
          </p>
        </Card>
        <Card className='faqCard'>
          <h2 className='faqQuestion'>¿Cuándo se distribuye el pago?</h2>
          <span className='whatIsSubtitle'>¡Al cierre de cada turno!</span>
          <p className='faqText'>
            El dinero estará disponible tan pronto se acredite.
          </p>
        </Card>
      </HorizontalBox>
      <HorizontalBox>
        <Card className='faqCard'>
          <h2 className='faqQuestion'>¿Cómo se retira el dinero?</h2>
          <p className='faqText'>
            Por transferencia desde nuestra App. Es necesario que los usuarios
            de la App tengan asociada al menos una cuenta bancaria donde puedan
            recibir el dinero. ¡Sin límite de dinero por día!
          </p>
        </Card>
        <Card className='faqCard'>
          <h2 className='faqQuestion'>
            ¿Cuánto sale poner Tipie en mi negocio?
          </h2>
          <span className='whatIsSubtitle'>
            ¡Nada! No tenés gastos fijos por mes.
          </span>
          <p className='faqText'>
            Nuestra comisión es un pequeño porcentaje de cada propina procesada.
          </p>
        </Card>
      </HorizontalBox>
    </div>
  );
}
