import React from 'react';
import { useHistory, Link, Redirect } from 'react-router-dom';
import { Grid, Card, CardContent, Stack } from '@mui/material';
import moment from 'moment';
import numbro from 'numbro';
import SideBar from '../../components/Sidebar';
import TopNavBar from '../../components/TopNavBar';
import CommerceMenu from '../../components/CommerceDropdown';
import TipModal from '../../components/Modal/TipModal';
import ColaboradoresModal from '../../components/Modal/ColaboradoresModal';
import SelectShiftModal from '../../components/Modal/SelectShiftModal';
import FirstLoginModal from '../../components/Modal/FirstLogin';
import './style.css';
import 'moment/locale/es';

export default function Home({ content }) {
  const { state } = useHistory().location;

  const [visible, setVisible] = React.useState(false);
  const [visibleColaboradores, setVisibleColaboradores] = React.useState(false);
  const [visibleShift, setVisibleShift] = React.useState(false);
  const [disabledEndShift, setDisabledEndShift] = React.useState(false);

  const [hasAssignedData, setHasAssignedData] = React.useState(false);

  const [sector, setSector] = React.useState(null);
  const [endShift, setEndShift] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  const [sectors, setSectors] = React.useState([]);
  const [balance, setBalance] = React.useState('');
  const [balanceDecimal, setBalanceDecimal] = React.useState('');

  const [allEmployees, setAllEmployees] = React.useState([]);

  const [allShifts, setAllShifts] = React.useState([]);
  const [shiftName, setShiftName] = React.useState('');
  const [fromHour, setFromHour] = React.useState('');
  const [toHour, setToHour] = React.useState('');
  const [commerceName, setCommerceName] = React.useState('');
  const [pendingEmployees, setPendingEmployees] = React.useState('');

  const [firstLoginShiftStatus, setFirstLoginShiftStatus] =
    React.useState(false);
  const [visibleFirstLoginShift, setVisibleFirstLoginShift] =
    React.useState(false);

  const activeShift = content[0].apiMerchant[0]?.id;

  let firstLogin;

  React.useEffect(() => {
    setVisibleShift(false);
    if (!hasAssignedData) {
      const commerceData = content[0].apiUsers;
      const commerceConfig = content[0].apiMerchant;
      const shiftConfig = commerceData.shifts;
      const sectorConfig = commerceData.sectors;

      if (commerceData.employees.length > 0) {
        if (
          commerceData.employees.some(
            (employee) => employee.status === 'APPROVED',
          )
        ) {
          firstLogin = false;
          setFirstLoginShiftStatus(false);
        } else {
          firstLogin = true;
          setFirstLoginShiftStatus(true);
        }
      } else {
        firstLogin = true;
        setFirstLoginShiftStatus(true);
      }

      setPendingEmployees(
        content[0].pendingEmployees.filter((e) => e.status === 'PENDING')
          .length,
      );

      if (commerceConfig.length > 0) {
        setVisibleShift(false); // Doesn't open the shift selector

        const totalAmount = commerceConfig[0].amount_partial;
        const amountToMoney = numbro(totalAmount).formatCurrency({
          mantissa: 2,
        });
        const decimalsArray = amountToMoney.split('.');
        setBalanceDecimal(decimalsArray[1]);
        setBalance(decimalsArray[0]);

        const { shiftId } = commerceConfig[0];
        const currentShift = commerceData.shifts.filter(
          (x) => x.id === shiftId,
        )[0];
        setShiftName(currentShift?.name);
        setFromHour(currentShift?.from_hour);
        setToHour(currentShift?.to_hour);

        // Asign employee array for the turn to each sector
        if (!commerceConfig[0].employee_partial) {
          commerceConfig[0].employee_partial = [];
        }

        const shiftEmployees = [];

        commerceData.employees.forEach((registeredEmployee) => {
          const isPresent = commerceConfig[0].employee_partial.find(
            (activeEmployeeId) => {
              return activeEmployeeId === registeredEmployee.user_id;
            },
          );
          shiftEmployees.push({
            ...registeredEmployee,
            active: !!isPresent, // just the boolean, I don't want the whole object
          });
        });

        sectorConfig.forEach((sector) => {
          sector.employees = shiftEmployees.filter((employee) => {
            if (employee.status === 'APPROVED') {
              return employee.sector_id === sector.id;
            }
          });
        });
      } else {
        if (firstLogin) {
          setVisibleShift(false);
        } else {
          setVisibleShift(true); // Opens the shift selector
        }

        commerceData.shifts.forEach((shift) => {
          shift.employees = commerceData.employees.filter((employee) => {
            return employee.shift_id === shift.id;
          });
        });

        setBalanceDecimal('00');
        setBalance('$0');
        // setShiftName('No hay turno activo');
        // setFromHour('');
        // setToHour('');
        setDisabledEndShift(true);
      }

      setCommerceName(commerceData.name);
      setSectors(sectorConfig);
      setAllShifts(shiftConfig);
      setAllEmployees(commerceData.employees);

      localStorage.setItem('commerce_data', JSON.stringify(commerceData));
      localStorage.setItem('commerce_config', JSON.stringify(commerceConfig));

      localStorage.setItem('shift_config', JSON.stringify(shiftConfig));
      localStorage.setItem('sector_config', JSON.stringify(sectorConfig));

      localStorage.setItem('commerce_id', commerceData.id);

      setHasAssignedData(true);
    }
  }, [content, hasAssignedData]);

  const showModal = () => {
    setVisible(true);
  };

  const showModalColaboradores = (sector) => {
    setSector(sectors.find((element) => element.name === sector));
    setVisibleColaboradores(true);
  };

  const showModalShift = (sector) => {
    setVisibleShift(true);
  };

  const handleEndShift = () => {
    setEndShift(true);
  };

  const handleGoBack = () => {
    if (endShift) {
      setEndShift(false);
    }
  };

  const handleStartShift = () => {
    if (firstLogin || firstLoginShiftStatus) {
      setVisibleShift(false);
      setVisibleFirstLoginShift(true);
    } else {
      setVisibleShift(true);
      setVisibleFirstLoginShift(false);
    }
  };

  const handleShiftActivity = () => {
    if (firstLogin || !activeShift || firstLoginShiftStatus) {
      handleStartShift();
    } else {
      handleEndShift();
    }
  };

  const hideVisibleFirstLoginShift = () => {
    setVisibleFirstLoginShift(false);
  };

  React.useEffect(() => {
    if (visible) {
      setVisible(false);
    } else if (visibleColaboradores) {
      setVisibleColaboradores(false);
    }
  }, [visible, visibleColaboradores]);

  if (endShift) {
    return <Redirect to='/cierre-turno' />;
  }

  return (
    <div
      style={{
        width: '100%',
        // minHeight: '100vh',
        height: '100%',
        backgroundColor: endShift ? 'white' : '#F5F5F5',
        position: 'relative',
        overflow: 'hidden',
      }}
      className='homeDiv'
    >
      <TopNavBar>
        <CommerceMenu activeShift={activeShift}>
          <ion-icon name='person-outline' className='userIcon' />
          <h4 className='topNavBarChildrenTitle'>{commerceName}</h4>
          <ion-icon name='chevron-down-outline' className='arrowDownIcon' />
        </CommerceMenu>
      </TopNavBar>
      <div className='mainContentHomeDiv'>
        <SideBar />
        <div className='homeCardContainerDiv'>
          {/* {endShift ? (
                        <EndShift handleGoBack={handleGoBack} sectors={sectors} shiftName={shiftName} activeShift={activeShift} />
                    ) : ( */}
          <Grid container spacing={2} paddingX='24px' paddingBottom='24px'>
            <Grid item xs={8}>
              <Card
                raised
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <p className='homeCardOneParagraph'>{moment().format('LL')}</p>
                <h3 className='homeCardOneTitle'>
                  Total de propinas del turno
                </h3>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height: '60%',
                  }}
                >
                  <h1 className='homeCardOneTotal'>
                    {content[0].apiMerchant.length > 0
                      ? `$${parseInt(balance.replace('$', '')).toLocaleString(
                          'de-DE',
                        )}`
                      : balance}
                    <span className='homeCardOneSpan'>{balanceDecimal}</span>
                  </h1>
                  <button
                    className={
                      firstLogin || !activeShift
                        ? 'homeCardOneButtonDisabled'
                        : 'homeCardOneButton'
                    }
                    onClick={showModal}
                    disabled={!!(firstLogin || !activeShift)}
                  >
                    Agregar propina
                  </button>
                </div>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card
                raised
                style={
                  firstLogin || !activeShift
                    ? {
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }
                    : { height: '100%' }
                }
              >
                {firstLogin || !activeShift ? (
                  <h4 className='homeNoActiveTitle'>No hay turno activo</h4>
                ) : (
                  <h2 className='homeCardTwoTitle'>Turno {shiftName}</h2>
                )}
                <div
                  className={
                    firstLogin || !activeShift
                      ? 'homeStartShiftDiv'
                      : 'homeEndShiftDiv'
                  }
                >
                  {firstLogin || !activeShift ? null : (
                    <p className='homeCardTwoParagraph'>
                      {fromHour} - {toHour}
                    </p>
                  )}

                  <button
                    className={
                      firstLogin || !activeShift
                        ? 'homeCardTwoButtonDisabled'
                        : 'homeCardTwoButton'
                    }
                    onClick={handleShiftActivity}
                    // disabled={disabledEndShift}
                    // style={{ cursor: disabledEndShift && (!firstLogin || activeShift)  ? 'not-allowed' : 'pointer'}}
                  >
                    {activeShift ? 'Cerrar turno' : 'Iniciar turno'}
                  </button>
                </div>
              </Card>
            </Grid>
          </Grid>

          <Stack
            spacing={2}
            direction='row'
            paddingX='24px'
            alignSelf='flex-start'
          >
            {sectors.map((sector, i) => (
              <Card
                key={i}
                raised
                style={
                  sectors.length > 1
                    ? sectors.length === 2
                      ? {
                          width: '50%',
                        }
                      : {
                          width: '40%',
                        }
                    : null
                }
              >
                <CardContent
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <h3
                    className={
                      sectors.length >= 4
                        ? 'homeSmallerTitle4'
                        : 'homeSmallerTitle'
                    }
                  >
                    Colaboradores en {sector.name}
                  </h3>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      height: '60%',
                    }}
                  >
                    <h1
                      className={
                        sectors.length >= 4
                          ? 'homeSmallerNumber4'
                          : 'homeSmallerNumber'
                      }
                    >
                      {firstLogin || !activeShift
                        ? '-'
                        : sector.employees?.filter(
                            (employee) => employee.active,
                          ).length ?? 0}
                    </h1>
                    <p
                      className={
                        sectors.length >= 4
                          ? 'homeSmallerSeeMore4'
                          : 'homeSmallerSeeMore'
                      }
                      onClick={() => showModalColaboradores(sector.name)}
                    >
                      Ver más
                    </p>
                  </div>
                </CardContent>
              </Card>
            ))}
          </Stack>

          <Grid container spacing={2} paddingX='24px' paddingTop='24px'>
            <Grid item xs={4}>
              <Card raised>
                <Link to='/alta' style={{ textDecoration: 'none' }}>
                  <CardContent
                    sx={{
                      height: 100,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <h3 className='homePendingTitle'>Altas pendientes</h3>
                    <h1 className='homePendingNumber'>
                      {firstLogin || pendingEmployees === 0
                        ? '-'
                        : `+${pendingEmployees}`}
                    </h1>
                  </CardContent>
                </Link>
              </Card>
            </Grid>
          </Grid>
          {/* )} */}
        </div>
      </div>
      {/* <h2>Home</h2>
            <Link to="checkout">CHECKOUT</Link> */}

      {/* PROPINA MODAL */}
      <TipModal visible={visible} />

      {/* COLABORADORES MODAL */}
      <ColaboradoresModal
        sector={sector}
        visible={visibleColaboradores}
        activeShift={activeShift}
        firstLogin={firstLogin}
      />

      {/* SHIFT MODAL */}
      <SelectShiftModal visible={visibleShift} shifts={allShifts} />

      {/* FIRST LOGIN MODAL */}
      <FirstLoginModal
        visible={visibleFirstLoginShift}
        dismiss={hideVisibleFirstLoginShift}
      />
    </div>
  );
}
