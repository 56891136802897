import React from 'react';
import './style.css';
import ProfilePic from '../ProfilePic';
import AddRemoveButton from '../AddRemoveButton';

import add from '../../assets/iconoAdd.svg';
import remove from '../../assets/iconoRemove.svg';

export default function ColaboradorItem({
  name,
  isPresent,
  status,
  className,
  photo,
  lastName,
  nickname,
  ...props
}) {
  const nameInitials = name;
  const [showRemoveBtn, setShowRemoveBtn] = React.useState(false);
  const [showAddBtn, setShowAddBtn] = React.useState(false);

  let logo;
  if (nameInitials.length > 1) {
    logo = (nameInitials[0] + nameInitials[1]).toUpperCase();
  }

  const [styleInactive, setStyleInactive] = React.useState('');
  const [styleActive, setStyleActive] = React.useState(false);

  React.useEffect(() => {
    if (!isPresent) {
      setShowAddBtn(true);
      setShowRemoveBtn(false);
    } else {
      setShowAddBtn(false);
      setShowRemoveBtn(true);
    }
  }, [isPresent]);

  const handleAdd = (employeeId) => {
    props.updateArray('ADD', employeeId);
    setStyleActive(true);
    setStyleInactive('');
    setShowAddBtn(false);
    setShowRemoveBtn(true);
  };

  const handleRemove = (employeeId) => {
    props.updateArray('REMOVE', employeeId);
    setStyleActive(false);
    setStyleInactive('colaboradorInactive');
    setShowRemoveBtn(false);
    setShowAddBtn(true);
  };

  // Determine button properties based on state
  let buttonProps = null;

  if (showRemoveBtn) {
    buttonProps = {
      icon: remove,
      ariaLabel: 'Remover colaborador',
      onClick: () => handleRemove(props.id),
    };
  } else if (showAddBtn) {
    buttonProps = {
      icon: add,
      ariaLabel: 'Agregar colaborador',
      onClick: () => handleAdd(props.id),
    };
  }

  return (
    <div className='colaboradorItemDiv'>
      <div
        className={`colaboradorItemData ${styleInactive} ${
          status || styleActive ? '' : 'colaboradorInactive'
        } `}
      >
        {photo ? (
          <img
            src={photo}
            alt={`Foto de perfil de ${name}`}
            className='colaboradorItemImg'
          />
        ) : (
          <ProfilePic name={logo} className='colaboradorItemImg' />
        )}
        <div className={`colaboradorItemText ${className}`}>
          <p className='colaboradorItemFirst'>{name}</p>
          <p className='colaboradorItemFirst'>{lastName}</p>
          {nickname ? (
            <p className='colaboradorItemSecond'>Alias: {nickname}</p>
          ) : null}
        </div>
      </div>
      {buttonProps && <AddRemoveButton {...buttonProps} />}
    </div>
  );
}
