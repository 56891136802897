import { useHistory } from 'react-router-dom';
import './style.css';

export default function NavButton(props) {
  const history = useHistory();

  const buttonClasses = {
    primary: 'navButtonPrimary',
    secondary: 'navButtonSecondary',
  };
  const className = `${buttonClasses[props.type ?? 'primary']} ${
    props.className
  }`;

  const isDisabled = props.disabled && !props.to;

  const navigation = () => {
    if (!isDisabled) {
      history.push(props.to);
    }
  };

  return (
    <button
      className={className}
      style={{ marginRight: 30 }}
      disabled={isDisabled}
      onClick={navigation}
    >
      {props.children}
    </button>
  );
}
