import { Menu } from '@material-ui/core';
import * as React from 'react';
import Logout from './logout.jsx';

function CommerceDropdown(props) {
  return (
    <Menu open={props.open} onClose={props.onClose} anchorEl={props.anchor}>
      <Logout setMenu={props.setMenu} activeShift={props.activeShift} />
    </Menu>
  );
}

export default function CommerceButton(props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [anchor, setAnchor] = React.useState(null);
  const [menu, setMenu] = React.useState(null);
  const toggleDropdown = () => setIsOpen(!isOpen);
  const handleOpen = (e) => {
    setAnchor(e.currentTarget);
    toggleDropdown();
  };

  return (
    <div className='topNavBarChildrenDiv' onClick={handleOpen}>
      {props.children}
      <CommerceDropdown
        open={isOpen}
        onClose={toggleDropdown}
        anchor={anchor}
        setMenu={setMenu}
      />
      {menu}
    </div>
  );
}
