import React from 'react';
import CustomizedInputs from '../../../Forms/CustomizedInputs';
import ModalActionBar from '../../../ModalActionBar';
import './style.css';
import { LoginService } from '../../../../services/loginService';
import { AbstractService } from '../../../../services/abstractService';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { useHistory } from 'react-router-dom';

export default function DeleteSectorTurnoModal(props) {
  const [display, setDisplay] = React.useState('none');
  const [password, setPassword] = React.useState('');
  const [disabled, setDisabled] = React.useState(true);
  const [error, setError] = React.useState(false);

  const [mustSubmit, setMustSubmit] = React.useState(false);
  const [succesfulDelete, setSuccesfulDelete] = React.useState(false);
  const [userData, setUserData] = React.useState('');

  const [showLoading, setShowLoading] = React.useState(false);

  // EYE ICON
  const [visibilityPassword, setVisibilityPassword] = React.useState(false);

  const togglePassword = () => {
    if (visibilityPassword) {
      setVisibilityPassword(false);
    } else {
      setVisibilityPassword(true);
    }
  };
  // EYE ICON
  const [displayLogout, setDisplayLogout] = React.useState('none');

  React.useEffect(() => {
    if (!userData) {
      setUserData(localStorage.getItem('userdata'));
    }
  }, [userData]);

  React.useEffect(() => {
    if (props.visible) {
      setDisplay('block');
    }
  }, [props.visible]);

  const handleDelete = async () => {
    setMustSubmit(false);
    setSuccesfulDelete(false);
    setDisabled(true);
    setShowLoading(true);
    try {
      let authBody;
      if (userData) {
        authBody = {
          userdata: userData,
          password: password.trim(),
        };
      }

      const res = await LoginService(authBody);

      if (res.status_code !== 200) {
        throw new Error('Unable to authenticate');
        setShowLoading(false);
      }

      if (props.deleteShift) {
        const deleteShiftService = {
          url: `${process.env.REACT_APP_BFF_WEB_URL}/profile/commerce/shift/${props.deleteShiftId}`,
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('loggedUser')}`,
            'Content-Type': 'application/json',
          },
        };
        try {
          setError(false);

          await AbstractService(deleteShiftService);
          setShowLoading(false);
          // props.handleFilterArray(props.deleteShiftId);
          handleDisplay();
          props.handleSuccess('deleteShift', props.deleteShift);
          setPassword('');
          window.location.reload();
        } catch (err) {
          setShowLoading(false);
          setError(true);
          setDisabled(false);
        }
      } else if (props.deleteSector) {
        const deleteSectorService = {
          url: `${process.env.REACT_APP_BFF_WEB_URL}/profile/commerce/sector/${props.deleteSectorId}`,
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('loggedUser')}`,
            'Content-Type': 'application/json',
          },
        };

        try {
          setError(false);
          await AbstractService(deleteSectorService);
          setShowLoading(false);
          handleDisplay();
          props.handleFilterArray('sectorsData', props.deleteSectorId);
          props.handleSuccess(
            'deleteSector',
            props.deleteSector,
            props.deteleSectorId,
          );
          setPassword('');
        } catch (error) {
          setShowLoading(false);
          setError(true);
          setDisabled(false);
        }
      }

      setSuccesfulDelete(true);
      setMustSubmit(true);
    } catch (err) {
      setShowLoading(false);
      setDisabled(false);
      setError(true);
      setDisplayLogout(true);
    }
  };

  const handleDisplay = () => {
    setDisplay('none');
    setPassword('');
    if (props.hideDelete) {
      props.hideDelete();
    }
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  React.useEffect(() => {
    if (password.length > 1) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [password]);

  const history = useHistory();

  const handleLogout = () => {
    localStorage.setItem('loggedUser', null);
    localStorage.setItem('username', null);
    history.push('login');
  };

  const handleLogoutModal = () => {
    setDisplayLogout('none');
  };

  return (
    <>
      <div
        style={{
          display,
          position: 'fixed',
          zIndex: 22,
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          overflow: 'auto',
          backgroundColor: 'rgb(0,0,0)',
          backgroundColor: 'rgba(0,0,0,0.4)',
        }}
        onClick={handleDisplay}
      >
        <div
          className='modalDeleteSectorTurnoContent'
          onClick={(e) => {
            // do not close modal if anything inside modal content is clicked
            e.stopPropagation();
          }}
        >
          <h2 className='modalDeleteSectorTurnoTitle'>
            {props.deleteShift
              ? `¿Querés eliminar el turno ${props.deleteShift}?`
              : `¿Querés eliminar el sector ${props.deleteSector}?`}
          </h2>
          <div className='modalDeleteSectorTurnoInput'>
            <CustomizedInputs
              label='Contraseña'
              password={!visibilityPassword ? password : null}
              handlePassword={handlePassword}
              error={error}
              deleteError={() => setError(false)}
              value={password}
            />
            {visibilityPassword ? (
              <VisibilityOffIcon className='eyeIcon' onClick={togglePassword} />
            ) : (
              <VisibilityIcon className='eyeIcon' onClick={togglePassword} />
            )}
            {/* <p className={error ? "modalDeleteSectorTurnoPassword" : "modalDeleteSectorTurnoPasswordNone"}>Contraseña incorrecta!</p> */}
          </div>

          <ModalActionBar
            onPrimaryClick={handleDelete}
            primaryDisabled={disabled}
            primaryLabel='Eliminar'
            onSecondaryClick={handleDisplay}
            loading={showLoading}
          />
        </div>
      </div>
      {/* Logout */}
      <div
        style={{
          display: displayLogout,
          position: 'fixed',
          zIndex: 22,
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          overflow: 'auto',
          backgroundColor: 'rgb(0,0,0)',
          backgroundColor: 'rgba(0,0,0,0.4)',
        }}
        onClick={handleLogoutModal}
      >
        <div
          className='modalEditTurnoContent'
          onClick={(e) => {
            // do not close modal if anything inside modal content is clicked
            e.stopPropagation();
          }}
        >
          <h2 className='modalEditTurnoTitle'>Contraseña incorrecta</h2>
          <p>
            Si olvidaste la contraseña podes restablecerla cerrando la sesión.
          </p>

          <ModalActionBar
            onPrimaryClick={handleLogout}
            primaryLabel='Cerrar sesión'
            secondaryLabel='Volver a intentarlo'
            onSecondaryClick={handleLogoutModal}
          />
        </div>
      </div>
    </>
  );
}
