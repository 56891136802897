/* eslint-disable default-case */
import React, { createElement } from 'react';
import './style.css';
import NativeSelect from '@mui/material/NativeSelect';
import { styled, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@material-ui/core/Divider';
import ModalActionBar from '../../../ModalActionBar';
import ProfilePic from '../../../ProfilePic';

import ConfirmPasswordModal from '../../ConfirmPasswordModal';

import { AbstractService } from '../../../../services/abstractService';

export default function EditColaboradorModal(props) {
  const [display, setDisplay] = React.useState('none');
  const [sector, setSector] = React.useState();
  const [shift, setShift] = React.useState();
  const [disable, setDisable] = React.useState(true);
  const [hasError, setHasError] = React.useState(false);
  const [editColaborador, setEditColaborador] = React.useState(false);
  const [deleteEmployee, setDeleteEmployee] = React.useState(false);
  const [displayPassword, setDisplayPassword] = React.useState(false);

  const hideVisiblePassword = () => {
    setDisplayPassword(false);
  };

  const modalRef = React.useRef();

  const scrollBackToTop = () => {
    modalRef.current.scrollTop = 0;
  };

  const handleDisplay = () => {
    scrollBackToTop();
    setDisplay('none');
    setShift('');
    setSector('');
  };

  React.useEffect(() => {
    if (props.visible) {
      setDisplay('block');
    }
  }, [props.visible]);

  React.useEffect(() => {
    if (sector || shift) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [sector, shift]);

  const handleChange = (e, value) => {
    switch (value) {
      case 'sector':
        setSector(e.target.value);
        break;
      case 'shift':
        setShift(e.target.value);
        break;
      default:
        throw new Error('Input inválido');
    }
  };

  // const handleConfirmPassword = () => {
  //   setDisplayPassword(true)
  // }

  const handleSubmit = async () => {
    const body = {
      shift_id: shift,
      sector_id: sector,
    };

    const employeeId = props.id;
    const editEmployeeData = {
      url: `${process.env.REACT_APP_BFF_WEB_URL}/employees/${employeeId}`,
      method: 'PATCH',
      data: body,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('loggedUser')}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      setHasError(false);

      await AbstractService(editEmployeeData);
    } catch (error) {
      setHasError(true);
    }

    handleDisplay();
    props.successEditColaborador();
  };

  const dismissEmployee = async () => {
    const employeeId = props.id;
    const dismissEmployeeData = {
      url: `${process.env.REACT_APP_BFF_WEB_URL}/employees/${employeeId}`,
      method: 'PATCH',
      data: {
        shift_id: shift,
        sector_id: sector,
        status: 'REVOKED',
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('loggedUser')}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      setHasError(false);

      const res = await AbstractService(dismissEmployeeData);
    } catch (error) {
      setHasError(true);
    }

    handleDisplay();
    props.successEditColaborador();
    dismissEmployeeSendEmail();
  };

  const dismissEmployeeSendEmail = async () => {
    const body = {
      reason: 'dismissed_employee_email',
      receptor_email: props.email,
      commerce_name: localStorage.getItem('commerceName'),
      commerce_address: localStorage.getItem('commerceAddress'),
    };
    const dismissEmployeeEmail = {
      // url: `${process.env.REACT_APP_BFF_WEB_URL}/notification/dismiss`,
      method: 'POST',
      data: body,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('loggedUser')}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      setHasError(false);

      await AbstractService(dismissEmployeeEmail);
    } catch (error) {
      setHasError(true);
    }
  };

  const handleDisplayPasswordModal = (value) => {
    switch (value) {
      case 'editColaborador':
        setDisplayPassword(true);
        setEditColaborador(true);
        break;
      case 'dismissColaborador':
        setDisplayPassword(true);
        setDeleteEmployee(true);
        break;
      default:
        throw new Error('Input inválido');
    }
  };

  const useStyles = makeStyles(() => ({
    select: {
      '&.MuiInput-underline:after': {
        borderBottomColor: '#E98A15',
      },
      '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        borderBottom: '1px solid #00000029',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
          borderRadius: 4,
          borderColor: '#00000029',
          boxShadow: '0 0 0 0.2rem #00000029',
        },
      },
    },
  }));

  const classes = useStyles();

  return (
    <>
      <div
        style={{
          display,
          position: 'fixed',
          zIndex: 22,
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          overflow: 'auto',
          backgroundColor: 'rgb(0,0,0)',
          backgroundColor: 'rgba(0,0,0,0.4)',
        }}
        onClick={handleDisplay}
      >
        <div
          className='modalEditColaboradorContent'
          ref={modalRef}
          onClick={(e) => {
            // do not close modal if anything inside modal content is clicked
            e.stopPropagation();
          }}
        >
          <button className='ColaboradorCloseBtn' onClick={handleDisplay}>
            <CloseIcon />
          </button>

          <div className='employeeProfile'>
            <div
              style={{ height: '100%', display: 'flex', alignItems: 'center' }}
            >
              {props.pfp ? (
                <img
                  src={props.pfp}
                  alt='foto de perfil'
                  className='datosConfigImg datosConfigProfilePhoto'
                />
              ) : (
                <ProfilePic name={`${props.name[0]}`} />
              )}
            </div>

            <div className='employeeData'>
              <h4 className='colaboradorName'>{props.name}</h4>
              <Divider flexItem orientation='horizontal' />

              {/* Spec calls for alias here. Omitting, can't edit elsewhere */}
              <div className='modalEditColaboradoresSubtitleDiv'>
                {props.nickname ? (
                  <p className='modalEditColaboradoresAliasText'>
                    Alias: {props.nickname}
                  </p>
                ) : null}
                <p className='modalEditColaboradoresAliasText'>
                  ID: {props.id}
                </p>
              </div>
            </div>
          </div>

          <div className='modalEditColaboradorDivInput'>
            <form className='modalEditColaboradorForm'>
              {/* TURNO */}
              <label className='modalEditLabel' id='turno'>
                Turno
              </label>
              <NativeSelect
                defaultValue={props.currentShift}
                inputProps={{
                  name: 'Turno',
                  id: 'uncontrolled-native',
                }}
                onChange={(e) => handleChange(e, 'shift')}
                id='turno'
                className={`${classes.select} modalEditSelectForm`}
              >
                {/* Turno actual */}
                <option disabled value=''>
                  {props.currentShift} ({props.currentFromHour} -{' '}
                  {props.currentToHour} hs)
                </option>
                {props.shiftsData.map((shift) => {
                  if (shift.name !== props.currentShift) {
                    return (
                      <option value={shift.id} key={shift.id}>
                        {shift.name} ({shift.from_hour} - {shift.to_hour} hs)
                      </option>
                    );
                  }
                })}
              </NativeSelect>

              {/* SECTOR */}

              <label
                className='modalEditLabel modalEditLabelSector'
                id='sector'
              >
                Sector
              </label>

              <NativeSelect
                defaultValue={props.currentSector}
                onChange={(e) => handleChange(e, 'sector')}
                id='sector'
                className={`${classes.select} modalEditSelectForm`}
              >
                {/* Sector actual */}
                <option disabled value=''>
                  {props.currentSector} ({props.currentPercentage}% de las
                  propinas)
                </option>
                {props.sectorsData.map((sector) => {
                  if (sector.name !== props.currentSector) {
                    return (
                      <option value={sector.id} key={sector.id}>
                        {sector.name} ({sector.percentage}% de las propinas)
                      </option>
                    );
                  }
                })}
              </NativeSelect>
            </form>

            <ModalActionBar
              onPrimaryClick={() =>
                handleDisplayPasswordModal('editColaborador')
              }
              primaryDisabled={disable}
              onSecondaryClick={() =>
                handleDisplayPasswordModal('dismissColaborador')
              }
              secondaryLabel='Eliminar colaborador'
              secondaryClass='borderless'
            />
          </div>

          {/*
        <p className="modalEditColaboradorTitle">Editá el sector, el turno o el alias</p>
        <p className="modalEditColaboradorName">{props.name}</p>
        <div className="modalEditColaboradorDivInput">
          <form
            //   onSubmit={handleFormSubmit}
            className="modalEditColaboradorForm"
          >
            <CustomField
              label='Sector'
              value={sector}
              variant='standard'
              fullWidth
              select={true}
              onChange={(e) => handleChange(e, 'sector')}
            >
              {props.sectorsData.map((sector) => {
                return <MenuItem
                  key={sector.id}
                  value={sector.id}
                >
                  {sector.name}
                </MenuItem>
              }
              )}
            </CustomField>

            <CustomField
              label='Turno'
              variant='standard'
              fullWidth
              value={shift}
              select={true}
              onChange={(e) => handleChange(e, 'shift')}
            >

              {props.shiftsData.map((shift) => {
                return <MenuItem
                  key={shift.id}
                  value={shift.id}
                >
                  {shift.name}
                </MenuItem>
              }
              )}
            </CustomField>

            <TextField
              className='inputEditColaboradorLabelSelected'
              label='Alias'
              variant='standard'
              fullWidth
              value={alias}
              onChange={(e) => handleChange(e, 'alias')}
            />

          </form>

        </div>

        <ModalActionBar
          onPrimaryClick={handleSubmit}
          primaryDisabled={disable}
          onSecondaryClick={handleDisplay}

        />

        */}
        </div>
      </div>

      {/* //Modal para confirmar contraseña */}
      <ConfirmPasswordModal
        visiblePassword={displayPassword}
        hideVisiblePassword={hideVisiblePassword}
        onSuccess={
          editColaborador
            ? handleSubmit
            : null || deleteEmployee
            ? dismissEmployee
            : null
        }
      />
    </>
  );
}
