import React from 'react';
import { Button } from '@mui/material';
import './style.css';

export default function ErrorPercentageModal(props) {
  const [display, setDisplay] = React.useState('none');

  const handleDisplay = () => {
    setDisplay('none');
  };

  React.useEffect(() => {
    if (props.visible) {
      setDisplay('block');
    }
  }, [props.visible]);

  return (
    <div
      style={{
        display,
        position: 'fixed',
        zIndex: 22,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        overflow: 'auto',
        backgroundColor: 'rgb(0,0,0)',
        backgroundColor: 'rgba(0,0,0,0.4)',
      }}
      onClick={handleDisplay}
    >
      <div className='modalErrorPercentageContent'>
        <div className='modalErrorPercentage'>
          <h2 className='modalErrorPercentageMainTitle'>Error</h2>
          <h4 className='modalErrorPercentageText'>{props.text}</h4>

          <div className='modalErrorPercentageBtnDiv'>
            <Button
              variant='text'
              sx={{
                color: '#E98A15',
                textTransform: 'none',
                '&:hover': { bgcolor: '#E98A150a' },
                alignSelf: 'center',
                paddingLeft: 0,
                marginLeft: 0,
              }}
              onClick={props.handleAction}
            >
              {props.button}
            </Button>
            <button
              className='modalErrorSaveChanges'
              style={{ width: 150 }}
              onClick={handleDisplay}
            >
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
