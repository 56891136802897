import * as React from 'react';
import { Dialog } from '@material-ui/core';
import { AbstractService } from '../../../../services/abstractService';
import RegisterForm from './registerForm.jsx';
import icon from '../../../../assets/icono-success.svg';
import './styles.css';

function Done(props) {
  return (
    <div className='registerModal'>
      <img src={icon} />
      <h1>¡Enviaste el formulario!</h1>
      <p>
        Te vamos a estar contactando dentro de las 48 hs para coordinar una
        visita a tu comercio.
      </p>
      <button className='navButtonPrimary' onClick={props.onClose}>
        Volver
      </button>
    </div>
  );
}

export default function RegisterCommerce(props) {
  const [success, setSuccess] = React.useState(false);
  const [displayModalError, setDisplayModalError] = React.useState(false);

  const onSubmit = (data) => {
    AbstractService({
      url: `${process.env.REACT_APP_BFF_WEB_URL}/commerce/register`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    })
      .then((r) => {
        setDisplayModalError(false);
        setSuccess(true);
      })
      .catch((e) => {
        setSuccess(false);
        setDisplayModalError(true);
      });
  };

  const onClose = () => {
    setDisplayModalError(false);
    props.onClose();
  };

  const popUpOnClose = () => {
    props.onClose();
    setSuccess(false);
  };

  return (
    <Dialog open={props.open} onClose={onClose} maxWidth='lg'>
      {success ? (
        <Done onClose={popUpOnClose} />
      ) : (
        <RegisterForm
          onClose={onClose}
          onSubmit={onSubmit}
          displayModalError={displayModalError}
        />
      )}
    </Dialog>
  );
}
