import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Link } from 'react-router-dom';
import iconSuccess from '../../assets/icono-success.svg';
import TopNavBar from '../../components/TopNavBar';
import './style.css';

export default function ProcessingTransaction() {
  return (
    <div className='cardProcessingTransactionContainer'>
      <TopNavBar />
      <div>
        <Card className='cardProcessingTransaction'>
          <CardContent className='cardProcessingTransactionContent'>
            <img src={iconSuccess} alt='Icono proceso éxitoso' />
            <h4 className='cardProcessingTransactionTitle'>
              ¡La transacción está siendo procesada!
            </h4>
            <p>Esta operación puede demorar unos minutos.</p>
            <Link to='/home' className='linkProcessingTransaction'>
              Volver al inicio
            </Link>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
