import { useState, useEffect } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import ProfilePic from '../../ProfilePic';
import ContactIcon from '../../../assets/contacto.svg';
import ErrorModal from '../../Modal/ErrorModal';

import './style.css';
import { AbstractService } from '../../../services/abstractService';

export default function DatosConfig(props) {
  const [visible, setVisible] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [visibleError, setVisibleError] = useState(false);

  const base64ToBlob = (base64, mimeType) => {
    const byteCharacters = atob(
      base64.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''),
    );
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  };

  const showModalError = () => {
    setVisibleError(true);
  };

  const hideModalError = () => {
    setVisibleError(false);
  };

  const commerceDocument = props.data.documents;
  const nameInitials = props.commerceName;

  if (!config) {
    const user = localStorage.getItem('loggedUser');
    if (!user) {
      setHasError(true); // develop
    }

    setConfig({
      url: `${process.env.REACT_APP_BFF_WEB_URL}/qr/download`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user}`,
        'Content-Type': 'application/json',
      },
    });
  }

  // Div iniciales de comercio
  let logo;

  if (nameInitials.length > 1) {
    logo = (nameInitials[0][0] + nameInitials[1][0]).toUpperCase();
  } else {
    logo = nameInitials[0][0].toUpperCase();
  }
  (nameInitials[0][0] + nameInitials[1][0]).toUpperCase();

  // Agregar img de comercio
  const handleAddProfilePhoto = () => {
    setVisible(true);
  };

  useEffect(() => {
    if (visible) {
      setVisible(false);
    }
  }, [visible]);

  // Hover para agregar img de comercio
  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const handleDownloadQR = async () => {
    setLoading(true);
    try {
      const res = await AbstractService(config);
      if (res && res.response && res.response.length > 0) {
        const qrData = res.response[0].qr;
        const qrBlob = base64ToBlob(qrData, 'image/png');
        const qrUrl = URL.createObjectURL(qrBlob);

        const link = document.createElement('a');
        link.href = qrUrl;
        link.download = 'tipie-propina-qr.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      setLoading(false);
    } catch (err) {
      console.error('Error downloading QR code: ', err);
      setLoading(false);
      showModalError();
    }
  };

  return (
    <div className='datosConfigCardContainerDiv'>
      <div className='datosConfigHeaderDiv'>
        <div>
          <div className='datosConfigGoBackDiv' onClick={props.handleGoBack}>
            <ion-icon name='chevron-back-outline' size={16} />
            <p className='datosConfigGoBackParagraph'>Volver</p>
          </div>

          <h1 className='datosConfigTitle'>Datos</h1>
          <p className='datosConfigParagraph'>
            Si necesitas cambiar algún dato tenés que comunicarte con soporte.
          </p>
        </div>
      </div>
      <div className='datosConfigCenterDiv'>
        <div className='datosConfigDivOne'>
          <div
            className='datosConfigDivOneContent datosConfigDivHoverStyle'
            onClick={handleAddProfilePhoto}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {/* {isHover ? */}
            {/* <img src={addPhotoIcon} alt='Icono agregar foto' className='datosConfigAddPhotoIcon' /> */}
            {/*  : */}
            {/* (props.data.profile_photo ?
                <img src={props.data.profile_photo} alt={`Logo de comercio ${props.commerceName}`} className="datosConfigImg datosConfigProfilePhoto" />
                : */}
            <ProfilePic name={logo} />
            {/* ) */}
            {/*  } */}
          </div>

          <div className='datosConfigDivTwo'>
            <p className='datosConfigDivTwoTitle'>{props.commerceName}</p>
            <p className='datosConfigDivTwoParagraph'>ID {props.data.id}</p>
            <p className='datosConfigDivTwoParagraph'>
              CUIT{' '}
              {commerceDocument != null ? commerceDocument.document_number : ''}
            </p>
          </div>
        </div>

        <div className='datosConfigDivExtraData'>
          <p className='datosConfigDivExtraDataText'>Correo electrónico: </p>
          <p className='datosConfigDivThreeParagraph'>{props.data.email}</p>
        </div>

        <div>
          <div className='datosConfigDivExtraData'>
            <p className='datosConfigDivExtraDataText'>Dirección: </p>
            <p className='datosConfigDivThreeParagraph'>
              {props.data.addresses.street} {props.data.addresses.label},{' '}
              {props.data.addresses.city}, {props.data.addresses.province}
            </p>
          </div>

          <div className='datosConfigDivExtraData'>
            <p className='datosConfigDivExtraDataText'>Teléfono: </p>
            <p className='datosConfigDivThreeParagraph'>{props.data.phone}</p>
          </div>
        </div>

        <div className='datosConfigQRMainDiv'>
          <h1 className='datosConfigTitle'>QR para propinas</h1>

          <div className='datosConfigQRSecondaryDiv'>
            <p className='datosConfigQRText'>
              Podes descargar un QR personalizado para tu comercio, y que los
              comensales puedan dejar la propina directamente desde sus
              celulares. Descargalo e imprimilo para poder comenzar a
              disfrutarlo.
            </p>

            <button
              type='button'
              className='datosConfigQRBtn'
              onClick={handleDownloadQR}
            >
              {loading ? (
                <CircularProgress color='inherit' size={14} />
              ) : (
                'Descargar QR'
              )}
            </button>
          </div>
        </div>
      </div>

      {/* AGREGAR IMAGEN MODAL */}
      {/* <AddCommerceImgModal visible={visible} /> */}

      <div className='datosConfigContactDiv'>
        <p className='datosConfigContactTitle'>¿Necesitas ayuda?</p>
        <div className='datosConfigContactIconDiv'>
          <img src={ContactIcon} className='datosConfigContactIcon' />
          <p className='datosConfigContactEmail'>
            soporte@tipieapp.com | 1122539457
          </p>
        </div>
      </div>

      <ErrorModal visible={visibleError} hideModal={hideModalError} />
    </div>
  );
}
