/* eslint-disable default-case */
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import './style.css';
import SectorConfigurator from './SectorConfigurator';
import ConfirmPasswordModal from '../../ConfirmPasswordModal';

export default function SectorPercentageModal(props) {
  const [display, setDisplay] = React.useState('none');
  const [disabled, setDisabled] = React.useState(false);

  // const [displayPassword, setDisplayPassword] = React.useState(false);

  const [updatedSectors, setUpdatedSectors] = React.useState([]);

  React.useEffect(() => {
    setUpdatedSectors(props.sectors);
  }, [props.sectors]);

  const handleDisplay = () => {
    setDisplay('none');
    setDisabled(true);
    props.hidePercentagesPasswordModal();
    props.hideModal();

    props.handleHideShowModify();
    const show = JSON.parse(localStorage.getItem('showModifyPercentages'));
    if (show) {
      window.location.reload();
    }
  };

  const handleDisplayTwo = () => {
    setDisplay('none');
    setDisabled(true);
    props.hidePercentagesPasswordModal();
    props.hideModal();
    props.handleHideShowModify();
    const show = JSON.parse(localStorage.getItem('showModifyPercentages'));
    if (show) {
      window.location.reload();
    }
    // que updatedsectors vuelva a ser props.sectors (sincambios)
  };

  const handleConfirmPassword = () => {
    props.showPercentagesPasswordModal();
  };

  const handleConfirmSector = () => {
    // props.handleSuccessSector(
    //   'editSectorName',
    //   props.sector,
    //   {
    //     id: props.sectorId,
    //   }
    // );

    props.handleSuccessSector(updatedSectors);

    handleDisplay();
  };

  const toggleDisabled = (value) =>
    value ? setDisabled(true) : setDisabled(false);

  // React.useEffect(() => {
  //   let sum = 0;
  //   updatedSectors.forEach(
  //     s => sum += parseInt(s.percentage)
  //   );

  //   if (sum == 100) {
  //     setDisabled(false);
  //   } else {
  //     setDisabled(true);
  //   }

  // }, [updatedSectors])

  React.useEffect(() => {
    if (props.visible) {
      setDisplay('block');
    }
  }, [props.visible]);

  return (
    <>
      <div
        style={{
          display,
          position: 'fixed',
          zIndex: 22,
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          overflow: 'auto',
          backgroundColor: 'rgb(0,0,0)',
          backgroundColor: 'rgba(0,0,0,0.4)',
        }}
        onClick={handleDisplayTwo}
      >
        <div
          className='SectorCutContent'
          onClick={(e) => {
            // do not close modal if anything inside modal content is clicked
            e.stopPropagation();
          }}
        >
          <button className='SectorCutCloseBtn' onClick={handleDisplayTwo}>
            <CloseIcon />
          </button>

          <p className='SectorCutBlob'>
            Configurá los porcentajes de propinas que van a recibir los
            colaboradores de cada sector.
          </p>

          <div className='SectorCutList'>
            {updatedSectors.map((s) => (
              <SectorConfigurator
                key={s.id}
                name={s.name}
                percentage={s.percentage}
                id={s.id}
                toggleDisabled={toggleDisabled}
                updatedSectors={updatedSectors}
                // onChange={(cut) => handleUpdate(s.id, cut)}
              />
            ))}
          </div>

          <button
            className='SectorCutBtn'
            style={{ justifySelf: 'flex-end', marginRight: 0 }}
            onClick={handleConfirmPassword}
            disabled={disabled}
          >
            Guardar cambios
          </button>
        </div>
      </div>

      <ConfirmPasswordModal
        btnLoading={props.btnLoading}
        visiblePassword={props.visiblePercentagePassword}
        onSuccess={handleConfirmSector}
      />
    </>
  );
}
