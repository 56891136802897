import mainPhoto from '../../../../assets/portada.jpg';
import itemImg from '../../../../assets/Grupo 22.png';
import './style.css';

export default function Hero() {
  return (
    <header
      className='heroHeader'
      style={{
        backgroundImage: `url(${mainPhoto})`,
      }}
    >
      <div className='heroTextDiv'>
        <h1>COBRÁ PROPINA </h1>
        <h1>¡CON TODAS LAS TARJETAS! </h1>
        <div className='subtitle'>
          <p>
            <img src={`${itemImg}`} alt='Check icon' />
            Distribución Automática De Propinas
          </p>
          <p>
            <img src={`${itemImg}`} alt='Check icon' />
            Acreditación Directa
          </p>
          <p>
            <img src={`${itemImg}`} alt='Check icon' />
            Sin Gastos Fijos Mensuales
          </p>
        </div>
      </div>
    </header>
  );
}
