import React from 'react';
import Loading from '../../components/Loading';
import ErrorHoc from '../../components/ErrorMessage';
import { AbstractService } from '../../services/abstractService';
import Home from './Home';
import Unauthorized from '../../hoc/unauthorized';

export default function HomeIndex() {
  const [configApiUsers, setConfigApiUsers] = React.useState();
  const [responseApiUsers, setResponseApiUsers] = React.useState('');

  const [configApiMerchant, setConfigApiMerchant] = React.useState();
  const [responseApiMerchant, setResponseApiMerchant] = React.useState('');

  const [configPendingEmployees, setConfigPendingEmployees] = React.useState();
  const [responsePendingEmployees, setResponsePendingEmployees] =
    React.useState('');

  const [loading, setLoading] = React.useState(true);
  const [hasError, setHasError] = React.useState(false);
  const [unauthorized, setUnauthorized] = React.useState(false);

  if (!configApiUsers && !configApiMerchant && !configPendingEmployees) {
    const user = localStorage.getItem('loggedUser');

    if (!user) {
      setUnauthorized(true);
      setHasError(false);
      setLoading(false);
    }

    setConfigApiUsers({
      url: `${process.env.REACT_APP_BFF_WEB_URL}/profile/commerce`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user}`,
        'Content-Type': 'application/json',
      },
    });

    setConfigApiMerchant({
      url: `${process.env.REACT_APP_BFF_WEB_URL}/commerce/active_shift`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user}`,
        'Content-Type': 'application/json',
      },
    });

    setConfigPendingEmployees({
      url: `${process.env.REACT_APP_BFF_WEB_URL}/employees/pending`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user}`,
        'Content-Type': 'application/json',
      },
    });
  }

  React.useEffect(() => {
    if (!(configApiUsers && configApiMerchant)) {
      // Incomplete configs
      return;
    }

    const promiseApiUsers = AbstractService(configApiUsers)
      .then((res) => ({ apiUsers: res }))
      .catch((err) => {
        setHasError(true);
      });

    const promiseApiMerchant = AbstractService(configApiMerchant)
      .then((res) => ({ apiMerchant: res }))
      .catch((err) => {
        setHasError(true);
      });

    const promisePendingEmployees = AbstractService(configPendingEmployees)
      .then((res) => ({ pendingEmployees: res }))
      .catch((err) => {
        setHasError(true);
      });

    Promise.all([promiseApiUsers, promiseApiMerchant, promisePendingEmployees])
      .then((responses) => {
        responses.forEach((res) => {
          if (res?.apiUsers) {
            setResponseApiUsers(res.apiUsers);
          } else if (res?.apiMerchant) {
            setResponseApiMerchant(res.apiMerchant);
          } else if (res?.pendingEmployees) {
            setResponsePendingEmployees(res.pendingEmployees);
          }
        });
        setLoading(false);
      })
      .catch((err) => {
        setHasError(true);
        setLoading(false);
      });
  }, [configApiUsers, configApiMerchant, configPendingEmployees]);

  if (unauthorized) {
    return <Unauthorized />;
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : hasError ? (
        <ErrorHoc views />
      ) : (
        <Home
          content={[
            {
              apiUsers: responseApiUsers,
              apiMerchant: responseApiMerchant,
              pendingEmployees: responsePendingEmployees,
            },
          ]}
        />
      )}
    </>
  );
}
