import './style.css';

export default function PrivacyBlob(props) {
  return (
    <article
      className='terms'
      style={{
        padding: props.standalone ? '25px 35px' : '0px',
      }}
    >
      <h1>Política de Privacidad</h1>

      <h2>1. INFORMACIÓN DEL SITIO</h2>
      <p>
        <strong>1.1.</strong> La presente Política de Privacidad (en adelante{' '}
        <strong>"Política de Privacidad"</strong>) se aplica a la utilización de
        los datos personales del Usuario (en adelante{' '}
        <strong>"Usted", "Usuario"</strong> o, en plural,{' '}
        <strong>"Usuarios"</strong>) onforme se describe a continuación, de la{' '}
        <strong>aplicación ("TIPIE")</strong> su página web, provistos por{' '}
        <strong>BOILCA S.A.,</strong> CUIT {/* TODO: complete */}, ya función
        principal consiste en facilitar al Usuario el procesamiento y
        distribución del pago de propinas, en comercios gastronómicos. En caso
        de ser necesario, <strong>BOILCA S.A.</strong> podrá complementar esta
        Política de Privacidad con información y/o términos y condiciones
        específicos con relación al servicio.
      </p>
      <p>
        <strong>1.2.</strong> El mero uso a la aplicación o acceso a su página
        web atribuye la condición de Usuario de <strong>TIPIE</strong> y expresa
        la aceptación plena y sin reservas de todas y cada una de las cláusulas
        de la Política de Privacidad en la versión publicada por{' '}
        <strong>TIPIE</strong> o <strong>BOILCA S.A.</strong> en el momento
        mismo en que el Usuario acceda a la aplicación, su página web o utilice
        su servicio. En consecuencia, la Política de Privacidad constituirá un
        acuerdo válido y obligatorio entre el Usuario y{' '}
        <strong>BOILCA S.A.</strong> con relación a la privacidad. Asimismo, la
        utilización de la aplicación, su página web y/o cualquier otro servicio
        relacionado expresa la aceptación plena y sin reservas del Usuario de
        los Términos y Condiciones de Utilización del Servicio (en adelante, los{' '}
        <strong>"Términos y Condiciones"</strong>) publicados por{' '}
        <strong>BOILCA S.A.</strong> en {/* TODO: complete */}, que se
        complementan con la presente Política de Privacidad.
      </p>
      <p>
        <strong>1.3.</strong> El objeto de la presente Política de Privacidad es
        poner en conocimiento a los titulares de los Datos Personales, conforme
        se define a continuación, respecto de los cuales se está recabando
        información, los tratamientos específicos de sus datos, las finalidades
        de los tratamientos, los datos de contacto para ejercer los derechos que
        le asisten, los plazos de conservación de la información y las medidas
        de seguridad entre otras cosas.
      </p>
      <p>
        <strong>1.4.</strong> Las disposiciones de la Política de Privacidad se
        aplicarán a todos los Usuarios de <strong>TIPIE</strong>, hayan estos
        ingresado sus Datos Personales o no.
      </p>
      <p>
        <strong>
          ANTES DE ACCEDER Y/O UTILIZAR LA APLICACIÓN, PAGINA WEB Y/O EL
          SERVICIO, POR FAVOR LEA ATENTAMENTE LA PRESENTE POLÍTICA DE
          PRIVACIDAD. SI EL USUARIO NO ESTÁ DE ACUERDO CON ESTA POLÍTICA DE
          PRIVACIDAD, DEBE ABSTENERSE DE ACCEDER Y/O UTILIZAR LA APLICACIÓN,
          PÁGINA WEB Y/O EL SERVICIO
        </strong>
      </p>

      <h2>2. RESPONSABLE DE TRATAMIENTODE SUS DATOS PERSONALES</h2>
      <p>
        <strong>2.1. BOILCA S.A.</strong> será la sociedad responsable del
        tratamiento de los Datos Personales que el Usuario facilite en la
        aplicación, su página web, o que se recopilen o procesen en los mismos,
        por, para o mediante los servicios, o en relación con ellos.
      </p>
      <p>
        En cualquier caso, si el titular de los Datos Personales accede o se
        registra en la aplicación o su página web, cualesquiera recopilación,
        uso e información compartida en relación con sus Datos Personales
        quedarán sujetos a esta Política de Privacidad, la Política de Cookies
        complementaria y sus actualizaciones.
      </p>
      <p>
        <strong>2.2.</strong> Para <strong>BOILCA S.A.</strong> la privacidad de
        los Datos Personales del Usuario es muy importante. En caso que el
        Usuario tenga alguna duda acerca de la Política de Privacidad, o sobre
        la utilización de la aplicación, su página web o el servicio, deberá
        ponerse en contacto con <strong>BOILCA S.A.</strong>, en cualquier
        momento, enviando un correo electrónico a {/* TODO: complete */}
      </p>

      <h2>3. RECOLECCIÓN DE INFORMACIÓN DE LOS USUARIOS</h2>
      <p>
        <strong>3.1. BOILCA S.A.</strong> trata los Datos Personales del Usuario
        únicamente con el consentimiento expreso que el Usuario le otorga con la
        aceptación de la presente Política de Privacidad. Asimismo el Usuario
        acepta que <strong>BOILCA S.A.</strong> pueda recolectar información
        suya utilizando cookies y tags, así como aquella información
        proporcionada por el Usuario a través de los formularios de la
        aplicación o su página web, al acceder, registrarse y/o al utilizar el
        servicio. En caso que un Usuario del Sitio no desee aceptar estas
        cookies, podrá configurar su navegador para que le otorgue la opción de
        aceptar cada cookie y rechazar las que no desee.
      </p>
      <p>
        <strong>3.2.</strong> Detallamos a continuación toda la información que{' '}
        <strong>BOILCA S.A.</strong> recolecta:
        <ul>
          <li>
            Información facilitada por el Usuario: <strong>i)</strong> nombre y
            apellido; <strong>ii)</strong> DNI; <strong>iii)</strong> CUIT/CUIL;{' '}
            <strong>iv)</strong> teléfono; <strong>v)</strong> correo
            electrónico; <strong>vi)</strong> imágenes de perfil;{' '}
            <strong>vii)</strong> {/* TODO: complete */} (en adelante, en su
            conjunto como los "Datos Personales"). No se almacenan datos ni
            información referente a las tarjetas utilizadas ni cuentas bancarias
            o de billeteras electrónicas, solo quedará constancia del medio de
            pago empleado, nombre, apellido, DNI y CUIT/CUIL del titular de la
            tarjeta o cuenta respectiva
          </li>
          <li>
            Información recopilada pasivamente: Cuando el Usuario accede a la
            aplicación, su página web y/o utiliza el servicio{' '}
            <strong>TIPIE</strong> podrá recopilar y almacenar de forma
            automática ciertos tipos de información, tales como la dirección del
            protocolo de Internet (IP) de la computadora o dispositivo del
            Usuario y otra información técnica sobre el uso de la computadora o
            dispositivo, como el tipo y versión del navegador, la configuración
            de la zona horaria, y el sistema operativo, ubicaciones geográficas,
            sesiones de uso, permanencia en el Sitio, frecuencia de uso,{' '}
            {/* TODO: complete */}.
          </li>
          <li>
            Información procedente de otras fuentes: Es posible que{' '}
            <strong>BOILCA S.A.</strong> reciba información del Usuario de otras
            fuentes tales como fuentes públicas, terceros (por ejemplo, agencias
            de informes del consumidor), {/* TODO: complete */}.
          </li>
          <li>
            Información proveniente de cookies, tags y/o cualquier otro método
            de detección de información automatizada provisto por las
            herramientas que ofrecen en la aplicación o su página web: La
            información que recopile <strong>BOILCA S.A.</strong> podrá incluir
            el comportamiento de navegación, dirección IP, logs, y otros tipos
            de información. Sin embargo, <strong>BOILCA S.A.</strong> no
            recolectará información personal identificable de manera directa de
            ningún Usuario usando cookies o tags o cualquier otro método de
            detección de información automatizada provisto por las herramientas
            que ofrece el Sitio.
          </li>
        </ul>
      </p>

      <h2>4. FINALIDAD DE TRATAMIENTO DE LOS DATOS PERSONALES</h2>
      <p>
        <strong>4.1. BOILCA S.A.</strong> trata la información personal del
        Usuario para las siguientes finalidades:
        <ul>
          <li>
            Autorizar, administrar y gestionar el acceso y/o uso de la
            aplicación, su página web y/o servicios, para verificar la identidad
            de los Usuarios (tanto de los usuarios creadores; usuarios
            registrados o usuarios no registrados), autenticar las transacciones
            que se efectúen y proporcionarles un servicio seguro, adaptable y
            adecuado a sus necesidades.
          </li>
          <li>
            Crear perfiles de los Usuarios registrados, a fin de poder efectuar
            la planificación y formas de distribución de las propinas
            percibidas, identificar al personal gastronómico y/u otros usuarios
            por cuestiones de seguridad y para poder garantizar un medio de
            contacto por cualquier eventualidad que pueda ocurrir en el uso del
            servicio.
          </li>
          <li>
            Crear un registro histórico de los pagos, transacciones y
            distribución de propinas que se efectúen por medio de la aplicación.
          </li>
          <li>
            Gestionar, analizar, desarrollar, personalizar y mejorar la
            aplicación, su página web y el servicio, como así también la
            interacción entre la aplicación y los distintos métodos de pago
            vinculados.
          </li>
          <li>Proveer el servicio y sus mejoras a los Usuarios.</li>
          <li>
            Mejorar la comunicación con los Usuarios y poder gestionar sus
            consultas, inquietudes y solicitudes efectuadas con atención
            personalizada.
          </li>
          <li>
            Analizar las conductas y comportamientos de los Usuarios en carácter
            de tales en la aplicación y su página web, a los efectos de intentar
            mejorar su Servicio e intentar proveerlos de mejores soluciones a
            sus necesidades.
          </li>
          <li>
            Obtener el diagnóstico de los eventuales problemas que puedan llegar
            a existir entre la aplicación y los Usuarios, mejorando la calidad
            de los servicios.
          </li>
          <li>
            Enviar a sus Usuarios, notificaciones, noticias y novedades de su
            interés, además de aquellas que revistan el carácter de
            notificaciones de índole institucional o legal.
          </li>
          <li>
            Facilitar el cumplimiento de obligaciones legales en caso de ser
            solicitadas por tribunales, u organismos estatales nacionales o
            internacionales que así lo requieran y lo soliciten en la forma
            correspondiente.
          </li>
        </ul>
      </p>

      <h2>5. USO DE LA INFORMACIÓN DE LOS USUARIOS RECOLECTADA</h2>
      <p>
        <strong>5.1. BOILCA S.A.</strong> utilizará y almacenará la información
        provista por los Usuarios y la recolectada con el fin de proveer el
        servicio y sus mejoras a los Usuarios, intentando ajustarse a sus
        necesidades, por el plazo máximo que establezca la legislación
        aplicable.
      </p>
      <p>
        <strong>5.2. BOILCA S.A.</strong> conservará y utilizará los Datos
        Personales otorgados por el Usuario durante el período en que este
        último utilice la aplicación, su página web y/o utilice el servicio con
        la finalidad de prestar el mejor servicio. Una vez finalizada la
        relación entre las Partes, por el motivo que fuere,{' '}
        <strong>BOILCA S.A.</strong> se reserva el derecho de conservar la
        información hasta finalizar la finalidad de su tratamiento.
        Posteriormente al plazo mencionado, <strong>BOILCA S.A.</strong>{' '}
        procederá a la destrucción de los Datos Personales.
      </p>
      <p>
        <strong>5.3. BOILCA S.A.</strong>, podrá enviar a sus Usuarios,
        notificaciones, noticias y novedades de su interés, además de aquellas
        que revistan el carácter de notificaciones de índole institucional o
        legal a los fines de ofrecer sus servicios, ello, siempre y cuando sea
        aceptado por el Usuario y aquel no haya ejercido su derecho de opt-out.
      </p>
      <p>
        <strong>5.4. BOILCA S.A.</strong> no comparte, ni divulga la información
        almacenada con otras empresas de servicios o sitios de internet o
        similares.
      </p>
      <p>
        <strong>5.5. BOILCA S.A.</strong> no venderá, alquilará ni negociará
        ningún Dato Personal a ningún tercero para fines comerciales.
      </p>
      <p>
        <strong>5.6.</strong> Cualquier persona que hubiera provisto información
        de contacto personal a través de la aplicación <strong>TIPIE</strong> o
        su página web, podrá enviar un correo electrónico a{' '}
        {/* TODO: complete */} a fin de actualizar, borrar y/o corregir su
        información personal de contacto. <strong>BOILCA S.A.</strong>{' '}
        responderá dicho requerimiento dentro de los 10 (diez) días hábiles
        siguientes a la recepción del mismo vía correo electrónico.
      </p>
      <p>
        <strong>5.7.</strong> La aplicación <strong>TIPIE</strong> o su página
        web, podrán contener enlaces a otros sitios de internet que no sean
        propiedad de <strong>BOILCA S.A..</strong> En consecuencia,{' '}
        <strong>BOILCA S.A.</strong> no será responsable por el actuar de dichos
        sitios de internet, a los cuales no se aplicará la presente Política de
        Privacidad. Recomendamos examinar la política de privacidad detallada en
        aquellos sitios de internet para entender los procedimientos de
        recolección de información que utilizan y como protegen sus datos
        personales.
      </p>
      <p>
        <strong>5.8.</strong> En lo que respecta al tratamiento de datos
        personales de ciudadanos de la Unión Europea,{' '}
        <strong>BOILCA S.A.</strong> estará a los principios y obligaciones
        recogidas en el Reglamento UE 2016/679, del Parlamento Europeo y del
        Consejo, de 27 de abril de 2016, relativo a la protección de las
        personas físicas en lo que respecta al tratamiento de datos personales y
        a la libre circulación de estos datos (RGPD).
      </p>

      <h2>6. CESIÓN Y TRANSFERENCIA DE LOS DATOS PERSONALES</h2>
      <p>
        <strong>6.1.</strong> Únicamente para cumplir con los fines establecidos
        en la presente Política de Privacidad y a efectos de prestar el
        servicio, <strong>BOILCA S.A.</strong> podrá revelar los Datos
        Personales y/o información personal del Usuario o Usuarios, cuando sea
        necesario, a las autoridades, los socios de <strong>BOILCA S.A.</strong>{' '}
        y otros terceros, como terceros proveedores de servicios utilizados en
        relación con el servicio, por ejemplo para realizar servicios de
        marketing, publicidad, comunicación, infraestructuras y servicios de TI,
        para personalizar y mejorar nuestro servicio, para procesar las
        transacciones con tarjetas de crédito u otros métodos de pago. Cuando{' '}
        <strong>BOILCA S.A.</strong> contratare a un proveedor de servicios le
        proporcionará la información que necesitará para realizar su función
        específica, que puede incluir Datos Personales y otra información que el
        Usuario proporcione a través de la aplicación o su página web. Estos
        proveedores de servicio están autorizados a utilizar los Datos
        Personales sólo cuando sea necesario para proporcionar sus servicios.
      </p>

      <h2>7. CONFIDENCIALIDAD Y SEGURIDAD DE LA INFORMACIÓN</h2>
      <p>
        <strong>7.1. BOILCA S.A.</strong> ha adoptado medidas de seguridad
        razonables para proteger la información de los Usuarios e impedir el
        acceso no autorizado a sus datos o cualquier modificación, divulgación o
        destrucción no autorizada de los mismos. La información recolectada por{' '}
        <strong>BOILCA S.A.</strong>, será mantenida de manera estrictamente
        confidencial. El acceso a los datos personales está restringido a
        aquellos empleados, contratistas, operadores, y representantes de{' '}
        <strong>BOILCA S.A.</strong> que necesitan conocer tales datos para
        desempeñar sus funciones y desarrollar o mejorar nuestro servicio.{' '}
        <strong>BOILCA S.A.</strong> exige a sus proveedores los mismos
        estándares de confidencialidad. <strong>BOILCA S.A.</strong> no permite
        el acceso a esta información a terceros ajenos a{' '}
        <strong>BOILCA S.A.</strong>, a excepción de un pedido expreso del
        Usuario.
      </p>
      <p>
        <strong>7.2.</strong> Sin perjuicio de lo expuesto, considerando que
        internet es un sistema abierto, de acceso público,{' '}
        <strong>BOILCA S.A.</strong> no puede garantizar que terceros no
        autorizados no puedan eventualmente superar las medidas de seguridad y
        utilizar la información de los Usuarios en forma indebida. En todo caso,{' '}
        <strong>BOILCA S.A.</strong> mantiene planes de seguridad y de respuesta
        a incidentes para controlar incidentes relacionados con el acceso no
        autorizado a la información privada que recopila o almacena.
      </p>

      <h2>8. CAMBIOS EN LA ESTRUCTURA CORPORATIVA</h2>
      <p>
        <strong>8.1. BOILCA S.A.</strong> se reserva el derecho de transferir la
        información recolectada en caso de venta <strong>TIPIE</strong>, o de
        una fusión o adquisición de los activos principales de{' '}
        <strong>BOILCA S.A.</strong>, o cualquier otra clase de transferencia de{' '}
        <strong>TIPIE O BOILCA S.A.</strong> a otra entidad. En dicho supuesto,{' '}
        <strong>BOILCA S.A.</strong> deberá adoptar las medidas razonables a
        efectos de asegurar que dicha información sea utilizada de una manera
        consistente con la Política de Privacidad.
      </p>

      <h2>9. MENORES DE EDAD</h2>
      <p>
        <strong>9.1.</strong> Atento a que <strong>TIPIE</strong> es una
        aplicación pública y abierta para personas mayores de 13 años, sólo
        podrán utilizar la aplicación y el servicio los mayores de la edad
        indicada. En caso en que algún menor de 13 años tenga acceso a los
        mismos, su uso deberá ser supervisado por los padres, madres, tutores o
        responsables legales.
      </p>
      <p>
        <strong>9.2.</strong> No podrán utilizar los servicios de{' '}
        <strong>TIPIE</strong> las personas que no tengan capacidad legal para
        contratar o que no cuenten con capacidad para efectuar ese acto,
        conforme a las normas y principios de autonomía progresiva; quienes
        hayan sido suspendidos o inhabilitados por <strong>BOILCA S.A.</strong>{' '}
        para utilizar el servicio y/o quienes se encuentren inhibidos legalmente
        o de algún modo vedados de ejercer actos jurídicos, derechos y/u
        obligaciones. Habida cuenta de ello, los menores de 13 años no tienen
        permitido el ingreso a la aplicación, su página web y/o servicio, así
        tampoco podrán suministrar ningún Dato Personal, ni ningún otro tipo de
        información.
      </p>

      <h2>10. DERECHOS DE LOS USUARIOS SOBRE LA INFORMACIÓN</h2>
      <p>
        <strong>10.1. BOILCA S.A.</strong> tratará, por todos los medios a su
        alcance, de facilitar a los Usuarios sobre los cuales haya recopilado o
        almacenado información personal, el acceso a sus Datos Personales (
        <strong>"Derecho de Acceso"</strong>), así como la rectificación,
        modificación o actualización de los mismos (
        <strong>"Derecho de Rectificación"</strong>), o incluso la cancelación
        de dichos datos personales ("Derecho de Remoción"), a menos que{' '}
        <strong>BOILCA S.A.</strong> pueda denegar dichas solicitudes (en
        adelante, las <strong>"Solicitudes"</strong>), en caso que se encuentre
        obligada o tenga derecho a conservar dichos Datos de acuerdo a la
        legislación aplicable
      </p>
      <p>
        <strong>a)</strong> A dichos efectos, el Usuario deberá enviar su
        Solicitud mediante el envío de un correo electrónico con el asunto{' '}
        <strong>
          <em>"Acceso a Datos Personales"</em>
        </strong>{' '}
        a {/* TODO: complete */} <strong>BOILCA S.A.</strong> podrá requerir a
        dicho Usuario que se identifique, lo que podrá ser verificado por{' '}
        <strong>BOILCA S.A.</strong>, así como que precise los Datos Personales
        a los cuales se desea acceder, rectificar o remover.
      </p>
      <p>
        <strong>b) BOILCA S.A.</strong> podrá rechazar la tramitación de
        Solicitudes que sean irrazonablemente repetitivas o sistemáticas, que
        requieran un esfuerzo técnico desproporcionado, que pongan en peligro la
        privacidad de los demás Usuarios, o que se consideren poco prácticas, o
        para las que no sea necesario acceder a los Datos Personales.
      </p>
      <p>
        <strong>c)</strong> El servicio de acceso, rectificación y remoción de
        Datos Personales será prestado por <strong>BOILCA S.A.</strong> en forma
        gratuita, excepto en caso que requiriera un esfuerzo desproporcionado o
        irrazonable, en cuyo caso podrá cobrarse un cargo de administración.
      </p>
      <p>
        <strong>10.2.</strong> A fin de ejercer sus derechos, los Usuarios
        podrán enviar una solicitud a la casilla de correo{' '}
        {/* TODO: complete */}
      </p>
      <p>
        <strong>10.3.</strong> ASIMISMO, Y ANTE CUALQUIER EVENTUALIDAD, SE
        INFORMA AL USUARIO QUE COMO TITULAR DE SUS DATOS PERSONALES TIENE LA
        FACULTAD DE EJERCER EL DERECHO DE ACCESO A LOS MISMOS EN FORMA GRATUITA
        A INTERVALOS NO INFERIORES A SEIS MESES, SALVO QUE SE ACREDITE UN
        INTERÉS LEGÍTIMO AL EFECTO CONFORME LO ESTABLECIDO EN EL ARTÍCULO 14,
        INCISO 3 DE LA LEY Nº 25.326 DE LA REPUBLICA ARGENTINA. LA AUTORIDAD DE
        APLICACIÓN (EN ARGENTINA, LA AGENCIA DE ACCESO A LA INFORMACIÓN PÚBLICA,
        ÓRGANO DE CONTROL DE LA LEY Nº 25.326), TIENE LA ATRIBUCIÓN DE ATENDER
        LAS DENUNCIAS Y RECLAMOS QUE SE INTERPONGAN CON RELACIÓN AL
        INCUMPLIMIENTO DE LAS NORMAS SOBRE PROTECCIÓN DE DATOS PERSONALES.
      </p>
      <p>
        Para contactar a la{' '}
        <strong>Agencia de Acceso a la Información Pública</strong>: ubicada en
        Av. Pte. Julio A. Roca 710, Piso 2º, Ciudad de Buenos Aires; enlace:{' '}
        <a href='https://www.argentina.gob.ar/aaip'>
          www.argentina.gob.ar/aaip
        </a>
        ; mail:{' '}
        <a href='mailto:datospersonales@aaip.gob.ar'>
          datospersonales@aaip.gob.ar
        </a>
        ; Tel. +5411-2821-0047.
      </p>
      <p>
        Si lo considera oportuno, el Usuario también puede presentar un reclamo
        ante la autoridad de control correspondiente.
      </p>

      <h2>11. EXCEPCIONES</h2>
      <p>
        <strong>11.1.</strong> No obstante cualquier otra provisión en contrario
        en la Política de Privacidad, <strong>BOILCA S.A.</strong> podrá
        divulgar cierta información personal de los Usuarios, cuando crea de
        buena fe que esa divulgación resulte razonablemente necesaria para:
        <ul>
          <li>Evitar una responsabilidad legal.</li>
          <li>
            Cumplir una exigencia legal, tal como una orden de allanamiento, una
            citación o una orden judicial.
          </li>
          <li>
            Cumplir un requerimiento de una autoridad gubernamental o
            reguladora; y/o Proteger los derechos, propiedad o seguridad de{' '}
            <strong>BOILCA S.A.</strong>, de los Usuarios, o de un tercero.
          </li>
        </ul>
      </p>

      <h2>12. LÍNEA ETICA/ LÍNEA DE DENUNCIAS</h2>
      <p>
        <strong>12.1. BOILCA S.A.</strong> ofrece al Usuario un canal de
        comunicación para dar a conocer en forma anónima, confidencial y segura
        la existencia de irregularidades éticas por parte de cualquier
        integrante perteneciente a <strong>BOILCA S.A.</strong> (en adelante, el{' '}
        <strong>"Canal de Comunicación"</strong>. <strong>BOILCA S.A.</strong>{' '}
        realiza todas las medidas necesarias a los fines de que dichas
        irregularidades no ocurran, y en razón de ello pone a disposición el
        Canal de Comunicación posteriormente detallado a los fines de que el
        Usuario colabore con dicho fin. Para mayor detalle, destacamos a
        continuación algunos ejemplos de irregularidades de forma enunciativa, a
        los fines de que el Usuario se mantenga informado:
        <ul>
          <li>Fraudes económico-financieros.</li>
          <li>Entrega de información confidencial de la compañía.</li>
          <li>Conflicto de intereses.</li>
          <li>
            Descuido o utilización inapropiada de los bienes de la organización.
          </li>
          <li>Uso inapropiado de nombre, logo y marcas de la empresa.</li>
          <li>Acoso laboral o malos tratos.</li>
        </ul>
        En el supuesto en que el Usuario contemple una irregularidad de las
        mencionadas, no debe dudar en reportarla. Su reporte será tratado en
        forma rápida, confidencial y profesional.
      </p>
      <p>
        <strong>12.2.</strong> Dicho servicio será brindado por un tercero a los
        fines de obtener la mayor precisión y objetividad posible. Ello
        significa que quien tratará los datos personales y/o información
        brindada por el Usuario una vez que realice una denuncia a través de los
        Canales de Comunicación detallados posteriormente, serán dichos terceros
        por cuenta y orden de <strong>BOILCA S.A.</strong> El Usuario entiende y
        acepta que para los fines de utilizar el Canal de Comunicación, quienes
        tratarán los datos serán dichos terceros, solo a los efectos de realizar
        las denuncias y promover un clima ético y de compliance.{' '}
        <strong>BOILCA S.A.</strong> tratará la presente información denunciada
        y la remitirá a un Comité, el cual analizará las implicancias de la
        información y las medidas a tomar.
      </p>
      <p>
        <strong>12.3.</strong> En razón de lo expuesto, BOILCA S.A. pondrá a
        disposición de los Usuarios los siguientes Canales de Comunicación:
        <br />
        Página web: {/* TODO: complete */}
        <br />
        E-mail: {/* TODO: complete */}
      </p>

      <h2>13. MODIFICACIÓN DE LA POLÍTICA DE PRIVACIDAD</h2>
      <p>
        <strong>13.1.</strong> El Usuario se compromete a leer atenta y
        comprensivamente la presente Política de Privacidad cada vez que acceda
        a la página web o utilice la aplicación, ya que los mismos pueden sufrir
        modificaciones. <strong>BOILCA S.A.</strong> se reserva el derecho de
        ampliar y modificar la aplicación y el servicio a efectos de mejorar la
        calidad del mismo, y en consecuencia se reserva el derecho de modificar,
        la Política de Privacidad en cualquier momento.
      </p>
      <p>
        <strong>13.2.</strong> Las nuevas versiones de la Política de Privacidad
        serán notificadas mediante publicación de dicha nueva versión en{' '}
        {/* TODO: complete */} y notificada por las vías de contacto que el
        Usuario declare en su caso.
      </p>
      <p>
        <strong>13.3.</strong> El Usuario acepta que será dado por notificado de
        cualquier modificación a la Política de Privacidad una vez que{' '}
        <strong>BOILCA S.A.</strong> hubiera publicado las mismas en{' '}
        {/* TODO: complete */}, y enviado la notificación por las vías de
        contacto declaradas por el Usuario. La aprobación de dicha nueva
        versión, y la continuación del Usuario en el uso de la aplicación, su
        página web y el servicio una vez publicada la misma dicha nueva versión
        se considerará como aceptación de dichas modificaciones a la Política de
        Privacidad. Sin perjuicio de lo mencionado, el Usuario acepta chequear{' '}
        {/* TODO: complete */} periódicamente.
      </p>

      <h2>14. CONTACTO</h2>
      <p>
        <strong>14.1.</strong> En caso que el Usuario tenga alguna duda acerca
        de la Política de Privacidad, o sobre la aplicación de la misma, deberá
        ponerse en contacto con <strong>BOILCA S.A.</strong>, en cualquier
        momento, vía correo electrónico a {/* TODO: complete */}
      </p>

      <h2>15. LEGISLACION Y JURISDICCION APLICABLE</h2>
      <p>
        <strong>15.1.</strong> La presente Política de Privacidad se regirá e
        interpretará con arreglo a la legislación vigente en la República
        Argentina, debiendo cualquier divergencia relacionadas a los presentes
        resolverse ante los tribunales ordinarios de la Ciudad Autónoma de
        Buenos Aires.
      </p>
    </article>
  );
}
