// import Spinner from '../../assets/logo2.png';
import CircularProgress from '@mui/material/CircularProgress';
import './style.css';

export default function Loading(props) {
  let styleOverrides = {};
  if (props.overrides) {
    styleOverrides = props.overrides;
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        ...styleOverrides,
        color: '#3C112B',
      }}
    >
      {/* <img src={Spinner}
                 alt='Cargando...'
                 className='spinnerLogo' /> */}
      <CircularProgress color='inherit' />
    </div>
  );
}
