/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import { styled } from '@material-ui/core/styles';
import CloseIcon from '@mui/icons-material/Close';
import Terms from '../../../../components/Terms';
import Privacy from '../../../../components/Privacy';
import Logo from '../../../../assets/tipie-vectores-01.png';
import { AbstractService } from '../../../../services/abstractService';

const provincesArray = [
  {
    label: 'Buenos Aires',
    value: 'Buenos Aires',
  },
  {
    label: 'Catamarca',
    value: 'Catamarca',
  },
  {
    label: 'Chaco',
    value: 'Chaco',
  },
  {
    label: 'Chubut',
    value: 'Chubut',
  },
  {
    label: 'CABA',
    value: 'CABA',
  },
  {
    label: 'Córdoba',
    value: 'Córdoba',
  },
  {
    label: 'Corrientes',
    value: 'Corrientes',
  },
  {
    label: 'Entre Ríos',
    value: 'Entre Ríos',
  },
  {
    label: 'Formosa',
    value: 'Formosa',
  },
  {
    label: 'Jujuy',
    value: 'Jujuy',
  },
  {
    label: 'La Pampa',
    value: 'La Pampa',
  },
  {
    label: 'La Rioja',
    value: 'La Rioja',
  },
  {
    label: 'Mendoza',
    value: 'Mendoza',
  },
  {
    label: 'Misiones',
    value: 'Misiones',
  },
  {
    label: 'Neuquén',
    value: 'Neuquén',
  },
  {
    label: 'Río Negro',
    value: 'Río Negro',
  },
  {
    label: 'Salta',
    value: 'Salta',
  },
  {
    label: 'San Juan',
    value: 'San Juan',
  },
  {
    label: 'San Luis',
    value: 'San Luis',
  },
  {
    label: 'Santa Cruz',
    value: 'Santa Cruz',
  },
  {
    label: 'Santa Fe',
    value: 'Santa Fe',
  },
  {
    label: 'Santiago del Estero',
    value: 'Santiago del Estero',
  },
  {
    label: 'Tierra del Fuego',
    value: 'Tierra del Fuego',
  },
  {
    label: 'Tucumán',
    value: 'Tucumán',
  },
];

const CustomField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#E98A15',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#E98A15',
  },
});

function TextInput(props) {
  const value = props.values[props.name];
  const onChange = (e) => {
    props.set(props.name, e.target.value);
  };
  return (
    <div className={props.className}>
      <CustomField
        label={props.label}
        variant='standard'
        onChange={onChange}
        value={value}
        select={props.select}
        inputProps={{
          className: props.className,
        }}
        fullWidth
        required
      >
        {props.children}
      </CustomField>
    </div>
  );
}

export default function RegisterForm(props) {
  const [showTos, setShowTos] = React.useState(false);
  const [showPrivacy, setShowPrivacy] = React.useState(false);
  const [invisible, setInvisible] = React.useState(true);
  const toggleTos = (e) => {
    if (e) {
      e.preventDefault();
    }
    setShowTos(!showTos);
  };
  const togglePrivacy = (e) => {
    if (e) {
      e.preventDefault();
    }
    setShowPrivacy(!showPrivacy);
  };

  const [formValues, setFormValues] = React.useState({
    personName: '',
    commerceName: '',
    province: '',
    city: '',
    postalCode: '',
    email: '',
    phone: '',
  });

  const [isEnabled, setIsEnabled] = React.useState(false);
  const [mustSubmit, setMustSubmit] = React.useState(false);

  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);

  const toggleErrorModal = () => {
    setShowErrorModal(false);
    props.onClose();
  };

  const toggleSuccessModal = () => {
    setShowSuccessModal(false);
    props.onClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formValues.email.includes('@')) {
      setInvisible(false);
      return;
    }

    const contactFormData = {
      url: `${process.env.REACT_APP_BFF_WEB_URL}/notifications/contact`,
      method: 'POST',
      data: formValues,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      setIsEnabled(false);
      await AbstractService(contactFormData);
      setShowSuccessModal(true);
    } catch (error) {
      setIsEnabled(true);
    }
  };

  const onSubmit = () => {
    if (props.onSubmit) {
      props.onSubmit({
        ...formValues,
      });
    }
    if (props.displayModalError) {
      setShowErrorModal(true);
    }

    setMustSubmit(false);
    handleSubmit();
  };

  const handleValue = (name, value) => {
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleMailFocus = () => {
    setInvisible(true);
  };

  React.useEffect(() => {
    if (!mustSubmit) {
      return;
    }
    onSubmit(formValues);
  }, [mustSubmit, onSubmit, formValues]);

  return (
    <div className='registerModal'>
      <h1>Completá el formulario para que podamos contactarte</h1>
      <form onSubmit={handleSubmit}>
        <div className='formFlexBox'>
          <div className='formFlexBoxOne'>
            <TextInput
              label='Nombre y apellido'
              name='personName'
              values={formValues}
              set={handleValue}
              className='formLarge'
            />
            <TextInput
              label='Nombre del comercio'
              name='commerceName'
              values={formValues}
              set={handleValue}
              className='formLarge'
            />
          </div>
          {/* // */}
          <div className='formFlexBoxOne'>
            <TextInput
              label='Provincia'
              name='province'
              values={formValues}
              set={handleValue}
              select
              className='formMedium'
            >
              {provincesArray.map((option) => {
                return (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                );
              })}
            </TextInput>
            <TextInput
              label='Localidad'
              name='city'
              values={formValues}
              set={handleValue}
              className='formLarge'
            />
            <TextInput
              label='Código postal'
              name='postalCode'
              values={formValues}
              set={handleValue}
              className='formSmall'
            />
          </div>
          {/* // */}
          <div className='formFlexBoxOne'>
            <TextInput
              label='Celular'
              name='phone'
              values={formValues}
              set={handleValue}
              className='formLarge'
            />
            <TextInput
              label='Mail'
              name='email'
              values={formValues}
              set={handleValue}
              className='formLarge'
              onFocus={handleMailFocus}
            />
            <div
              style={{
                opacity: invisible ? 0 : 1,
              }}
            >
              <p className='registerFormIncorrectEmail'>
                El formato de mail es incorrecto
              </p>
            </div>
          </div>
        </div>
        <label>
          <Checkbox
            style={{
              color: '#E98A15',
            }}
            checked={isEnabled}
            onChange={() => setIsEnabled(!isEnabled)}
          />
          Acepto los{' '}
          <a className='tosTrigger' onClick={toggleTos}>
            Términos y Condiciones
          </a>{' '}
          y autorizo el uso de mis datos de acuerdo a la{' '}
          <a className='tosTrigger' onClick={togglePrivacy}>
            Declaración de Privacidad
          </a>
        </label>
        <button
          className='navButtonPrimary formButton'
          disabled={!isEnabled}
          type='submit'
        >
          Enviar formulario
        </button>
      </form>
      <Dialog open={showTos} onClose={toggleTos}>
        <Terms standalone />
      </Dialog>
      <Dialog open={showPrivacy} onClose={togglePrivacy}>
        <Privacy standalone />
      </Dialog>
      <Dialog open={showErrorModal} onClose={toggleErrorModal}>
        <div className='modalErrorDiv'>
          <CloseIcon className='modalErrorIcon' onClick={toggleErrorModal} />
          <h3 className='modalErrorTitle'>¡Ops! Error</h3>
          <h5 className='modalErrorText'>Surgió un error inesperado.</h5>
          <h5 className='modalErrorText'>
            Por favor volver a intentarlo en unos minutos.
          </h5>
          <img src={Logo} alt='Tipie Logo' className='modalErrorImg' />
        </div>
      </Dialog>
      <Dialog
        open={showSuccessModal}
        onClose={toggleSuccessModal}
        className='dialog'
      >
        <div className='modalErrorDiv'>
          <h3 className='modalErrorTitle'>¡Formulario enviado!</h3>
          <h5 className='modalErrorText'>Nos pondremos en contacto con vos.</h5>
          <h5 className='modalErrorText'>¡Muchas gracias!</h5>
          <img src={Logo} alt='Tipie Logo' className='modalErrorImg' />
        </div>
      </Dialog>
    </div>
  );
}
