import React from 'react';
import TopNavBar from '../../components/TopNavBar';
import SideBar from '../../components/Sidebar';
import UnauthorizedModal from '../../components/Modal/Unauthorized';

import './style.css';

export default function Unauthorized() {
  return (
    <div className='mainDiv'>
      <TopNavBar />
      <SideBar />

      <UnauthorizedModal />
    </div>
  );
}
