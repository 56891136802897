import React from 'react';
import TopNavBar from '../../components/TopNavBar';
import SideBar from '../../components/Sidebar';
import FourOFourModal from '../../components/Modal/FourOFourModal';

export default function FourOFour() {
  return (
    <div className='mainDiv'>
      <TopNavBar />
      <SideBar />

      <FourOFourModal />
    </div>
  );
}
