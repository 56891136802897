// DEPRECADO: los cambios nuevos corren en /views/Configuracion/Turnos y /views/Configuracion/Sectores

import React from 'react';
import { Grid } from '@mui/material';
import AddSectorTurnoModal from '../../Modal/ConfiguracionModal/AddSectorShift';
import ConfirmSectorTurnoModal from '../../Modal/ConfiguracionModal/AddSectorShift/ConfirmSectorShift';
import DeleteSectorTurnoModal from '../../Modal/ConfiguracionModal/DeleteSectorTurno';
import SectoresModal from '../../Modal/ConfiguracionModal/Sectores';
import SectorPercentagesModal from '../../Modal/ConfiguracionModal/SectorPercentages';
import TurnosModal from '../../Modal/ConfiguracionModal/Turnos';
import SuccessModal from '../../Modal/SuccessModal';
import FinishModal from '../../Modal/FinishModal';

import './style.css';
import Loading from '../../Loading';

import { AbstractService } from '../../../services/abstractService';
import ErrorPercentageModal from '../../Modal/ConfiguracionModal/Sectores/ErrorPercentage';
import PreconfigShiftSectorModal from '../../Modal/ConfiguracionModal/preconfigShiftsSectors';

export default function TurnosYSectores(props) {
  const [visible, setVisible] = React.useState(false);
  const [visibleSectores, setVisibleSectores] = React.useState(false);
  const [visiblePercentages, setVisiblePercentages] = React.useState(false);
  const [visibleSuccess, setVisibleSuccess] = React.useState(false);
  const [visibleFinish, setVisibleFinish] = React.useState(false);

  const [visibleDelete, setVisibleDelete] = React.useState(false);
  const [visibleAdd, setVisibleAdd] = React.useState(false);
  const [visibleConfirmAdd, setVisibleConfirmAdd] = React.useState(false);
  const [visiblePassword, setVisiblePassword] = React.useState(false);
  const [visiblePercentagePassword, setVisiblePercentagePassword] =
    React.useState(false);

  const [successText, setsuccessText] = React.useState(false);

  const [shift, setShift] = React.useState('');
  const [shiftId, setShiftId] = React.useState('');

  const [fromHour, setFromHour] = React.useState('');
  const [toHour, setToHour] = React.useState('');

  const [designatedSector, setDesignatedSector] = React.useState('');
  const [designatedSectorId, setDesignatedSectorId] = React.useState('');
  const [designatedSectorPercentage, setDesignatedSectorPercentage] =
    React.useState('');

  const [modalTitle, setModalTitle] = React.useState('');
  const [modalBtn, setModalBtn] = React.useState('');

  const [modalFinishTitle, setModalFinishTitle] = React.useState('');
  const [modalFinishBtn, setModalFinishBtn] = React.useState('');

  const [shiftToDelete, setShiftToDelete] = React.useState('');
  const [shiftIdToDelete, setShiftIdToDelete] = React.useState('');

  const [sectorToDelete, setSectorToDelete] = React.useState('');
  const [sectorIdToDelete, setSectorIdToDelete] = React.useState('');

  const [addShift, setAddShift] = React.useState(false);

  const [newShiftInfo, setNewShiftInfo] = React.useState({});
  const [newSectorInfo, setNewSectorInfo] = React.useState({});

  const [enableSave, setEnableSave] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [hasError, setHasError] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);
  const [btnLoading, setBtnLoading] = React.useState(false);

  const [createdSector, setCreatedSector] = React.useState(false);

  const [disabledVisiblePercentages, setDisabledVisiblePercentages] =
    React.useState(false);

  const [newCommerce, setNewCommerce] = React.useState(false);
  const [displayPreconf, setDisplayPreconf] = React.useState('none');

  const [newPercentages, setNewPercentages] = React.useState([]);

  const showModal = (shiftName, shiftId, shiftFromHour, shiftToHour) => {
    setVisible(true);
    setShift(shiftName);
    setShiftId(shiftId);
    setFromHour(shiftFromHour);
    setToHour(shiftToHour);
  };

  const showModalSectores = (sector, sectorId, sectorPercentage) => {
    setVisibleSectores(true);
    setDesignatedSector(sector);
    setDesignatedSectorId(sectorId);
    setDesignatedSectorPercentage(sectorPercentage);
  };

  const showFinishModal = () => {
    setVisibleFinish(true);
  };

  const hideFinishModal = () => {
    setVisibleFinish(false);
  };

  const showPercentagesPasswordModal = () => {
    setVisiblePercentagePassword(true);
  };

  const hidePercentagesPasswordModal = () => {
    setVisiblePercentagePassword(false);
  };

  const showEditPercentages = () => {
    if (props.array.length <= 1) {
      setDisabledVisiblePercentages(true);
    } else {
      setVisiblePercentages(true);
    }
  };

  const hideEditPercentages = () => {
    setVisiblePercentages(false);
  };

  const hideSuccess = () => {
    setVisibleSuccess(false);
  };

  React.useEffect(() => {
    if (visible) {
      setVisible(false);
    } else if (visibleSectores) {
      setVisibleSectores(false);
    } else if (visibleSuccess) {
      setVisibleSuccess(false);
    } else if (visibleDelete) {
      setVisibleDelete(false);
    } else if (visibleAdd) {
      setVisibleAdd(false);
    } else if (visibleConfirmAdd) {
      setVisibleConfirmAdd(false);
    } else if (visibleFinish) {
      setVisibleFinish(false);
    }
  }, [
    visible,
    visibleSectores,
    visibleFinish,
    visibleSuccess,
    visibleDelete,
    visibleAdd,
    visibleConfirmAdd,
  ]);

  const handleSuccess = (value, arg, changes) => {
    switch (value) {
      case 'editShift':
        setVisibleSectores(false);
        setModalTitle('Cambio realizado con éxito');
        setModalBtn('Volver');
        setVisibleSuccess(true);
        setCreatedSector(false);
        setVisiblePercentages(false);
        break;
      case 'editSector':
        setVisible(false);
        props.handleChange('editSector', changes);
        setModalTitle(`Cambiaste el porcentaje de propina para ${arg}`);

        let sumPercentages = 0;
        props.array.forEach((sector) => {
          sumPercentages += parseInt(sector.percentage);
        });
        if (sumPercentages != 100) {
          setErrorMessage(
            `El total del porcentaje elegido tiene que ser igual a 100% (actualmente: ${sumPercentages})`,
          );
          setEnableSave(false);
        } else {
          setEnableSave(true);
          setErrorMessage('');
        }

        setModalBtn('Aceptar');
        setVisibleSuccess(true);
        setCreatedSector(false);
        setVisiblePercentages(false);
        break;
      case 'editSectorName':
        setVisible(false);
        props.handleChange('editSectorName', changes);
        setModalTitle('Cambio realizado con éxito');
        setModalBtn('Aceptar');
        setVisibleSuccess(true);
        setCreatedSector(false);
        setVisiblePercentages(false);
        break;
      case 'deleteShift':
        setVisibleDelete(false);
        setModalTitle('Cambio realizado con éxito');
        setsuccessText('');
        setShiftToDelete('');
        setModalBtn('Volver');
        setVisibleSuccess(true);
        setCreatedSector(false);
        setVisiblePercentages(false);
        break;
      case 'deleteSector':
        setVisibleDelete(false);
        setModalTitle('Cambio realizado con éxito');
        setsuccessText('');
        setSectorToDelete('');
        setModalBtn('Volver');
        setVisibleSuccess(true);
        setCreatedSector(false);
        setVisiblePercentages(false);
        break;
      case 'addShift':
        setVisibleConfirmAdd(false);
        setModalTitle('Cambio realizado con éxito');
        setSectorToDelete('');
        setModalBtn('Aceptar');
        setCreatedSector(false);
        setVisibleSuccess(true);
        setVisiblePercentages(false);
        break;
      case 'addSector':
        setVisibleConfirmAdd(false);
        setModalTitle('El sector se creó con éxito');
        setsuccessText(
          'Asegurate de modificar los porcentajes para que este sector empiece a funcionar.',
        );
        setSectorToDelete('');
        setModalBtn('Modificar porcentajes');
        setCreatedSector(true);
        setVisibleSuccess(true);
        setVisiblePercentages(false);
        setVisiblePassword(false);
        setVisibleAdd(false);
        break;
      default:
        throw new Error('Input inválido');
    }
  };

  const handleDeleteTurno = (shift, id) => {
    setVisible(false);
    setShiftToDelete(shift);
    setShiftIdToDelete(id);
    setVisibleDelete(true);
  };

  const handleDeleteSector = (sector, id) => {
    setVisibleSectores(false);
    setSectorToDelete(sector);
    setSectorIdToDelete(id);
    setVisibleDelete(true);
  };

  const handleAddTurno = () => {
    setAddShift(true);
    setVisibleAdd(true);
  };

  const handleAddSector = () => {
    setAddShift(false);
    setVisibleAdd(true);
  };

  const handleConfirmAddSectorShift = (arg, obj) => {
    setVisibleAdd(false);
    if (arg === 'addShift') {
      setAddShift(true);
      setNewShiftInfo(obj);
    } else {
      setAddShift(false);
      setNewSectorInfo(obj);
    }
    setVisibleConfirmAdd(true);
    setVisiblePassword(true);
  };

  const hideConfirmAdd = () => {
    setVisibleConfirmAdd(false);
  };

  const hidePassword = () => {
    setVisiblePassword(false);
  };

  const handleSavePercentageChanges = async (newPercentages) => {
    if (newPercentages) {
      const percentageSum = [];
      newPercentages.map((x) => percentageSum.push(parseInt(x.percentage)));
      const finalSum = percentageSum.reduce((a, b) => a + b);

      if (finalSum !== 100) {
        setErrorMessage(
          `El total del porcentaje elegido tiene que ser igual a 100% (actualmente ${finalSum}%)`,
        );
        setEnableSave(false);
      } else {
        setEnableSave(true);
        setErrorMessage('');

        const editSectorService = {
          url: `${process.env.REACT_APP_BFF_WEB_URL}/commerce/sectors/update`,
          method: 'PATCH',
          data: newPercentages,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('loggedUser')}`,
            'Content-Type': 'application/json',
          },
        };

        try {
          setBtnLoading(true);
          setHasError(false);
          await AbstractService(editSectorService);
          localStorage.setItem(
            'sector_newPercentages',
            JSON.stringify(newPercentages),
          );
          const newPercentagesArray = JSON.parse(
            localStorage.getItem('sector_newPercentages'),
          );
          setNewPercentages(newPercentagesArray);
        } catch (error) {
          setHasError(true);
        }
        setBtnLoading(false);
        setModalFinishTitle('Cambio realizado con éxito');
        setModalFinishBtn('Aceptar');
        showFinishModal();
        setVisibleSuccess(false);
      }
    } else {
      setModalFinishTitle('Cambio realizado con éxito');
      setModalFinishBtn('Aceptar');
      showFinishModal();
      setVisibleSuccess(false);

      setVisibleConfirmAdd(false);
      setCreatedSector(false);
    }
  };

  // Turnos y sectores preconfigurados para nuevo comercio
  React.useEffect(() => {
    if (props.preconfigShifts === false || props.preconfigSectors === false) {
      setNewCommerce(true);
      setDisplayPreconf('block');
    }
  }, [props.preconfigShifts, props.preconfigSectors]);

  const handleAcceptPreconf = async (value) => {
    try {
      const body = {
        [`${value}_accepted`]: true,
        tc_accepted_datetime: new Date(),
      };

      const updatePreconfigService = {
        url: `${process.env.REACT_APP_BFF_WEB_URL}/commerce/blobs/update`,
        method: 'PATCH',
        data: body,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('loggedUser')}`,
          'Content-Type': 'application/json',
        },
      };

      await AbstractService(updatePreconfigService);
      setDisplayPreconf('none');
    } catch (error) {
      setHasError(true);
    }
  };

  const renderGridItem = (x, id) => {
    return (
      <Grid item xs={4} key={id}>
        <div className='turnosYSectoresMainDiv'>
          <div className='turnosYSectoresTitleDiv'>
            <h2 className='turnosYSectoresCenterTitle'>{x.name}</h2>
            <p className='turnosYSectoresCenterParagraph'>
              {props.shiftsData
                ? `${x.from_hour} hs-${x.to_hour} hs`
                : `${x.percentage}% de las propinas`}
            </p>
            <p
              className='turnosYSectoresEditar'
              style={
                props.shiftsData
                  ? { display: 'none' }
                  : { alignSelf: 'normal', marginTop: -5 }
              }
              onClick={
                props.shiftsData
                  ? () => {}
                  : () => showModalSectores(x.name, x.id, x.percentage)
              }
            >
              Editar/Eliminar sector
            </p>
          </div>
          <p
            className='turnosYSectoresEditar'
            style={props.shiftsData ? {} : { display: 'none' }}
            onClick={
              props.shiftsData
                ? () => showModal(x.name, x.id, x.from_hour, x.to_hour)
                : () => {}
            }
          >
            Editar
          </p>
        </div>
      </Grid>
    );
  };

  const renderGrid = () => {
    if (props.shiftsData) {
      //  En caso de que se edite un turno se guarda en el storage, se reemplaza en el array y se muestra en el componente
      const shiftsEditedData = JSON.parse(
        localStorage.getItem('shift_editedData'),
      );

      const finalArray = JSON.parse(localStorage.getItem('shift_config'));

      if (shiftsEditedData) {
        const filteredArray = finalArray.map((item) => {
          if (item.id === shiftsEditedData.id) {
            return { ...item, ...shiftsEditedData };
          }
          return item;
        });

        const sortedArray = filteredArray.sort((a, b) =>
          a.description > b.description
            ? 1
            : a.description < b.description
            ? -1
            : 0,
        );
        localStorage.setItem('shift_config', JSON.stringify(sortedArray));
        return sortedArray.map((x, id) => renderGridItem(x, id));
      }
      return props.array
        .sort((a, b) =>
          a.description > b.description
            ? 1
            : a.description < b.description
            ? -1
            : 0,
        )
        .map((x, id) => renderGridItem(x, id));
    }
    if (newPercentages.length >= 1) {
      // En caso de modificar los porcentajes se guardan en el storage y se muestran en el componente
      return newPercentages.map((x, id) => renderGridItem(x, id));
    }
    return props.array.map((x, id) => renderGridItem(x, id));
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className='turnosYSectoresCardContainerDiv'>
      <div className='turnosYSectoresHeaderDiv'>
        <div>
          <div
            className='turnosYSectoresGoBackDiv'
            onClick={props.handleGoBack}
          >
            <ion-icon name='chevron-back-outline' size={16} />
            <p className='turnosYSectoresGoBackParagraph'>Volver</p>
          </div>

          <h1 className='turnosYSectoresTitle'>
            {props.shiftsData ? 'Turnos' : 'Sectores'}
          </h1>
          <p className='turnosYSectoresParagraph'>
            {props.shiftsData
              ? 'A continuación podes modificar los horarios de los distintos turnos.'
              : 'A continuación podes modificar los porcentajes de los distintos sectores.'}
          </p>
        </div>

        <div className='nuevoTurnoBtnDiv'>
          <button
            className='nuevoTurnoBtn'
            onClick={() =>
              props.shiftsData ? handleAddTurno() : handleAddSector()
            }
          >
            Nuevo {props.shiftsData ? 'turno' : 'sector'}
          </button>
        </div>
      </div>

      <Grid container spacing={2} paddingX='50px'>
        {renderGrid()}
      </Grid>

      <p className='turnosYSectoresError'>{errorMessage}</p>
      {props.sectorsData ? (
        <button
          onClick={showEditPercentages}
          className='turnosYSectoresSaveChanges'
        >
          Modificar porcentajes
        </button>
      ) : null}

      {newCommerce && (
        <PreconfigShiftSectorModal
          shiftsData={props.shiftsData ?? false}
          displayPreconf={displayPreconf}
          onClick={() =>
            props.shiftsData
              ? handleAcceptPreconf('shifts')
              : handleAcceptPreconf('sectors')
          }
        />
      )}

      <TurnosModal
        visible={visible}
        shift={shift}
        shiftId={shiftId}
        handleSuccessTurno={handleSuccess}
        handleDeleteTurno={handleDeleteTurno}
        activeShift={props?.activeShift?.shiftId}
        fromHour={fromHour}
        toHour={toHour}
        render={renderGrid}
      />
      <SectoresModal
        visible={visibleSectores}
        sector={designatedSector}
        sectorId={designatedSectorId}
        sectorPercentage={designatedSectorPercentage}
        showEditPercentages={showEditPercentages}
        handleSuccessSector={handleSuccess}
        handleDeleteSector={handleDeleteSector}
        percentageArray={props.array}
      />
      <SectorPercentagesModal
        visible={visiblePercentages}
        btnLoading={btnLoading}
        handleSuccessSector={handleSavePercentageChanges}
        sectors={newPercentages.length >= 1 ? newPercentages : props.array}
        hideModal={hideEditPercentages}
        showPercentagesPasswordModal={showPercentagesPasswordModal}
        hidePercentagesPasswordModal={hidePercentagesPasswordModal}
        visiblePercentagePassword={visiblePercentagePassword}
      />
      <SuccessModal
        visible={visibleSuccess}
        hideSuccess={hideSuccess}
        title={modalTitle}
        button={modalBtn}
        text={successText}
        createdSector={createdSector}
        editSectors={showEditPercentages}
      />
      <FinishModal
        visible={visibleFinish}
        hideFinishModal={hideFinishModal}
        title={modalFinishTitle}
        button={modalFinishBtn}
      />
      <DeleteSectorTurnoModal
        visible={visibleDelete}
        deleteShift={shiftToDelete}
        deleteSector={sectorToDelete}
        deleteSectorId={sectorIdToDelete}
        deleteShiftId={shiftIdToDelete}
        handleSuccess={handleSuccess}
        handleFilterArray={props.handleFilterArray}
      />
      <AddSectorTurnoModal
        visible={visibleAdd}
        array={props.array}
        addShift={addShift}
        handleConfirmAddSectorShift={handleConfirmAddSectorShift}
      />
      <ConfirmSectorTurnoModal
        visible={visibleConfirmAdd}
        addShift={addShift}
        newShiftInfo={newShiftInfo}
        newSectorInfo={newSectorInfo}
        handleSuccess={handleSuccess}
        handleAddArray={props.handleAddArray}
        visiblePassword={visiblePassword}
        hideConfirmAdd={hideConfirmAdd}
        hidePassword={hidePassword}
      />
      <ErrorPercentageModal
        visible={disabledVisiblePercentages}
        text='Para modificar el porcentaje debes tener al menos dos sectores.'
        button='Nuevo sector'
        handleAction={handleAddSector}
      />
    </div>
  );
}
