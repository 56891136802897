import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../../assets/tipie-vectores-01.png';
import './style.css';

export default function FourOFourModal() {
  const [display, setDisplay] = React.useState('block');

  const tipieLink = 'www.tipieapp.com';
  return (
    <div
      style={{
        display,
        position: 'fixed',
        zIndex: 22,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        overflow: 'auto',
        backgroundColor: 'rgb(0,0,0)',
        backgroundColor: 'rgba(0,0,0,0.4)',
      }}
    >
      <div className='modalUnauthorizedContent'>
        <div className='modalUnauthorized'>
          <h2 className='modalUnauthorizedMainTitle'>
            404: Esta página no existe
          </h2>
          <h4 className='modalUnauthorizedText'>
            Para continuar podés iniciar sesión en o registrarte en:
          </h4>
          <Link
            to={{ pathname: 'https://www.tipieapp.com' }}
            target='_blank'
            className='modalUnauthorizedLink'
          >
            {tipieLink}
          </Link>

          <img className='modalUnauthorizedImg' src={Icon} alt='Tipie' />
        </div>
      </div>
    </div>
  );
}
