import MainContent from './MainContent';
import './style.css';

export default function Card({
  className: userClassName = '',
  icon,
  side,
  corner,
  altCorner,
  biggerTitle,
  bigTitle,
  smallTitle,
  whatIs,
  howTo,
  faq,
  children,
}) {
  const className = `Card ${userClassName}`;

  const iconElement = icon && <img className='icon' src={icon} alt='icon' />;
  const sideElement = side && (
    <img className='side' src={side} alt='sideImage' />
  );
  const cornerElement = (corner || altCorner) && (
    <img
      className={corner ? 'corner' : 'altCorner'}
      src={corner || altCorner}
      alt='sideImage'
    />
  );

  const biggerTitleElement = biggerTitle && (
    <h2>
      {iconElement}
      {biggerTitle}
    </h2>
  );

  const bigTitleElement = bigTitle && (
    <h3>
      {iconElement}
      {bigTitle}
    </h3>
  );

  let smallTitleClass = '';
  if (whatIs) smallTitleClass = 'cardSmallTitle';
  else if (howTo) smallTitleClass = 'cardSmallTitleHowTo';

  let smallTitleContent = smallTitle;
  if (whatIs || faq) {
    smallTitleContent = (
      <h5 className={`cardSmallTitle${faq ? 'Faq' : 'P'}`}>{smallTitle}</h5>
    );
  }

  const smallTitleElement = smallTitle && (
    <h4 className={smallTitleClass}>
      {iconElement}
      {smallTitleContent}
    </h4>
  );

  return (
    <div className={side ? 'cardContainer' : userClassName}>
      {sideElement}
      <MainContent
        className={className}
        biggerTitleElement={biggerTitleElement}
        bigTitleElement={bigTitleElement}
        smallTitleElement={smallTitleElement}
        cornerElement={cornerElement}
      >
        {children}
      </MainContent>
    </div>
  );
}
