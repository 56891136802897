import { Link } from 'react-router-dom';
import NavButton from '../../../../components/NavButton';
import logo2 from '../../../../assets/Logo.svg';

import './style.css';

export default function LandingHeader({ register }) {
  return (
    <nav className='topNavLanding' style={{ position: 'absolute' }}>
      <Link to='/'>
        <img src={logo2} alt='Tipie Logo' className='navLogoTipieOne' />
      </Link>
      <div className='TopNavBarChildrenDiv'>
        <button type='button' className='navButtonSecondary' onClick={register}>
          Registrate
        </button>

        <NavButton to='login'>Iniciar sesión</NavButton>
      </div>
    </nav>
  );
}
