import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import { styled } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import TopNavBar from '../../components/TopNavBar';
import './style.css';

const CustomField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#E98A15',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#E98A15',
  },
});

function TextInput({
  width,
  label,
  onChange,
  value,
  multiline,
  required,
  children,
}) {
  return (
    <div style={{ width: width ?? '350px' }}>
      <CustomField
        label={label}
        variant='standard'
        onChange={onChange}
        value={value}
        multiline={multiline}
        fullWidth
        required={required}
      >
        {children}
      </CustomField>
    </div>
  );
}

export default function Contact() {
  const contactEmail = 'contacto@tipieapp.com';

  const [name, setName] = React.useState('');
  const [mail, setMail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [message, setMessage] = React.useState('');

  const [mustSend, setMustSend] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setMustSend(true);
  };

  const sendData = async () => {
    if (!name || !(mail || phone) || !message) {
      setSuccess(false);
      setError('Datos insuficientes');
      setMustSend(false);
      return;
    }

    const formData = {
      url: 'some-endpoint',
      method: 'post',
      data: {
        name,
        mail,
        phone,
        message,
      },
    };

    const messageSent = true; // await AbstractService(formData);
    if (messageSent) {
      setSuccess(true);
    } else {
      setError(
        'Error enviando tu mensaje, volvé a intentar más tarde, por favor.',
      );
      setMustSend(false);
    }
  };

  React.useEffect(() => {
    if (!mustSend) {
      return;
    }
    sendData();
  }, [mustSend]);

  React.useEffect(() => {
    if (!success) {
      // Error in message is handled in sendData
      return;
    }
    setShowDialog(true);
  }, [success]);

  const clearMessage = () => {
    setName('');
    setMail('');
    setPhone('');
    setMessage('');

    setMustSend(false);
    setShowDialog(false);
    setError('');
    setSuccess(false);
  };

  return (
    <div>
      <TopNavBar />
      <main style={{ margin: '100px 25px' }}>
        <h1 className='contactHeader'>Contactanos</h1>
        <p>
          Podés comunicarte con nosotros por mail escribiéndonos a{' '}
          <a className='contactLink' href={`mailto:${contactEmail}`}>
            {contactEmail}
          </a>
          , o llenando el siguiente formulario:
        </p>
        <p className='loginErrorText'>{error}</p>
        <form onSubmit={handleSubmit}>
          <TextInput
            label='Nombre'
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <TextInput
            label='Email'
            value={mail}
            onChange={(e) => setMail(e.target.value.trim())}
          />
          <TextInput
            label='Teléfono'
            value={phone}
            onChange={(e) => setPhone(e.target.value.trim())}
          />
          <TextInput
            label='Mensaje'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            multiline
            required
          />
          <button type='submit' className='navButtonPrimary formButton'>
            Enviar
          </button>
        </form>
      </main>
      <Dialog open={showDialog} onClose={clearMessage}>
        <div style={{ margin: '25px' }}>
          <h1 className='contactHeader'>¡Gracias por tu mensaje!</h1>
          <p style={{ textAlign: 'center', width: '100%' }}>
            Nos pondremos en contacto a la brevedad
          </p>
        </div>
      </Dialog>
    </div>
  );
}
