import { useState } from 'react';
import InstagramIcon from '@mui/icons-material/Instagram';
import TermsModal from '../../../../components/Modal/Terms';

import './style.css'; // Ensure this import is here to apply custom styles

export default function LandingFooter() {
  const linkPadding = {
    padding: '5px 15px',
    color: '#6E6E6E',
    textDecoration: 'none',
    cursor: 'pointer',
  };
  const [showTerms, setShowTerms] = useState(false);
  const toggleTerms = () => {
    setShowTerms(!showTerms);
  };

  return (
    <footer className='landingFooter'>
      <hr />
      <div className='landingFooterDiv'>
        <div className='landingStoreIcons storeIconsFlex'>
          <a
            href='https://play.google.com/store/apps/details?id=tipieapp.apk&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
            style={{ display: 'inline-block' }}
          >
            <img
              className='storeBadge' // Use the common class here
              alt='Disponible en Google Play'
              src='https://play.google.com/intl/en_us/badges/static/images/badges/es-419_badge_web_generic.png'
            />
          </a>
          <a
            href='https://apps.apple.com/ar/app/tipie/id1619703820?itsct=apps_box_badge&amp;itscg=30200'
            style={{
              display: 'inline-block',
              overflow: 'hidden',
              borderRadius: '13px',
            }} // Removed width and height here
          >
            <img
              className='storeBadge' // Use the common class here
              src='https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/es-mx?size=250x83&amp;releaseDate=1711324800'
              alt='Download on the App Store'
            />
          </a>
        </div>

        <div className='landingLeftSide'>
          <a href='https://instagram.com/tipieapp'>
            <InstagramIcon
              style={{ color: '#6E6E6E', marginRight: 10, cursor: 'pointer' }}
            />
          </a>
          <a style={linkPadding} href='/contacto'>
            Contacto
          </a>
          <a style={linkPadding} href='/soporte'>
            Ayuda
          </a>
          <a style={linkPadding} onClick={toggleTerms}>
            Términos y condiciones
          </a>
        </div>
      </div>

      {/* MODALS */}
      {showTerms ? <TermsModal toggle={toggleTerms} /> : ''}
    </footer>
  );
}
