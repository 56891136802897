/* eslint-disable default-case */
import React from 'react';
import { NumericFormat } from 'react-number-format';
import { parseCurrencyStringToNumber } from '../../../utils/numbers';
import { AbstractService } from '../../../services/abstractService';
import ModalActionBar from '../../ModalActionBar';
import './style.css';

export default function TipModal(props) {
  const [amount, setAmount] = React.useState('');
  const [clientName, setClientName] = React.useState('');
  const [clientDocument, setClientDocument] = React.useState('');
  const [clientMail, setClientMail] = React.useState('');
  const [disabled, setDisabled] = React.useState(true);
  const [hasError, setHasError] = React.useState(false);
  const [btnBackground, setBtnBackground] = React.useState('#D1D1D1');
  const [display, setDisplay] = React.useState('none');

  const handleClientNameChange = (e) => {
    setClientName(e.target.value);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleClientDocumentChange = (e) => {
    setClientDocument(e.target.value);
  };

  const handleClientMailChange = (e) => {
    setClientMail(e.target.value);
  };

  const handleDisplay = () => {
    setDisplay('none');
  };

  React.useEffect(() => {
    if (props.visible) {
      setDisplay('block');
    }
  }, [props.visible]);

  const handleTip = async () => {
    const addTipService = {
      url: `${process.env.REACT_APP_BFF_WEB_URL}/transactions/create`,
      method: 'POST',
      data: {
        name: clientName,
        doc_number: clientDocument,
        email: clientMail,
        total: parseCurrencyStringToNumber(amount),
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('loggedUser')}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      setDisabled(true);
      setBtnBackground('#D1D1D1');
      setHasError(false);
      const response = await AbstractService(addTipService);
      window.location.href = response.url;
    } catch (error) {
      setDisabled(false);
      setBtnBackground('#E98A15');
      setHasError(true);
    }
    setDisabled(false);
    setBtnBackground('#E98A15');
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleTip();
  };

  React.useEffect(() => {
    if (
      amount.length > 1 &&
      clientName.length > 1 &&
      clientDocument.length > 6
    ) {
      setDisabled(false);
      setBtnBackground('#E98A15');
    } else {
      setDisabled(true);
      setBtnBackground('#D1D1D1');
    }
  }, [amount, clientName, clientDocument]);

  return (
    <div
      style={{
        display,
        position: 'fixed',
        zIndex: 22,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        overflow: 'auto',
        // backgroundColor: 'rgb(0,0,0)',
        backgroundColor: 'rgba(0,0,0,0.4)',
      }}
      onClick={handleDisplay}
    >
      <div
        className='modalTipContent'
        onClick={(e) => {
          // do not close modal if anything inside modal content is clicked
          e.stopPropagation();
        }}
      >
        <p className='modalTipParagraph'>
          Para agregar propina, necesitamos que completes los siguientes datos:
        </p>

        <form onSubmit={handleFormSubmit} className='modalTipForm'>
          <div className='divLabelTipModal'>
            <NumericFormat
              className='inputAmount'
              onChange={handleAmountChange}
              value={amount}
              prefix='$'
              allowLeadingZeros
              thousandSeparator='.'
              decimalSeparator=','
              decimalScale={2}
              placeholder='$0'
            />
          </div>

          <div className='divLabelTipModal'>
            <input
              className='inputTip'
              type='text'
              value={clientName}
              onChange={handleClientNameChange}
              placeholder='Nombre del cliente'
            />
          </div>

          <div className='divLabelTipModal'>
            <NumericFormat
              className='inputTip'
              onChange={handleClientDocumentChange}
              value={clientDocument}
              placeholder='Documento'
              format='#########'
              maxLength={8}
              allowNegative={false}
              decimalSeparator=''
              isNumericString
            />
          </div>

          <div className='divLabelTipModal'>
            <input
              className='inputTip'
              type='text'
              value={clientMail}
              onChange={handleClientMailChange}
              placeholder='Mail del cliente'
            />
          </div>

          <ModalActionBar
            primaryDisabled={disabled}
            primaryLabel='Agregar'
            primaryType='submit'
            onSecondaryClick={handleDisplay}
          />
        </form>
      </div>
    </div>
  );
}
