import './style.css';

export default function GetStarted({ register }) {
  return (
    <article className='getStarted'>
      <hr style={{ color: '##E98A15' }} />
      <h1>¿Querés usar Tipie en tu negocio?</h1>
      <p>Completá el formulario y nos pondremos en contacto.</p>
      <br />
      <button type='button' className='landingLoginBtn' onClick={register}>
        Completar formulario
      </button>
    </article>
  );
}
