/* eslint-disable default-case */
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import './style.css';
import { Button, TextField } from '@mui/material';
import ErrorPercentageModal from './ErrorPercentage';

import ConfirmPasswordModal from '../../ConfirmPasswordModal';

export default function SectoresModal(props) {
  const [display, setDisplay] = React.useState('none');
  const [disabled, setDisabled] = React.useState(true);

  const [displayPassword, setDisplayPassword] = React.useState(false);

  const [sectorName, setSectorName] = React.useState('');

  const [visible, setVisible] = React.useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleDisplay = () => {
    if (props.hideModal) {
      props.hideModal();
    }
    setDisplay('none');
    setSectorName('');
    setDisabled(true);
    setVisible(false);
  };

  const handleConfirmPassword = () => {
    setDisplayPassword(true);
  };

  const handleConfirmSector = () => {
    props.handleSuccessSector('editSectorName', props.sector, {
      id: props.sectorId,
      name: sectorName,
    });

    handleDisplay();
  };

  const handleDeleteSector = () => {
    if (parseInt(props.sectorPercentage) !== 0) {
      showModal();
    } else {
      handleDisplay();
      props.handleDeleteSector(props.sector, props.sectorId);
    }
  };

  const handleShowEditPercentages = () => {
    setVisible(false);
    handleDisplay();
    props.showEditPercentages();
  };

  React.useEffect(() => {
    if (props.visible) {
      setDisplay('block');
    }
  }, [props.visible]);

  React.useEffect(() => {
    if (sectorName.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [sectorName]);

  return (
    <div
      style={{
        display,
        position: 'fixed',
        zIndex: 22,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        overflow: 'auto',
        backgroundColor: 'rgb(0,0,0)',
        backgroundColor: 'rgba(0,0,0,0.4)',
      }}
      onClick={handleDisplay}
    >
      <div
        className='modalSectoresContent'
        onClick={(e) => {
          // do not close modal if anything inside modal content is clicked
          e.stopPropagation();
        }}
      >
        <button className='modalSectoresCloseBtn' onClick={handleDisplay}>
          <CloseIcon />
        </button>

        <h2 className='modalSectoresSectorName'>{props.sector}</h2>

        <div className='modalSectoresInputDiv'>
          <TextField
            label='Modificar nombre del sector'
            variant='standard'
            fullWidth
            sx={{
              // maxWidth: '70%',
              '& label.Mui-focused': {
                color: '#E98A15',
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: '#E98A15',
              },
            }}
            value={sectorName}
            onChange={(e) => setSectorName(e.target.value)}
          />
        </div>

        <div className='modalSectoresDeleteBtnDiv'>
          <Button
            variant='text'
            sx={{
              color: '#E98A15',
              textTransform: 'none',
              '&:hover': { bgcolor: '#E98A150a' },
            }}
            onClick={handleDeleteSector}
          >
            Eliminar Sector
          </Button>

          <p className='modalSectoresPDelete'>
            Para poder eliminar el sector es necesario que su porcentaje esté en
            0%.
          </p>
        </div>

        <button
          className='turnosYSectoresSaveChanges'
          style={{ justifySelf: 'flex-end', marginRight: 0, width: 150 }}
          onClick={handleConfirmPassword}
          // onClick={handleConfirmSector}
          disabled={disabled}
        >
          Aceptar
        </button>
      </div>

      <ErrorPercentageModal
        visible={visible}
        text='Para poder eliminar el turno es necesario que el porcentaje esté en 0%. Asegurate de modificar esto y vuelve a intentarlo.'
        handleAction={handleShowEditPercentages}
        button='Modificar porcentajes'
      />
      {/* //Modal para confirmar contraseña */}
      <ConfirmPasswordModal
        visiblePassword={displayPassword}
        onSuccess={handleConfirmSector}
      />
    </div>
  );
}
