import React from 'react';
import './style.css';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useHistory } from 'react-router-dom';
import iconSuccess from '../../../assets/icono-success.svg';
import CustomizedInputs from '../../Forms/CustomizedInputs';
import ModalActionBar from '../../ModalActionBar';
import { LoginService } from '../../../services/loginService';
import { AbstractService } from '../../../services/abstractService';

export default function ConfirmEndShift(props) {
  const [display, setDisplay] = React.useState('none');
  const [mustSubmit, setMustSubmit] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [disabled, setDisabled] = React.useState(true);
  const [btnBackground, setBtnBackground] = React.useState('#D1D1D1');
  const [succesfulEndShift, setSuccesfulEndShift] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [displayLogout, setDisplayLogout] = React.useState('none');
  const [showLoading, setShowLoading] = React.useState(false);

  // EYE ICON
  const [visibilityPassword, setVisibilityPassword] = React.useState(false);

  const togglePassword = () => {
    if (visibilityPassword) {
      setVisibilityPassword(false);
    } else {
      setVisibilityPassword(true);
    }
  };
  // EYE ICON

  const currentTime = new Date();

  const endShiftSendEmail = async (data) => {
    const body = {
      reason: 'closing_shift_email',
      receptor_email: data.email,
      commerce_name: localStorage.getItem('commerceName'),
      commerce_address: localStorage.getItem('commerceAddress'),
      shift_name: props.shiftName,
      shift_schedule: `${props.shiftfromHour}-${props.shiftToHour}`,
      total_shift_account: props.account,
      closing_time: currentTime.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      }),
      sector_name: props.sectors[0].name,
      sector_percentage: props.sectors[0].percentage,
      employees_on_shift: `${data.name} ${data.last_name}`,
      user_account: props.account,
    };

    const endShiftEmail = {
      // url: `${process.env.REACT_APP_BFF_WEB_URL}/notification/shift/closing`,
      method: 'POST',
      data: body,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('loggedUser')}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      await AbstractService(endShiftEmail);
    } catch (error) {}
  };
  const login = async () => {
    setMustSubmit(false);
    setShowLoading(true);
    try {
      const authBody = {
        userdata: localStorage.getItem('userdata'), // fetch from local storage
        password: password.trim(),
      };

      const res = await LoginService(authBody);

      if (res.status_code !== 200) {
        throw new Error('Unable to authenticate');
        setShowLoading(false);
      }

      const configEndShift = {
        url: `${process.env.REACT_APP_BFF_WEB_URL}/commerce/shift/end`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('loggedUser')}`,
          'Content-Type': 'application/json',
        },
      };

      const endShiftResponse = await AbstractService(configEndShift);
      setShowLoading(false);
      setSuccesfulEndShift(true);
      props.presentEmployeesData.map((data) => {
        endShiftSendEmail(data);
        return null; // Necesario para evitar una advertencia de retorno en una función map
      });
    } catch (err) {
      setShowLoading(false);
      setError(true);
    }
  };

  React.useEffect(() => {
    if (!mustSubmit) {
      return;
    }
    login();
  }, [mustSubmit]);

  const handleDisplay = () => {
    if (succesfulEndShift) {
      props.goBack();
      setPassword('');
      // window.location.reload(); // Force home to re-render
    } else {
      setDisplay('none');
      setPassword('');
    }
  };

  React.useEffect(() => {
    if (props.visible) {
      setDisplay('block');
    }
  }, [props.visible]);

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleEndShift = () => {
    setMustSubmit(true);
  };

  React.useEffect(() => {
    if (password.length > 1) {
      setDisabled(false);
      setBtnBackground('#E98A15');
    } else {
      setDisabled(true);
      setBtnBackground('#D1D1D1');
    }
  }, [password]);

  const history = useHistory();

  const handleLogout = () => {
    localStorage.setItem('loggedUser', null);
    localStorage.setItem('username', null);
    history.push('login');
  };

  React.useEffect(() => {
    if (error) {
      setDisplayLogout('block');
    }
  }, [error]);

  const handleLogoutModal = () => {
    setDisplayLogout('none');
  };

  return (
    <>
      <div
        style={{
          display,
          position: 'fixed',
          zIndex: 22,
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          overflow: 'auto',
          backgroundColor: 'rgb(0,0,0)',
          backgroundColor: 'rgba(0,0,0,0.4)',
        }}
        onClick={handleDisplay}
      >
        {succesfulEndShift ? (
          <div className='modalConfirmEndShiftContent'>
            <div className='modalConfirmEndShiftSuccess'>
              <img
                src={iconSuccess}
                className='modalConfirmEndShiftImg'
                alt='Icon Confirmado'
              />
              <h2 className='modalConfirmEndShiftSuccessText'>
                Cerraste el turno {props.shiftName}
              </h2>
              <button
                className='modalConfirmEndShiftBtn'
                onClick={props.goBack}
              >
                Ir al Inicio
              </button>
            </div>
          </div>
        ) : (
          <div
            className='modalConfirmEndShiftContent'
            onClick={(e) => {
              // do not close modal if anything inside modal content is clicked
              e.stopPropagation();
            }}
          >
            <h2 className='modalConfirmEndShiftTitle'>
              ¿Querés cerrar el turno {props.shiftName}?
            </h2>
            <p className='modalConfirmEndShiftParagraph'>
              {' '}
              Al cerrarlo, las propinas serán divididas entre los Colaboradores
              Activos del turno.
            </p>

            <div className='modalConfirmEndShiftInput'>
              <CustomizedInputs
                label='Contraseña'
                password={!visibilityPassword ? password : null}
                handlePassword={handlePassword}
                error={error}
                deleteError={() => setError(false)}
                value={password}
              />
              {visibilityPassword ? (
                <VisibilityOffIcon
                  className='eyeIcon'
                  onClick={togglePassword}
                />
              ) : (
                <VisibilityIcon className='eyeIcon' onClick={togglePassword} />
              )}
              {/* <p className={error ? "modalConfirmEndShiftPassword" : "modalConfirmEndShiftPasswordNone"}>Contraseña incorrecta!</p> */}
            </div>

            <ModalActionBar
              onPrimaryClick={handleEndShift}
              primaryLabel='Cerrar turno'
              onSecondaryClick={handleDisplay}
              loading={showLoading}
            />
          </div>
        )}
      </div>

      {/* Logout */}
      <div
        style={{
          display: displayLogout,
          position: 'fixed',
          zIndex: 22,
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          overflow: 'auto',
          backgroundColor: 'rgb(0,0,0)',
          backgroundColor: 'rgba(0,0,0,0.4)',
        }}
        onClick={handleLogoutModal}
      >
        <div
          className='modalEditTurnoContent'
          onClick={(e) => {
            // do not close modal if anything inside modal content is clicked
            e.stopPropagation();
          }}
        >
          <h2 className='modalEditTurnoTitle'>Contraseña incorrecta</h2>
          <p>
            Si olvidaste la contraseña podes restablecerla cerrando la sesión.
          </p>

          <ModalActionBar
            onPrimaryClick={handleLogout}
            primaryLabel='Cerrar sesión'
            secondaryLabel='Volver a intentarlo'
            onSecondaryClick={handleLogoutModal}
          />
        </div>
      </div>
    </>
  );
}
