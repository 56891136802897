import { Card, CardContent } from '@mui/material';
import NavButton from '../NavButton';

import iconoError from '../../assets/icono-error.svg';

export default function ErrorMessage(props) {
  return (
    <div
      style={{
        height: '100vh',
        width: '100%',
        backgroundColor: '#F5F5F5',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card>
        <CardContent>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <img src={iconoError} alt='Error' />
            <h1
              style={{
                color: '#63324B',
                margin: '24px',
              }}
            >
              ¡Ops! Error
            </h1>
            <p
              style={{
                margin: '0px 50px 30px 50px',
              }}
            >
              {props.views
                ? 'Intentá más tarde'
                : 'La operación no se pudo procesar. Volvé a intentarlo.'}
            </p>

            <NavButton to='/home'>Volver al inicio</NavButton>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
