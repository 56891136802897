import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import './style.css';

export default function FirstLoginModal(props) {
  const [display, setDisplay] = React.useState('none');
  const history = useHistory();

  const tipieLink = 'www.tipieapp.com';

  const handleDisplay = () => {
    setDisplay('none');
    props.dismiss();
  };

  React.useEffect(() => {
    if (props.visible) {
      setDisplay('block');
    }
  }, [props.visible]);

  return (
    <div
      style={{
        display,
        position: 'fixed',
        zIndex: 22,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        overflow: 'auto',
        backgroundColor: 'rgb(0,0,0)',
        backgroundColor: 'rgba(0,0,0,0.4)',
      }}
      onClick={handleDisplay}
    >
      <div className='modalFirstLoginContent'>
        <div className='modalFirstLogin'>
          <h2 className='modalFirstLoginMainTitle'>
            No se puede iniciar turno
          </h2>

          <p className='modalFirstLoginText'>
            Para empezar a procesar propinas, primero tenés que tener vinculados
            los colaboradores que trabajan en el local.
          </p>
          <p className='modalFirstLoginText'>
            Ellos se tienen que bajar la App Tipie, y registrarse. Pueden seguir
            las instrucciones desde nuestra web.
          </p>

          <Link to='/' target='_blank' className='modalFirstLoginLink'>
            {tipieLink}
          </Link>
        </div>
      </div>
    </div>
  );
}
