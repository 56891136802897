import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import CustomizedInputs from '../../CustomizedInputs';
import './style.css';

export default function EmailForOtp(props) {
  return (
    <div className='formLogin'>
      <div className='mainDivSendOtp'>
        <CustomizedInputs
          label='Mail registrado'
          handleUsername={(e) => props.handleUsernameResetPassword(e)}
          user={props.usernameResetPassword}
          error={props.errorUserResetPassword}
          deleteError={props.deleteError}
          handleSubmit={(e) => props.handleSubmitResetPassword(e)}
        />

        <div className='divSpamAlert'>
          <InfoOutlinedIcon className='spamAlertIcon' />
          <div>
            <p className='spamAlertText'>
              Recordá chequear la casilla de Spam.{' '}
            </p>
            <p className='spamAlertText'>
              Sólo va a llegar el código si el mail está registrado en Tipie.
            </p>
          </div>
        </div>
        <div className='sendOtpBtnDiv'>
          <button
            onClick={(e) => props.handleSubmitResetPassword(e)}
            disabled={props.disabled}
            style={{
              width: 167,
              borderRadius: 24,
              // paddingTop: 10,
              // paddingBottom: 10,
              fontSize: 14,
              fontFamily: 'Inter, Semi Bold',
              backgroundColor: props.btnBackground,
              color: 'white',
              margin: 8,
              borderWidth: 0,
              cursor: props.disabled ? 'not-allowed' : 'pointer',
            }}
          >
            {props.showLoading ? (
              <div
                style={{
                  paddingTop: 15,
                  paddingBottom: 15,
                }}
              >
                <CircularProgress color='inherit' size={14} />
              </div>
            ) : (
              <p>Continuar</p>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
