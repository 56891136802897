import { useState, useEffect } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';
import CustomizedInputs from '../../CustomizedInputs';
import { AbstractService } from '../../../../services/abstractService';
import './style.css';

export default function InsertNewPassword(props) {
  const [btnBackground, setBtnBackground] = useState('#D1D1D1');
  const [disabled, setDisabled] = useState(true);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [showLoadingNewPassword, setShowLoadingNewPassword] = useState(false);

  // EYE ICON
  const [visibilityPasswordOne, setVisibilityPasswordOne] = useState(false);
  const [visibilityPasswordTwo, setVisibilityPasswordTwo] = useState(false);

  const togglePassword = (value) => {
    switch (value) {
      case 'one':
        if (visibilityPasswordOne) {
          setVisibilityPasswordOne(false);
        } else {
          setVisibilityPasswordOne(true);
        }
        break;
      case 'two':
        if (visibilityPasswordTwo) {
          setVisibilityPasswordTwo(false);
        } else {
          setVisibilityPasswordTwo(true);
        }
        break;
      default:
        break;
    }
  };
  // EYE ICON

  const passwordRule = new RegExp(
    '/([A-Z])[0-9]|([0-9])[A-Z]|([A-Z].+)[0-9]|([0-9].+)[A-Z]/gm',
  );

  useEffect(() => {
    if (password.length > 1 && confirmPassword.length > 1) {
      setDisabled(false);
      setBtnBackground('#E98A15');
    } else {
      setBtnBackground('#D1D1D1');
      setDisabled(true);
    }
  }, [password, confirmPassword]);

  const handleNewPassword = (e) => {
    setPassword(e.target.value);
  };

  const successfulChangePasswordEmail = async () => {
    const body = {
      reason: 'change_password_successful_email',
      receptor_email: props.receptorEmail,
    };
    const successfulChangePassword = {
      // url: `${process.env.REACT_APP_BFF_WEB_URL}/notification/password/change`,
      method: 'POST',
      data: body,
      headers: {
        Authorization: `Bearer ${
          props.session ?? localStorage.getItem('loggedUser')
        }`,
        'Content-Type': 'application/json',
      },
    };
    try {
      setError(false);

      await AbstractService(successfulChangePassword);
    } catch (error) {
      setError(true);
    }
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleInsertNewPassword = async () => {
    if (password.trim() !== confirmPassword.trim()) {
      setError(true);
      setErrorText('Las contraseñas no coinciden');
      return;
    }
    if (!passwordRule.test(password) || password.trim().length < 8) {
      setError(true);
      setErrorText(
        'La contraseña debe tener mínimo 8 carateres e incluir al menos una mayúscula y un número',
      );
      return;
    }
    try {
      const user = props.session ?? localStorage.getItem('loggedUser');
      const body = {
        password,
      };
      setDisabled(true);
      setBtnBackground('#D1D1D1');
      setShowLoadingNewPassword(true);
      const updatePassword = await AbstractService({
        url: `${process.env.REACT_APP_BFF_WEB_URL}/profile/commerce`,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${user}`,
          'Content-Type': 'application/json',
        },
        data: body,
      });
      successfulChangePasswordEmail();
      props.showModal('success');
      setShowLoadingNewPassword(false);
      setBtnBackground('#E98A15');
    } catch (error) {
      props.showModal('error');
      setShowLoadingNewPassword(false);
      setBtnBackground('#E98A15');
    }
  };

  const deleteErrorMessage = () => {
    setError(false);
  };

  return (
    <div>
      <div className='insertNewPasswordDiv'>
        <div className='divLabelInsertNewPassword'>
          <CustomizedInputs
            newPassword={!visibilityPasswordOne}
            label='Nueva contraseña'
            error={error}
            deleteError={deleteErrorMessage}
            handleNewPassword={handleNewPassword}
            value={password}
          />
          {visibilityPasswordOne ? (
            <VisibilityOffIcon
              className='eyeIcon'
              onClick={() => togglePassword('one')}
            />
          ) : (
            <VisibilityIcon
              className='eyeIcon'
              onClick={() => togglePassword('one')}
            />
          )}
        </div>

        <div className='divLabelInsertNewPassword'>
          <CustomizedInputs
            confirmPassword={!visibilityPasswordTwo}
            label='Repetí tu nueva contraseña'
            handleConfirmPassword={handleConfirmPassword}
            deleteError={deleteErrorMessage}
            error={error}
            value={confirmPassword}
          />
          {visibilityPasswordTwo ? (
            <VisibilityOffIcon
              className='loginEyeIcon'
              onClick={() => togglePassword('two')}
            />
          ) : (
            <VisibilityIcon
              className='loginEyeIcon'
              onClick={() => togglePassword('two')}
            />
          )}
        </div>
        <div
          className={
            error ? 'insertPasswordErrorDiv' : 'insertPasswordErrorDivNone'
          }
        >
          <ion-icon name='alert-circle-outline' style={{ color: '#C51111' }} />
          <p className='insertPasswordErrorText'>{errorText}</p>
        </div>
      </div>
      <div className='resetPasswordBtnDiv'>
        <button
          disabled={disabled}
          style={{
            marginRight: 32,
            marginTop: 8,
            marginBottom: 8,
            width: 167,
            backgroundColor: btnBackground,
            color: 'white',
            fontFamily: 'Inter, Semi Bold',
            fontWeight: 500,
            fontSize: 16,
            padding: 0,
            border: 'none',
            borderRadius: 24,
            cursor: disabled ? 'not-allowed' : 'pointer',
          }}
          onClick={handleInsertNewPassword}
        >
          {showLoadingNewPassword ? (
            <div
              style={{
                paddingTop: 15,
                paddingBottom: 15,
              }}
            >
              <CircularProgress color='inherit' size={14} />
            </div>
          ) : (
            <p>Continuar</p>
          )}
        </button>
      </div>
    </div>
  );
}
