import Card from '../../components/Card';
import HorizontalBox from '../../components/HorizontalBox';

import './style.css';

import aboutTipie from '../../../../assets/aboutTipie2.png';

export default function WhatIs() {
  return (
    <div>
      <Card className='whatIs' side={aboutTipie} biggerTitle='¿Qué es Tipie?'>
        <p className='firstP'>
          <span className='whatIsSubtitle'>
            Somos un procesador de propinas.
          </span>
          <br />
          Se trata de una plataforma digital que se encarga de cobrar las
          propinas con tarjetas de crédito y débito para repartirlas entre el
          staff del comercio.
        </p>
      </Card>
      <HorizontalBox>
        <Card smallTitle='El Comercio' whatIs className='stepsContainer'>
          <div className='whatIsdivSteps'>
            <p>
              <span className='whatIsNumber'>1.</span> Se tiene que adherir a{' '}
              <span className='whatIsBold'>Tipie</span>.
            </p>
            <p>
              <span className='whatIsNumber'>2.</span>
              Ingresar al Panel de Control con su usuario.
            </p>
            <p>
              <span className='whatIsNumber'>3.</span>
              Configurar el modo de repartir las propinas.
            </p>
            <p>¡Todo listo! A empezar a operar desde el Panel.</p>
          </div>
        </Card>
        <Card smallTitle='Los Colaboradores' whatIs className='stepsContainer'>
          <p className='whatIsPreListTitle'>
            <span className='whatIsBoldTitle'>
              Son todas las personas que van a recibir propinas.
            </span>
          </p>
          <div className='whatIsdivSteps'>
            <p>
              <span className='whatIsNumber'>1.</span>
              Se tienen que descargar <span className='whatIsBold'>
                Tipie
              </span>{' '}
              en su celular.
              <br />
              <span className='available'>Disponible para iOS & Android</span>
            </p>
            <p>
              <span className='whatIsNumber'>2.</span>
              Crearse un perfil.
            </p>
            <p>
              <span className='whatIsNumber'>3.</span>
              Vincularse al comercio adherido a Tipie.
            </p>
            <p>¡Y listo! A disfrutar.</p>
          </div>
        </Card>
      </HorizontalBox>
    </div>
  );
}
