/* eslint-disable default-case */
import { useState, useEffect } from 'react';
import TopNavBar from '../../components/TopNavBar';
import SideBar from '../../components/Sidebar';
import './style.css';
import turnosLogo from '../../assets/nuevo-icono-turnos.svg';
import sectoresLogo from '../../assets/nuevo-icono-sectores.svg';
import datosLogo from '../../assets/nuevo-icono-datos.svg';
import TurnosYSectores from '../../components/ConfiguracionComponents/TurnosYSectores';
import DatosConfig from '../../components/ConfiguracionComponents/DatosConfig';
import CommerceMenu from '../../components/CommerceDropdown';
import { AbstractService } from '../../services/abstractService';
import { useHistory } from 'react-router-dom';

export default function Configuracion({ content }) {
  const [commerceData, setConfigCommerceData] = useState(false);
  const [sectorsData, setConfigsectorsData] = useState(false);
  const [shiftsData, setConfigshiftsData] = useState(false);

  const [dataProfile, setDataProfile] = useState({});
  const [commerceName, setCommerceName] = useState('');
  const [arrayShiftsData, setArrayShiftsData] = useState([]);
  const [arraySectorsData, setArraySectorsData] = useState([]);

  const [activeShift, setConfigactiveShift] = useState([]);

  const history = useHistory();

  useEffect(() => {
    const dataProfile = content;
    setDataProfile(dataProfile);
    localStorage.setItem('commerceData', JSON.stringify(dataProfile));

    const commerceName = dataProfile[0].commerceData.name;
    const commerceAddress = `${dataProfile[0].commerceData.addresses?.street} ${dataProfile[0].commerceData.addresses?.city} ${dataProfile[0].commerceData.addresses?.zip}`;

    setCommerceName(commerceName);
    localStorage.setItem('commerceName', commerceName);
    localStorage.setItem('commerceAddress', commerceAddress);
    const shiftsAccepted =
      dataProfile[0].commerceData?.commerceAcceptedBlobs?.shifts_accepted;
    const sectorsAccepted =
      dataProfile[0].commerceData?.commerceAcceptedBlobs?.sectors_accepted;

    localStorage.setItem('shiftsAcceptedBlob', JSON.stringify(shiftsAccepted));
    localStorage.setItem(
      'sectorsAcceptedBlob',
      JSON.stringify(sectorsAccepted),
    );

    localStorage.setItem(
      'activeShift',
      JSON.stringify(dataProfile[0].activeShift[0]),
    );

    const arrayShifts = dataProfile[0].shiftsData;
    setArrayShiftsData(arrayShifts);
    localStorage.setItem('shift_config', JSON.stringify(arrayShifts));
    const arraySectors = dataProfile[0].sectorsData;
    setArraySectorsData(arraySectors);
    localStorage.setItem('sector_config', JSON.stringify(arraySectors));
  }, [content]);

  const handleGoBack = () => {
    if (shiftsData || sectorsData || commerceData || activeShift) {
      setConfigCommerceData(false);
      setConfigshiftsData(false);
      setConfigsectorsData(false);
      setConfigactiveShift(false);
    }
  };

  const handleSeeMore = (n) => {
    switch (n) {
      case 'shiftsData':
        setConfigshiftsData(true);
        setConfigsectorsData(false);
        setConfigCommerceData(false);
        break;
      case 'sectorsData':
        setConfigsectorsData(true);
        setConfigshiftsData(false);
        setConfigCommerceData(false);
        break;
      case 'commerceData':
        setConfigCommerceData(true);
        setConfigsectorsData(false);
        setConfigshiftsData(false);
        break;
    }
  };

  const handleFilterArray = (value, id) => {
    if (value === 'shiftsData') {
      const newArray = arrayShiftsData.filter((x) => x.id !== id);
      setConfigshiftsData(newArray);
      setArrayShiftsData(newArray);
      // guardar en storage **
      localStorage.setItem('shift_config', JSON.stringify(newArray));
    } else if (value === 'sectorsData') {
      const newArray = arraySectorsData.filter((x) => x.id !== id);
      setConfigsectorsData(newArray);
      setArraySectorsData(newArray);
      localStorage.setItem('sector_config', JSON.stringify(newArray));
      // guardar en storage **
    }
  };

  const handleAddArray = (value, arg) => {
    if (value === 'sectorsData') {
      const arrayIds = [];
      arraySectorsData.forEach((x) => arrayIds.push(parseInt(x.id)));

      const maxId = Math.max(...arrayIds);
      const newId = maxId + 1;

      const sectorToAdd = {
        id: newId,
        // label: arg.label,
        // value: parseInt(arg.value),
        name: arg.name,
        percentage: arg.percentage,
      };
      const newArray = [...arraySectorsData, sectorToAdd];
      localStorage.setItem('sector_config', JSON.stringify(newArray));
      arraySectorsData.push(sectorToAdd);
    } else if (value === 'shiftsData') {
      const arrayIds = [];
      arrayShiftsData.forEach((x) => arrayIds.push(parseInt(x.id)));

      const maxId = Math.max(...arrayIds);
      const newId = maxId + 1;

      const shiftToAdd = {
        id: newId,
        // label: arg.label,
        name: arg.name,
        from_hour: arg.from_hour,
        to_hour: arg.to_hour,
      };
      const newArray = [...arrayShiftsData, shiftToAdd];
      setConfigshiftsData(newArray);
      setArrayShiftsData(newArray);
      localStorage.setItem('shift_config', JSON.stringify(newArray));
    }
  };

  const pushUpdateToBack = async (data) => {
    try {
      await AbstractService({
        url: `${process.env.REACT_APP_BFF_WEB_URL}/commerce/sectors/update`,
        method: 'PATCH',
        data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('loggedUser')}`,
          'Content-Type': 'application/json',
        },
      });
    } catch (err) {}
  };

  const handleChange = (value, changeObj) => {
    switch (value) {
      case 'editShift':
        const indexArrayTurnos = arrayShiftsData.findIndex(
          (x) => x.id === changeObj.id,
        );
        const arrayToChangeTurnos = arrayShiftsData;
        arrayToChangeTurnos[indexArrayTurnos].from_hour = changeObj.from_hour;
        arrayToChangeTurnos[indexArrayTurnos].to_hour = changeObj.to_hour;
        // arrayToChangeTurnos[indexArrayTurnos].description = changeObj.description
        setConfigsectorsData(arrayToChangeTurnos);
        break;
      case 'editSector':
        const indexArray = arraySectorsData.findIndex(
          (x) => x.id === changeObj.id,
        );
        const arrayToChange = arraySectorsData;
        // arrayToChange[indexArray].value = changeObj.value;
        arrayToChange[indexArray].percentage = changeObj.value;
        setConfigsectorsData(arrayToChange);
        break;
      case 'editSectorName':
        setConfigsectorsData([{ id: changeObj.id, name: changeObj.name }]);

        // Updating on render
        setArraySectorsData((A) =>
          A.map((sector) => {
            if (sector.id === changeObj.id) {
              return { ...sector, name: changeObj.name };
            }
            return sector;
          }),
        );

        // Saving to backend
        pushUpdateToBack([{ id: changeObj.id, name: changeObj.name }]);

        break;
      default:
        throw new Error('Input inválido!');
    }
  };

  const handleNavigation = (path) => {
    history.push(path);
  };

  return (
    <div className='configuracionDiv'>
      <TopNavBar>
        <CommerceMenu>
          <ion-icon name='person-outline' className='userIcon' />
          <h4 className='topNavBarChildrenTitle'>{commerceName}</h4>
          <ion-icon name='chevron-down-outline' className='arrowDownIcon' />
        </CommerceMenu>
      </TopNavBar>
      <div className='mainContentConfiguracionDiv'>
        <SideBar />

        {shiftsData ? (
          <TurnosYSectores
            shiftsData
            activeShift={dataProfile[0].activeShift[0]}
            handleGoBack={handleGoBack}
            array={arrayShiftsData}
            handleFilterArray={handleFilterArray}
            handleAddArray={handleAddArray}
            handleChange={handleChange}
            preconfigShifts={
              dataProfile[0].commerceData?.commerceAcceptedBlobs
                ?.shifts_accepted
            }
          />
        ) : sectorsData ? (
          <TurnosYSectores
            sectorsData
            activeShift={dataProfile[0].activeShift[0]}
            handleGoBack={handleGoBack}
            array={arraySectorsData}
            handleFilterArray={handleFilterArray}
            handleAddArray={handleAddArray}
            handleChange={handleChange}
            preconfigSectors={
              dataProfile[0].commerceData?.commerceAcceptedBlobs
                ?.sectors_accepted
            }
          />
        ) : (
          <div className='configuracionCardContainerDiv'>
            <div className='configuracionHeader'>
              <h1 className='configuracionTitle'>Configuración</h1>
            </div>

            <div className='configuracionCenterDiv'>
              <div className='configuracionDatosDiv'>
                <div className='configuracionTitleDiv'>
                  <h2 className='configuracionCenterTitle'>Turnos</h2>
                  <p className='configuracionCenterSubtitle'>
                    Modificá los horarios de los distintos turnos.
                  </p>
                </div>
                <div className='configuracionImgDiv'>
                  <img
                    src={turnosLogo}
                    alt='Turnos'
                    className='configuracionCenterImg'
                  />
                  <p
                    className='configuracionVerMas'
                    // onClick={() => handleSeeMore('shiftsData')}
                    onClick={() => handleNavigation('/configuracion/turnos')}
                  >
                    Ver más
                  </p>
                </div>
              </div>

              <div className='configuracionDatosDiv'>
                <div className='configuracionTitleDiv'>
                  <h2 className='configuracionCenterTitle'>Sectores</h2>
                  <p className='configuracionCenterSubtitle'>
                    Modificá los porcentajes de los distintos sectores.
                  </p>
                </div>
                <div className='configuracionImgDiv'>
                  <img
                    src={sectoresLogo}
                    alt='Sectores'
                    className='configuracionCenterImg'
                  />
                  <p
                    className='configuracionVerMas'
                    // onClick={() => handleSeeMore('sectorsData')}
                    onClick={() => handleNavigation('/configuracion/sectores')}
                  >
                    Ver más
                  </p>
                </div>
              </div>

              <div className='configuracionDatosDiv'>
                <div className='configuracionTitleDiv'>
                  <h2 className='configuracionCenterTitle'>Datos</h2>
                  <p className='configuracionCenterSubtitle'>Tu comercio.</p>
                </div>
                <div className='configuracionImgDiv'>
                  <img
                    src={datosLogo}
                    alt='Datos'
                    className='configuracionCenterImg'
                  />
                  <p
                    className='configuracionVerMas'
                    onClick={() => handleNavigation('/configuracion/datos')}
                  >
                    Ver más
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
