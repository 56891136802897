import React from 'react';
import Loading from '../../components/Loading';
import { AbstractService } from '../../services/abstractService';
import Unauthorized from '../../hoc/unauthorized';
import ErrorHoc from '../../components/ErrorMessage';
import Colaboradores from './Colaboradores';

export default function ColaboradoresIndex() {
  const [configApiUsersEmployees, setConfigApiUsersEmployees] =
    React.useState(null);
  const [responseApiUsersEmployees, setResponseApiUsersEmployees] =
    React.useState([]);

  const [configCommerceSectors, setConfigCommerceSectors] = React.useState();
  const [responseCommerceSectors, setResponseCommerceSectors] =
    React.useState('');

  const [configCommerceShifts, setConfigCommerceShifts] = React.useState();
  const [responseCommerceShifts, setResponseCommerceShifts] =
    React.useState('');

  const [loading, setLoading] = React.useState(true);
  const [hasError, setHasError] = React.useState(false);
  const [unauthorized, setUnauthorized] = React.useState(false);

  if (!configApiUsersEmployees) {
    const user = localStorage.getItem('loggedUser');
    if (!user) {
      // Unauthorized
      setUnauthorized(true);
      setHasError(false);
      setLoading(false);
    }

    setConfigApiUsersEmployees({
      url: `${process.env.REACT_APP_BFF_WEB_URL}/employees/search`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user}`,
        'Content-Type': 'application/json',
      },
    });

    setConfigCommerceSectors({
      url: `${process.env.REACT_APP_BFF_WEB_URL}/commerce/sectors/list`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user}`,
        'Content-Type': 'application/json',
      },
    });

    setConfigCommerceShifts({
      url: `${process.env.REACT_APP_BFF_WEB_URL}/commerce/shifts/list`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user}`,
        'Content-Type': 'application/json',
      },
    });
  }

  React.useEffect(() => {
    if (
      configApiUsersEmployees &&
      configCommerceSectors &&
      configCommerceShifts
    ) {
      const promiseApiUsersEmployees = AbstractService(configApiUsersEmployees)
        .then((res) => ({ employeesList: res }))
        .catch((err) => {
          setHasError(true);
        });

      const promiseApiUsersSectors = AbstractService(configCommerceSectors)
        .then((res) => ({ sectorsData: res }))
        .catch((err) => {
          setHasError(true);
        });

      const promiseApiUsersShifts = AbstractService(configCommerceShifts)
        .then((res) => ({ shiftsData: res }))
        .catch((err) => {
          setHasError(true);
        });

      Promise.all([
        promiseApiUsersEmployees,
        promiseApiUsersSectors,
        promiseApiUsersShifts,
      ])
        .then((responses) => {
          responses.forEach((res) => {
            if (res?.employeesList) {
              setResponseApiUsersEmployees(res.employeesList);
            } else if (res?.sectorsData) {
              setResponseCommerceSectors(res.sectorsData);
            } else if (res?.shiftsData) {
              setResponseCommerceShifts(res.shiftsData);
            }
          });
          setLoading(false);
        })
        .catch((err) => {
          setHasError(true);
          setLoading(false);
        });
    }
  }, [configApiUsersEmployees, configCommerceSectors, configCommerceShifts]);

  if (unauthorized) {
    return <Unauthorized />;
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : hasError ? (
        <ErrorHoc views />
      ) : (
        <Colaboradores
          content={[
            {
              employeesList: responseApiUsersEmployees,
              sectorsData: responseCommerceSectors,
              shiftsData: responseCommerceShifts,
            },
          ]}
        />
      )}
    </>
  );
}
