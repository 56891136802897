import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import Logo from '../../assets/tipie-vectores-01.png';
import PlaceholderLogo from '../../assets/tipie-vectores-02.png';
import SecondLogo from '../../assets/faq-commerces.png';
import Loading from '../../components/Loading';
import { AbstractService } from '../../services/abstractService';
import ResultCheckout from '../../components/AddTipQR/resultCheckout';

import './style.css';

export default function AddTipQR({ content }) {
  const history = useHistory();

  const { id } = useParams();

  const [commerceName, setCommerceName] = React.useState('');
  const [commerceAddress, setCommerceAddress] = React.useState('');
  const [commerceZip, setCommerceZip] = React.useState('');
  const [commerceProfilePic, setCommerceProfilePic] = React.useState('');
  const [commerceIsActive, setCommerceIsActive] = React.useState(false);

  const [config, setConfig] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [hasError, setHasError] = React.useState(false);

  if (!config) {
    setConfig({
      url: `${process.env.REACT_APP_BFF_WEB_URL}/profile/commerce/${id}`,
      method: 'GET',
    });
  }

  React.useEffect(() => {
    if (config) {
      AbstractService(config)
        .then((res) => {
          setCommerceIsActive(res.active_shift);
          setCommerceZip(res.addresses.zip);
          setCommerceAddress(`${res.addresses.street} ${res.addresses.label}`);
          setCommerceName(res.name);
          setCommerceProfilePic(res.profile_photo);
          setLoading(false);
          setHasError(false);
        })
        .catch((err) => {
          setLoading(false);
          setHasError(true);
        });
    }
  }, [config]);

  const handleConfirm = () => {
    setHasError(false);
    history.push('/payment/form', { commerce_id: id });
  };

  if (hasError) {
    setHasError(false);
    return <ResultCheckout success={false} />;
  }
  if (loading && !commerceName && !commerceAddress) {
    return <Loading />;
  }

  return (
    <div className='QRMainDiv'>
      <header className='QRHeaderDiv'>
        <h1 className='QRMainTitle'>Propina digital</h1>

        <p className='QRMainSubtitle'>
          ¡Hola! Estás por dejar tu propina con tarjeta de débito o crédito
        </p>
      </header>

      <div className='QRSecondaryDiv'>
        <div className='QRCardDiv'>
          <div className='QRCardHeader'>
            <img src={SecondLogo} className='QRSecondaryLogo' />
            <p className='QRCommerceTitle'>Comercio</p>
          </div>

          <Divider className='QRDivider' />

          <div className='QRCardContent'>
            {commerceProfilePic ? (
              <img src={commerceProfilePic} className='QRPlaceholderLogo' />
            ) : (
              <img src={PlaceholderLogo} className='QRPlaceholderLogo' />
            )}
            <div className='QRCardTextDiv'>
              <p className='QRCardTextPrimary'>{commerceName}</p>
              <p className='QRAddCardTextSecondary'>
                {commerceAddress} - CP {commerceZip}
              </p>
            </div>
          </div>
        </div>
      </div>

      <footer className='QRFooter'>
        {commerceName.length > 1 && commerceIsActive ? (
          <button className='QRConfirm' onClick={handleConfirm}>
            Confirmar
          </button>
        ) : (
          <p
            style={{
              width: '80%',
              color: '#440927',
              fontWeight: 500,
              textAlign: 'center',
            }}
          >
            El comercio no tiene turnos abiertos. Consultar con el mismo.
          </p>
        )}
        <br />
        <button className='QRCancel'>Cancelar</button>

        <img src={Logo} className='QRPrimaryLogo' />
      </footer>
    </div>
  );
}
