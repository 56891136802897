import React from 'react';

import './style.css';
import NativeSelect from '@mui/material/NativeSelect';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@mui/icons-material/Close';

import ModalActionBar from '../../ModalActionBar';
import ProfilePic from '../../ProfilePic';
import SuccessModal from '../SuccessModal';

import { AbstractService } from '../../../services/abstractService';

export default function ApprovalColaboradoresModal(props) {
  const [display, setDisplay] = React.useState('none');
  const [selectedSector, setSelectedSector] = React.useState(null);
  const [selectedShift, setSelectedShift] = React.useState(null);
  const [disable, setDisable] = React.useState(true);
  const [hasError, setHasError] = React.useState(false);
  const [successColaborador, setSuccessColaborador] = React.useState(false);
  const [showLoading, setShowLoading] = React.useState(false);

  const commerceData = JSON.parse(localStorage.getItem('commerce_data'));

  const modalRef = React.useRef();

  const scrollBackToTop = () => {
    modalRef.current.scrollTop = 0;
  };

  const handleDisplay = () => {
    scrollBackToTop();
    setDisplay('none');
    setSelectedShift('');
    setSelectedSector('');
    props.dismiss();
  };

  React.useEffect(() => {
    if (props.visible) {
      setDisplay('block');
    }
  }, [props.visible]);

  // React.useEffect(() => {
  //   if (sector || shift) {
  //     setDisable(false);
  //   } else {
  //     setDisable(true);
  //   }
  // }, [sector, shift]);

  const handleChange = (e, value) => {
    switch (value) {
      case 'sector':
        setSelectedSector(e.target.value);
        break;
      case 'shift':
        setSelectedShift(e.target.value);
        break;
      default:
        throw new Error('Input inválido');
    }
  };

  const handleDismissSuccessColaborador = () => {
    setSuccessColaborador(false);
  };

  const handleSubmit = async () => {
    setShowLoading(true);

    const body = {
      shift_id: !selectedShift ? commerceData.shifts[0].id : selectedShift,
      sector_id: !selectedSector ? commerceData.sectors[0].id : selectedSector,
      status: 'APPROVED',
    };

    const { employeeId } = props;

    const editEmployeeData = {
      url: `${process.env.REACT_APP_BFF_WEB_URL}/employees/${employeeId}`,
      method: 'PATCH',
      data: body,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('loggedUser')}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      setHasError(false);
      await AbstractService(editEmployeeData);
      setShowLoading(false);
      props.handleAccept();
    } catch (error) {
      setShowLoading(false);
      setHasError(true);
    }
    setShowLoading(false);
    setSuccessColaborador(true);
  };

  React.useEffect(() => {
    if (props.display) {
      setDisplay('block');
    }
  }, [props.display]);

  const useStyles = makeStyles(() => ({
    select: {
      '&.MuiInput-underline:after': {
        borderBottomColor: '#E98A15',
      },
    },
  }));

  const classes = useStyles();

  return (
    <div
      style={{
        display,
        position: 'fixed',
        zIndex: 22,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        overflow: 'auto',
        backgroundColor: 'rgb(0,0,0)',
        backgroundColor: 'rgba(0,0,0,0.4)',
      }}
      onClick={handleDisplay}
    >
      <div
        className='modalApprovalColaboradorContent'
        ref={modalRef}
        onClick={(e) => {
          // do not close modal if anything inside modal content is clicked
          e.stopPropagation();
        }}
      >
        <button className='ColaboradorCloseBtn' onClick={handleDisplay}>
          <CloseIcon />
        </button>

        <div className='employeeProfile'>
          <div
            style={{ height: '100%', display: 'flex', alignItems: 'center' }}
          >
            <ProfilePic name={`${props.name[0]}${props.lastName[0]}`} />
          </div>

          <div className='employeeData'>
            <h4 className='colaboradorName'>
              {props.name} {props.lastName}
            </h4>
            <h4 className='colaboradorDoc'>{`CUIL: ${props.doc ?? '-'}`}</h4>
          </div>
        </div>

        <div className='modalApprovalColaboradorDivInput'>
          <form className='modalApprovalColaboradorForm'>
            <label className='modalApprovalLabel' id='turno'>
              Turno
            </label>

            <NativeSelect
              inputProps={{
                name: 'Turno',
                id: 'uncontrolled-native',
              }}
              onChange={(e) => handleChange(e, 'shift')}
              id='turno'
              className={`${classes.select} modalApprovalSelectForm`}
            >
              {commerceData.shifts.map((shift, i) => {
                return (
                  <option value={shift.id} key={i}>
                    {shift.name} ({shift.from_hour} - {shift.to_hour} hs)
                  </option>
                );
              })}
            </NativeSelect>

            <label
              className='modalApprovalLabel modalApprovalLabelSector'
              id='sector'
            >
              Sector
            </label>

            <NativeSelect
              defaultValue='Seleccioná su sector'
              onChange={(e) => handleChange(e, 'sector')}
              id='sector'
              className={`${classes.select} modalApprovalSelectForm`}
            >
              {commerceData.sectors.map((sector, i) => {
                return (
                  <option value={sector.id} key={i}>
                    {sector.name} ({sector.percentage}%)
                  </option>
                );
              })}
            </NativeSelect>
          </form>

          <ModalActionBar
            onPrimaryClick={handleSubmit}
            // primaryDisabled={disable}
            onSecondaryClick={handleDisplay}
            secondaryClass='borderless'
            loading={showLoading}
          />
        </div>
        <SuccessModal
          visible={successColaborador}
          dismiss={handleDismissSuccessColaborador}
          dismissPrimaryModal={props.dismiss}
          title='Colaborador confirmado'
          button='Continuar'
        />
      </div>
    </div>
  );
}
