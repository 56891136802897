import * as React from 'react';
import './styles.css';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Loading from '../Loading';

export default function SelectShiftDropdown(props) {
  const [loading, setLoading] = React.useState(true);

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(1),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      borderBottom: '1px solid #00000029',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#00000029',
        boxShadow: '0 0 0 0.2rem #00000029',
      },
    },
  }));

  const handleChange = (event) => {
    props.handleActiveShift(event.target.value);
  };

  React.useEffect(() => {
    if (props.shifts) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='selectShiftDropdownDiv'>
      <FormControl fullWidth>
        <InputLabel id='demo-simple-select-label' style={{ color: '#6A6A6A' }}>
          Seleccionar turno
        </InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={props.activeShift}
          label='Elegí un turno'
          onChange={handleChange}
          input={<BootstrapInput />}
        >
          {props.shifts.map((x) => (
            <MenuItem key={x.id} value={x}>
              {x.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
