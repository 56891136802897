/* eslint-disable default-case */
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import TopNavBar from '../../../components/TopNavBar';
import SideBar from '../../../components/Sidebar';
import CommerceMenu from '../../../components/CommerceDropdown';
import './style.css';
import Loading from '../../../components/Loading';
import DatosConfig from '../../../components/ConfiguracionComponents/DatosConfig';

export default function DatosView({ params }) {
  const [commerceName, setCommerceName] = useState('');
  const [commerceDataProfile, setCommerceDataProfile] = useState('');

  const history = useHistory();

  useEffect(() => {
    const commerceN = localStorage.getItem('commerceName');
    setCommerceName(commerceN);

    const commerceD = JSON.parse(localStorage.getItem('commerceData'));
    setCommerceDataProfile(commerceD);
  }, []);

  const handleGoBack = () => {
    history.push('/configuracion');
  };

  if (!commerceName || !commerceDataProfile) {
    return <Loading />;
  }

  return (
    <div className='configuracionDiv'>
      <TopNavBar>
        <CommerceMenu>
          <ion-icon name='person-outline' className='userIcon' />
          <h4 className='topNavBarChildrenTitle'>{commerceName}</h4>
          <ion-icon name='chevron-down-outline' className='arrowDownIcon' />
        </CommerceMenu>
      </TopNavBar>
      <div className='mainContentConfiguracionDiv'>
        <SideBar />

        <DatosConfig
          handleGoBack={handleGoBack}
          data={commerceDataProfile[0].commerceData}
          commerceName={commerceName}
        />
      </div>
    </div>
  );
}
