import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import './style.css';

import LandingHeader from './sections/Header';
import RegisterCommerce from './sections/RegisterCommerce';
import Hero from './sections/Hero';
import WhatIs from './sections/WhatIs';
import Faq from './sections/Faq';
import GetStarted from './sections/GetStarted';
import LandingFooter from './sections/Footer';
import Loading from '../../components/Loading';
import { AbstractService } from '../../services/abstractService';

export default function LandingPage() {
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const toggleRegisterModal = () => setShowRegisterModal(!showRegisterModal);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const user = localStorage.getItem('loggedUser');

      if (user) {
        const config = {
          url: `${process.env.REACT_APP_BFF_WEB_URL}/token/active`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${user}`,
            'Content-Type': 'application/json',
          },
        };

        try {
          await AbstractService(config);
          setRedirect(true);
        } catch (error) {
          localStorage.removeItem('loggedUser');
        }
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) {
    return <Loading />;
  }
  if (redirect) {
    return <Redirect to='/home' />;
  }

  return (
    <main className='landingMain'>
      <RegisterCommerce
        open={showRegisterModal}
        onClose={toggleRegisterModal}
      />
      <div className='firstSectionBack'>
        <LandingHeader register={toggleRegisterModal} />
        <Hero register={toggleRegisterModal} />
        <WhatIs />
      </div>
      <Faq />
      <GetStarted register={toggleRegisterModal} />
      <LandingFooter />
    </main>
  );
}
