import React from 'react';
import { useHistory } from 'react-router-dom';
import Logo from '../../../assets/tipie-vectores-01.png';
import Success from '../../../assets/tipProcessing2.png';
import Error from '../../../assets/error_img.png';

import './style.css';

export default function ResultCheckout({ success }) {
  const history = useHistory();

  const handleNavigation = () => {
    history.push('/');
  };

  return (
    <div className='QRMainDiv'>
      <header className='QRHeaderDiv'>
        <h1 className='QRResultMainTitle'>
          {success ? 'Propina exitosa' : 'Error'}
        </h1>

        {success ? (
          <h1 className='QRResultMainTitle'>¡Muchas gracias!</h1>
        ) : null}

        {!success ? (
          <p className='QRResultMainSubtitle'>
            Intente nuevamente en unos minutos
          </p>
        ) : null}
      </header>

      <div className='QRResultImgDiv'>
        <img
          alt='Logo'
          src={success ? Success : Error}
          className='QRResultLogo'
        />
      </div>

      <footer className='QRFooter'>
        <button
          type='button'
          className='QRResultConfirm'
          onClick={handleNavigation}
        >
          Aceptar
        </button>
        <img alt='Logo' src={Logo} className='QRPrimaryLogo' />
      </footer>
    </div>
  );
}
