import * as React from 'react';
import { MenuItem, Dialog } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ModalActionBar from '../ModalActionBar';

function ConfirmLogout(props) {
  return (
    <Dialog open={props.open} onClose={props.onCancel}>
      <div style={{ padding: '25px' }}>
        <p>
          Hay un turno abierto en el local, y debe ser cerrado manualmente.
          ¿Querés cerrar sesión de todas formas?
        </p>
        <ModalActionBar
          primaryLabel='Cerrar sesión'
          onPrimaryClick={props.onConfirm}
          onSecondaryClick={props.onCancel}
        />
      </div>
    </Dialog>
  );
}

export default function LogoutItem(props) {
  const [showModal, setShowModal] = React.useState(false);
  const closeModal = () => {
    setShowModal(false);
    props.setMenu(null);
  };

  const history = useHistory();

  const isShiftOpen = () => {
    // TODO: logic
    return true;
  };

  const logout = () => {
    localStorage.removeItem('loggedUser');
    localStorage.removeItem('username');
    localStorage.removeItem('shift_config');
    localStorage.removeItem('updateEmployees');
    localStorage.removeItem('shift_editedData');
    localStorage.removeItem('commerceData');
    localStorage.removeItem('commerceName');
    localStorage.removeItem('commerceAddress');
    localStorage.removeItem('shiftsAcceptedBlob');
    localStorage.removeItem('sectorsAcceptedBlob');
    localStorage.removeItem('active_shift');
    localStorage.removeItem('sector_config');
    localStorage.removeItem('commerce_data');
    localStorage.removeItem('commerce_config');
    localStorage.removeItem('commerce_id');
    localStorage.removeItem('showModifyPercentages');
    localStorage.removeItem('userdata');
    localStorage.removeItem('shift_config');
    localStorage.removeItem('activeShift');
    localStorage.removeItem('updateEmployeesLength');
    history.push('/login');
  };

  const handleLogout = () => {
    if (isShiftOpen()) {
      setShowModal(true);
    } else {
      logout();
    }
  };

  React.useEffect(() => {
    if (!showModal) {
      return;
    }

    if (props.activeShift) {
      props.setMenu(
        <ConfirmLogout
          open={showModal}
          onCancel={closeModal}
          onConfirm={logout}
        />,
      );
    } else {
      logout();
    }
  }, [showModal]);

  return <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>;
}
