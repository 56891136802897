import { useState } from 'react';
import UsersContext from './index';
import { AbstractService } from '../../services/abstractService';

export default function UsersProvider({ children }) {
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const getUser = async (body) => {
    try {
      setIsLoading(true);
      setHasError(false);
      setErrorMessage('');
      // Acá tendría que hacer un get buscando al usuario que le llega por paráemtro
      const loginResult = await AbstractService({
        url: `${process.env.REACT_APP_BFF_WEB_URL}/users/login`,
        method: 'POST',
        body,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });
      // setUser(loginResult[0]);
    } catch (error) {
      setUser({});
      setHasError(true);
      setErrorMessage('Algo ha pasado, verifica tu conexión');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <UsersContext.Provider
      value={{
        user,
        getUser,
        isLoading,
        hasError,
        errorMessage,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
}
