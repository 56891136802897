import React from 'react';
import moment from 'moment-timezone';
import numbro from 'numbro';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Skeleton from '@mui/material/Skeleton';

import OpsTable from './OpsTable';

import iconClosed from '../../assets/historial-shift-closed.svg';
import iconOpen from '../../assets/historial-shift-open.svg';

import './style.css';

const styles = {
  accordion: {
    shadowOffset: { width: 0, height: 2 },
    shadowColor: '#8f8f8f',
    shadowOpacity: 0.6,
    shadowRadius: 3,
    borderWidth: 0,
  },
  accordionSummary: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  accordionDetails: {
    backgroundColor: '#F7F7F7',
  },
};

function AccordionSkeleton(props) {
  const totalElements = props.boxes ?? 5;
  const arr = [];
  for (let i = 0; i < totalElements; i++) {
    arr.push(i);
  }

  return (
    <div
      style={{
        // height: '250px',
        width: '100%',
      }}
    >
      {arr.map((i) => {
        return <Skeleton key={i} width='80%' height='2em' />;
      })}
    </div>
  );
}

export default function HistorialAccordion(props) {
  const { shift } = props;

  const [isOpen, setIsOpen] = React.useState(false);
  const [opsList, setOpsList] = React.useState([]);

  const toggleAccordion = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      // run the shift thingy
      shift.fetchOps(setOpsList);
      setIsOpen(true);
    }
  };

  React.useEffect(() => {
    // To be run when the component is unmounted
    return () => {
      shift.abortLoad();
    };
  }, []);

  const shiftDate = moment(shift.timestamp).tz('America/Buenos_Aires');
  const shiftAmount = numbro(shift.totalAmount)
    .formatCurrency({
      mantissa: 2,
      optionalMantissa: true,
      thousandSeparated: true,
      currencyPosition: 'prefix',
      spaceSeparated: true,
    })
    .replace('.', '#')
    .replace(',', '.')
    .replace('#', ',');

  const capitalizeFirst = (str) => {
    const initial = str.charAt(0).toUpperCase();
    const rest = str.slice(1, str.length);
    return `${initial}${rest}`;
  };

  const monthYear = capitalizeFirst(shiftDate.format('MMMM YYYY'));

  const day = capitalizeFirst(shiftDate.format('dddd D'));

  return (
    <Accordion
      expanded={isOpen}
      onChange={toggleAccordion}
      style={styles.accordion}
    >
      <AccordionSummary
        aria-controls='panel1a-content'
        style={styles.accordionSummary}
      >
        <div className='historialAccordionDivTitle'>
          <p className='historialAccordionShift'>{shift.shiftName}</p>
          <div className='historialAccordionDateDiv'>
            <p className='historialAccordionDateOne'>{monthYear}</p>
            <p className='historialAccordionDateTwo'>{day}</p>
          </div>
          <div className='historialAccordionDateDiv'>
            <p className='historialAccordionDateOne'>Monto Total</p>
            <p className='historialAccordionTotalText'>{shiftAmount}</p>
          </div>
          <div className='historialAccordionTotal'>
            <p className='historialAccordionDateOne'>Turno</p>
            <p className='historialAccordionDateTwo'>
              {shift.isActive ? 'En curso' : 'Cerrado'}
            </p>
          </div>
          <div className='historialAccordionIcon'>
            <img
              alt='Estado del turno'
              src={shift.isActive ? iconOpen : iconClosed}
            />
          </div>
        </div>
      </AccordionSummary>

      <AccordionDetails>
        {opsList.length === 0 ? (
          <AccordionSkeleton />
        ) : (
          <OpsTable opsList={opsList} />
        )}
      </AccordionDetails>
    </Accordion>
  );
}
