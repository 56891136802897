import React from 'react';
import Pagination from '@mui/material/Pagination';
import Loading from '../../components/Loading';
import HistorialAccordion from '../../components/HistorialAccordion';

export default function ShiftHistory(props) {
  const { history } = props;
  const [activePage, setActivePage] = React.useState(1);

  const shiftsToDisplay = React.useMemo(() => {
    return (history ?? []).filter((shift) => shift.key !== 0 || (shift.shiftId && shift.totalAmount !== undefined));
  }, [history]);

  const shiftsPerPage = 5;
  const totalShifts = React.useMemo(() => shiftsToDisplay.length, [shiftsToDisplay]);
  const pageCount = React.useMemo(() => Math.ceil(totalShifts / shiftsPerPage), [totalShifts]);

  React.useEffect(() => {
    setActivePage(1);
  }, [pageCount]);

  const handleSetPage = (event, value) => setActivePage(value);

  if (!history) {
    return <Loading />;
  }

  return (
    <div className='historialCardContainerDiv'>
      <div className='historialHeader'>
        <h1 className='historialTitle'>Historial</h1>
        <p className='historialParagraph'>Acá vas a encontrar el detalle de los movimientos realizados.</p>
      </div>
      {history.length !== 0 && history[0].shiftId ? (
        <div className='historialMainDiv'>
          <div className='historialDivTable'>
            {shiftsToDisplay.slice((activePage - 1) * shiftsPerPage, activePage * shiftsPerPage).map((shift) => (
              <HistorialAccordion key={shift.key} shift={shift} />
            ))}
          </div>
          <Pagination style={{ paddingBottom: '2%' }} count={pageCount} page={activePage} onChange={handleSetPage} />
        </div>
      ) : (
        <h3 className='historialNoMovements'>Todavía no hay movimientos.</h3>
      )}
    </div>
  );
}
