/* eslint-disable default-case */
import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import TopNavBar from '../../../components/TopNavBar';
import SideBar from '../../../components/Sidebar';
import CommerceMenu from '../../../components/CommerceDropdown';

import SectoresModal from '../../../components/Modal/ConfiguracionModal/Sectores';
import SectorPercentagesModal from '../../../components/Modal/ConfiguracionModal/SectorPercentages';
import SuccessModal from '../../../components/Modal/SuccessModal';
import FinishModal from '../../../components/Modal/FinishModal';
import DeleteSectorTurnoModal from '../../../components/Modal/ConfiguracionModal/DeleteSectorTurno';
import AddSectorTurnoModal from '../../../components/Modal/ConfiguracionModal/AddSectorShift';
import ConfirmSectorTurnoModal from '../../../components/Modal/ConfiguracionModal/AddSectorShift/ConfirmSectorShift';
import ErrorPercentageModal from '../../../components/Modal/ConfiguracionModal/Sectores/ErrorPercentage';
import PreconfigShiftSectorModal from '../../../components/Modal/ConfiguracionModal/preconfigShiftsSectors';
import ErrorModal from '../../../components/Modal/ErrorModal';

import { AbstractService } from '../../../services/abstractService';

import './style.css';
import Loading from '../../../components/Loading';

export default function SectoresView({ props }) {
  const history = useHistory();
  const [commerceName, setCommerceName] = useState('');

  const [newPercentages, setNewPercentages] = useState([]);
  const [arraySectors, setArraySectors] = useState({});

  const [visibleSectores, setVisibleSectores] = useState(false);

  const [visible, setVisible] = useState(false);
  const [visibleSuccess, setVisibleSuccess] = useState(false);
  const [visibleConfirmAdd, setVisibleConfirmAdd] = useState(false);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [visibleFinish, setVisibleFinish] = useState(false);
  const [visiblePercentagePassword, setVisiblePercentagePassword] =
    useState(false);

  const [designatedSector, setDesignatedSector] = useState('');
  const [designatedSectorId, setDesignatedSectorId] = useState('');
  const [designatedSectorPercentage, setDesignatedSectorPercentage] =
    useState('');

  const [visiblePercentages, setVisiblePercentages] = useState(false);
  const [disabledVisiblePercentages, setDisabledVisiblePercentages] =
    useState(false);

  const [modalTitle, setModalTitle] = useState('');
  const [modalBtn, setModalBtn] = useState('');

  const [modalFinishTitle, setModalFinishTitle] = useState('');
  const [modalFinishBtn, setModalFinishBtn] = useState('');

  const [enableSave, setEnableSave] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [hasError, setHasError] = useState(false);

  const [createdSector, setCreatedSector] = useState(false);

  const [successText, setsuccessText] = useState(false);

  const [sectorToDelete, setSectorToDelete] = useState('');
  const [sectorIdToDelete, setSectorIdToDelete] = useState('');

  const [btnLoading, setBtnLoading] = useState(false);

  const [newSectorInfo, setNewSectorInfo] = useState({});

  const [newCommerce, setNewCommerce] = useState(false);
  const [displayPreConfig, setDisplayPreConfig] = useState('none');
  const [preConfigSector, setPreConfigSector] = useState(false);
  const [preConfigSectorState, setPreConfigSectorState] = useState(false);

  const [newArraySectors, setNewArraySectors] = useState([]);

  const [modalBlobError, setModalBlobError] = useState(false);
  const [configCommerceSectors, setConfigCommerceSectors] = useState('');
  const [loading, setLoading] = useState(true);
  const [showModify, setShowModify] = useState(false);
  const [showModifyValue, setShowModifyValue] = useState(false);
  const [justDeleted, setJustDeleted] = useState(false);

  useEffect(() => {
    const commerceData = localStorage.getItem('commerceName');
    setCommerceName(commerceData);

    if (!configCommerceSectors) {
      const user = localStorage.getItem('loggedUser');

      if (!user) {
        setHasError(false);
        setLoading(false);
      }

      setConfigCommerceSectors({
        url: `${process.env.REACT_APP_BFF_WEB_URL}/commerce/sectors/list`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${user}`,
          'Content-Type': 'application/json',
        },
      });
    }
  }, []);

  /// ///////////

  useEffect(() => {
    const show = JSON.parse(localStorage.getItem('showModifyPercentages'));
    setShowModifyValue(true);
    if (show) {
      setVisiblePercentages(true);
    } else {
      setVisiblePercentages(false);
    }
  }, [showModify]);

  /// ////////////
  useEffect(() => {
    const getSectors = async () => {
      const response = await AbstractService(configCommerceSectors);
      return response;
    };

    if (!configCommerceSectors) {
      // Incomplete configs
      return;
    }

    getSectors()
      .then((res) => {
        setArraySectors(res);
        setNewArraySectors(res);
        setLoading(false);
      })
      .catch(() => {});
  }, [configCommerceSectors]);

  useEffect(() => {
    const blob = JSON.parse(localStorage.getItem('sectorsAcceptedBlob'));

    setPreConfigSector(blob);
    setPreConfigSectorState(true);

    if (!preConfigSector && preConfigSectorState) {
      setNewCommerce(true);
      setDisplayPreConfig('block');
    } else {
      setNewCommerce(false);
      setDisplayPreConfig('none');
    }
  }, [preConfigSector, preConfigSectorState]);

  /// ///////////////
  const handleShowModify = () => {
    localStorage.setItem('showModifyPercentages', JSON.stringify(true));
    setShowModify(true);
  };

  const handleHideShowModify = () => {
    localStorage.setItem('showModifyPercentages', JSON.stringify(false));
    setShowModify(false);
  };
  /// ///////////

  const handleGoBack = () => {
    history.push('/configuracion');
  };

  const showModalSectores = (sector, sectorId, sectorPercentage) => {
    setVisibleSectores(true);
    setDesignatedSector(sector);
    setDesignatedSectorId(sectorId);
    setDesignatedSectorPercentage(sectorPercentage);
  };

  const hideModalSectores = () => {
    setVisibleSectores(false);
  };

  const showModalErrorBlob = () => {
    setModalBlobError(true);
  };

  const hideModalErrorBlob = () => {
    setModalBlobError(false);
  };

  const showEditPercentages = () => {
    handleShowModify();
    if (arraySectors.length <= 1) {
      setDisabledVisiblePercentages(true);
    } else {
      setVisiblePercentages(true);
    }
  };

  const handleSuccess = (value, arg, changes) => {
    switch (value) {
      case 'editSector':
        setVisible(false);
        // props.handleChange('editSector', changes);
        setModalTitle(`Cambiaste el porcentaje de propina para ${arg}`);

        const sumPercentages = 0;
        // props.array.forEach(sector => {
        //   sumPercentages += parseInt(sector.percentage);
        // });
        if (sumPercentages != 100) {
          setErrorMessage(
            `El total del porcentaje elegido tiene que ser igual a 100% (actualmente: ${sumPercentages})`,
          );
          setEnableSave(false);
        } else {
          setEnableSave(true);
          setErrorMessage('');
        }

        setModalBtn('Aceptar');
        setVisibleSuccess(true);
        setCreatedSector(false);
        setVisiblePercentages(false);
        break;
      case 'editSectorName':
        setVisible(false);
        handleChange('editSectorName', changes);
        setModalTitle('Cambio realizado con éxito');
        setModalBtn('Aceptar');
        setVisibleSuccess(true);
        setCreatedSector(false);
        setVisiblePercentages(false);
        break;
      case 'deleteSector':
        setVisibleDelete(false);
        setModalTitle('Cambio realizado con éxito');
        setsuccessText('');
        setSectorToDelete('');
        setModalBtn('Volver');
        setVisibleSuccess(true);
        setCreatedSector(false);
        setVisiblePercentages(false);
        setJustDeleted(true);

        break;
      case 'addSector':
        setVisibleConfirmAdd(false);
        setModalTitle('El sector se creó con éxito');
        setsuccessText(
          'Asegurate de modificar los porcentajes para que este sector empiece a funcionar.',
        );
        setSectorToDelete('');
        setModalBtn('Modificar porcentajes');
        setCreatedSector(true);
        setVisibleSuccess(true);
        setVisiblePercentages(false);
        setVisiblePassword(false);
        setVisibleAdd(false);
        break;
      default:
        throw new Error('Input inválido');
    }
  };

  const hideSuccess = () => {
    setVisibleSuccess(false);
  };

  const handleDeleteSector = (sector, id) => {
    setVisibleSectores(false);
    setSectorToDelete(sector);
    setSectorIdToDelete(id);
    setVisibleDelete(true);
  };

  const showFinishModal = () => {
    setVisibleFinish(true);
  };

  const hideFinishModal = () => {
    setVisibleFinish(false);
  };

  const showPercentagesPasswordModal = () => {
    setVisiblePercentagePassword(true);
  };

  const hidePercentagesPasswordModal = () => {
    setVisiblePercentagePassword(false);
  };

  const hideEditPercentages = () => {
    setVisiblePercentages(false);
  };

  const hideConfirmAdd = () => {
    setVisibleConfirmAdd(false);
  };

  const hidePassword = () => {
    setVisiblePassword(false);
  };

  const handleSavePercentageChanges = async (newPercentages) => {
    if (newPercentages) {
      const percentageSum = [];
      newPercentages.map((x) => percentageSum.push(parseInt(x.percentage)));
      const finalSum = percentageSum.reduce((a, b) => a + b);

      if (finalSum !== 100) {
        setErrorMessage(
          `El total del porcentaje elegido tiene que ser igual a 100% (actualmente ${finalSum}%)`,
        );
        setEnableSave(false);
      } else {
        setEnableSave(true);
        setErrorMessage('');

        const editSectorService = {
          url: `${process.env.REACT_APP_BFF_WEB_URL}/commerce/sectors/update`,
          method: 'PATCH',
          data: newPercentages,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('loggedUser')}`,
            'Content-Type': 'application/json',
          },
        };

        try {
          setBtnLoading(true);
          setHasError(false);
          await AbstractService(editSectorService);
          // setArraySectors(newPercentages);
          window.location.reload();
          localStorage.setItem('sector_config', JSON.stringify(newPercentages));
        } catch (error) {
          setHasError(true);
        }
        setBtnLoading(false);
        setModalFinishTitle('Cambio realizado con éxito');
        setModalFinishBtn('Aceptar');
        showFinishModal();
        setVisibleSuccess(false);
      }
    } else {
      setModalFinishTitle('Cambio realizado con éxito');
      setModalFinishBtn('Aceptar');
      showFinishModal();
      setVisibleSuccess(false);

      setVisibleConfirmAdd(false);
      setCreatedSector(false);
    }

    handleHideShowModify();
  };

  const handleConfirmAddSectorShift = (obj) => {
    setVisibleAdd(false);
    setNewSectorInfo(obj);
    setVisibleConfirmAdd(true);
    setVisiblePassword(true);
  };

  const handleAddSector = () => {
    setVisibleAdd(true);
  };

  const hideAddSector = () => {
    setVisibleAdd(false);
  };

  const handleAddArray = (value, arg) => {
    const arrayIds = [];
    arraySectors.forEach((x) => arrayIds.push(parseInt(x.id)));

    const maxId = Math.max(...arrayIds);
    const newId = maxId + 1;

    const sectorToAdd = {
      id: newId,
      // label: arg.label,
      // value: parseInt(arg.value),
      name: arg.name,
      percentage: arg.percentage,
    };
    const newArray = [...arraySectors, sectorToAdd];
    localStorage.setItem('sector_config', JSON.stringify(newArray));
    arraySectors.push(sectorToAdd);
  };

  const handleFilterArray = (value, id) => {
    const newArray = arraySectors.filter((x) => x.id !== id);
    // setConfigsectorsData(newArray);
    // setArraySectors(newArray);
    localStorage.setItem('sector_config', JSON.stringify(newArray));
    // window.location.reload();
  };

  const pushUpdateToBack = async (data) => {
    try {
      await AbstractService({
        url: `${process.env.REACT_APP_BFF_WEB_URL}/commerce/sectors/update`,
        method: 'PATCH',
        data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('loggedUser')}`,
          'Content-Type': 'application/json',
        },
      });
    } catch (err) {}
  };

  const handleAcceptPreconf = async (value) => {
    try {
      const body = {
        [`${value}_accepted`]: true,
        tc_accepted_datetime: new Date(),
      };

      const updatePreconfigService = {
        url: `${process.env.REACT_APP_BFF_WEB_URL}/commerce/blobs/update`,
        method: 'PATCH',
        data: body,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('loggedUser')}`,
          'Content-Type': 'application/json',
        },
      };

      await AbstractService(updatePreconfigService);
      setDisplayPreConfig('none');
      setNewCommerce(false);
      const finalBlob = true;
      localStorage.setItem('sectorsAcceptedBlob', JSON.stringify(finalBlob));
    } catch (error) {
      setHasError(true);
      setDisplayPreConfig('none');
      showModalErrorBlob();
    }
  };

  // EDITAR NOMBRE SECTOR
  const handleChange = (value, changeObj) => {
    switch (value) {
      case 'editSector':
        const indexArray = arraySectors.findIndex((x) => x.id === changeObj.id);
        const arrayToChange = arraySectors;
        // arrayToChange[indexArray].value = changeObj.value;
        arrayToChange[indexArray].percentage = changeObj.value;
        // setArraySectors(arrayToChange);
        window.location.reload();
        break;
      case 'editSectorName':
        // setConfigsectorsData([{ id: changeObj.id, name: changeObj.name }])

        // Updating on render
        // setArraySectors(A => A.map((sector => {
        //   if (sector.id === changeObj.id) {
        //     return { ...sector, name: changeObj.name }
        //   } else {
        //     return sector;
        //   }
        // })));

        arraySectors.forEach((x) => {
          if (x.id === changeObj.id) {
            x.name = changeObj.name;
          }
        });

        localStorage.setItem('sector_config', JSON.stringify(arraySectors));

        // Saving to backend
        pushUpdateToBack([{ id: changeObj.id, name: changeObj.name }]);

        break;
      default:
        throw new Error('Input inválido!');
    }
  };

  // RENDER GRID

  const renderGridItem = (x, id) => {
    return (
      <Grid item xs={4} key={id}>
        <div className='turnosYSectoresMainDiv'>
          <div className='turnosYSectoresTitleDiv'>
            <h2 className='turnosYSectoresCenterTitle'>{x.name}</h2>
            <p className='turnosYSectoresCenterParagraph'>
              {x.percentage}% de las propinas
            </p>
            <p
              className='turnosYSectoresEditar'
              style={{ alignSelf: 'normal', marginTop: -5 }}
              onClick={() => showModalSectores(x.name, x.id, x.percentage)}
            >
              Editar/Eliminar sector
            </p>
          </div>
        </div>
      </Grid>
    );
  };

  const renderGrid = () => {
    if (newPercentages.length >= 1) {
      // En caso de modificar los porcentajes se guardan en el storage y se muestran en el componente
      return newPercentages.map((x, id) => renderGridItem(x, id));
    }
    return arraySectors.map((x, id) => renderGridItem(x, id));
  };

  if (loading) {
    return <Loading />;
  }

  if (!commerceName && !preConfigSectorState && !showModifyValue) {
    return <Loading />;
  }
  //   if (loading && !commerceName && !preConfigSectorState && !showModifyValue) {
  //     return <Loading />;
  // }

  return (
    <div className='configuracionDiv'>
      <TopNavBar>
        <CommerceMenu>
          <ion-icon name='person-outline' className='userIcon' />
          <h4 className='topNavBarChildrenTitle'>{commerceName}</h4>
          <ion-icon name='chevron-down-outline' className='arrowDownIcon' />
        </CommerceMenu>
      </TopNavBar>
      <div className='mainContentConfiguracionDiv'>
        <SideBar />

        <div className='turnosYSectoresCardContainerDiv'>
          <div className='turnosYSectoresHeaderDiv'>
            <div>
              <div className='turnosYSectoresGoBackDiv' onClick={handleGoBack}>
                <ion-icon name='chevron-back-outline' size={16} />
                <p className='turnosYSectoresGoBackParagraph'>Volver</p>
              </div>

              <h1 className='turnosYSectoresTitle'>Sectores</h1>
              <p className='turnosYSectoresParagraph'>
                A continuación podes modificar los porcentajes de los distintos
                sectores.
              </p>
            </div>

            <div className='nuevoTurnoBtnDiv'>
              <button className='nuevoTurnoBtn' onClick={handleAddSector}>
                Nuevo sector
              </button>
            </div>
          </div>

          <Grid container spacing={2} paddingX='50px'>
            {renderGrid()}
          </Grid>

          {/* <p className='turnosYSectoresError'>{errorMessage}</p> */}
          <button
            onClick={showEditPercentages}
            className='turnosYSectoresSaveChanges'
          >
            Modificar porcentajes
          </button>

          {newCommerce && (
            <PreconfigShiftSectorModal
              shiftsData={false}
              displayPreconf={displayPreConfig}
              onClick={() => handleAcceptPreconf('sectors')}
            />
          )}

          <ErrorModal visible={modalBlobError} hideModal={hideModalErrorBlob} />

          <SectoresModal
            visible={visibleSectores}
            sector={designatedSector}
            sectorId={designatedSectorId}
            sectorPercentage={designatedSectorPercentage}
            showEditPercentages={showEditPercentages}
            handleSuccessSector={handleSuccess}
            handleDeleteSector={handleDeleteSector}
            hideModal={hideModalSectores}
            percentageArray={arraySectors} // props.array
          />

          <SectorPercentagesModal
            visible={visiblePercentages}
            btnLoading={btnLoading}
            handleSuccessSector={handleSavePercentageChanges}
            sectors={
              newPercentages.length >= 1 ? newPercentages : newArraySectors
            } // props.array
            hideModal={hideEditPercentages}
            showPercentagesPasswordModal={showPercentagesPasswordModal}
            hidePercentagesPasswordModal={hidePercentagesPasswordModal}
            visiblePercentagePassword={visiblePercentagePassword}
            handleHideShowModify={handleHideShowModify}
          />

          <SuccessModal
            visible={visibleSuccess}
            hideSuccess={hideSuccess}
            title={modalTitle}
            button={modalBtn}
            text={successText}
            createdSector={createdSector}
            editSectors={showEditPercentages}
            handleShowModify={handleShowModify}
            justDeleted={justDeleted}
          />

          <FinishModal
            visible={visibleFinish}
            hideFinishModal={hideFinishModal}
            title={modalFinishTitle}
            button={modalFinishBtn}
          />

          <DeleteSectorTurnoModal
            visible={visibleDelete}
            deleteSector={sectorToDelete}
            deleteSectorId={sectorIdToDelete}
            handleSuccess={handleSuccess}
            handleFilterArray={handleFilterArray}
          />

          <AddSectorTurnoModal
            visible={visibleAdd}
            hideModal={hideAddSector}
            array={arraySectors} // props.array
            // addShift={addShift}
            handleConfirmAddSectorShift={handleConfirmAddSectorShift}
          />

          <ConfirmSectorTurnoModal
            visible={visibleConfirmAdd}
            newSectorInfo={newSectorInfo}
            onSuccess={handleSuccess}
            handleAddArray={handleAddArray}
            visiblePassword={visiblePassword}
            hideConfirmAdd={hideConfirmAdd}
            hidePassword={hidePassword}
          />

          <ErrorPercentageModal
            visible={disabledVisiblePercentages}
            text='Para modificar el porcentaje debes tener al menos dos sectores.'
            button='Nuevo sector'
            handleAction={handleAddSector}
          />
        </div>
      </div>
    </div>
  );
}
