export default function MainContent({
  className,
  biggerTitleElement,
  bigTitleElement,
  smallTitleElement,
  cornerElement,
  children,
}) {
  return (
    <article className={className}>
      {biggerTitleElement}
      {bigTitleElement}
      {smallTitleElement}
      {children}
      {cornerElement}
    </article>
  );
}
