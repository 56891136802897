import TermsBlob from '../../components/Terms';
import TopNavBar from '../../components/TopNavBar';
import NavButton from '../../components/NavButton';

export default function TermsView(props) {
  return (
    <div>
      <TopNavBar>
        <NavButton to='/'>Volver</NavButton>
      </TopNavBar>
      <div style={{ margin: '100px 25px' }}>
        <TermsBlob />
      </div>
    </div>
  );
}
