import Blob from '../../Terms';
import './style.css';

export default function TermsModal(props) {
  return (
    <div className='modalTerms' onClick={props.toggle}>
      <div className='modalTermsContent'>
        <Blob />
      </div>
    </div>
  );
}
