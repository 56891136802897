import React from 'react';
import { useHistory } from 'react-router-dom';
import './style.css';
import iconSuccess from '../../../assets/icono-success.svg';

export default function SuccessModal(props) {
  const [display, setDisplay] = React.useState('none');
  const history = useHistory();

  const handleDisplay = () => {
    setDisplay('none');

    if (props.dismiss && props.dismissPrimaryModal) {
      props.dismiss();
      props.dismissPrimaryModal();
    }

    if (props.redirect) {
      history.push(props.redirect);
    } else if (props.refresh) {
      window.location.reload(true);
    } else if (props.createdSector) {
      props.editSectors();
    } else if (props.hideSuccess) {
      props.hideSuccess();
    }
  };

  const handleDisplayOut = () => {
    setDisplay('none');
    if (props.hideSuccess) {
      props.hideSuccess();
    }

    if (!props.justDeleted) {
      if (props.handleShowModify) {
        props.handleShowModify();
        window.location.reload();
      }
    }
  };

  React.useEffect(() => {
    if (props.visible) {
      setDisplay('block');
    }
  }, [props.visible]);

  return (
    <div
      style={{
        display,
        position: 'fixed',
        zIndex: 22,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        overflow: 'auto',
        backgroundColor: 'rgb(0,0,0)',
        backgroundColor: 'rgba(0,0,0,0.4)',
      }}
      onClick={props.hideSuccess ? handleDisplayOut : handleDisplay}
    >
      <div className='modalSuccessContent'>
        <div className='modalSuccess'>
          <img
            src={iconSuccess}
            className='modalSuccessImg'
            alt='Icon Confirmado'
          />
          <h2 className='modalSuccessText'>{props.title}</h2>
          {props.text ? (
            <p className='modalSuccessParagraph'>{props.text}</p>
          ) : null}
          <button
            className={
              props.editSectors
                ? 'modalSuccessConfirmSectors'
                : 'modalSuccessConfirm'
            }
            onClick={handleDisplay}
          >
            {props.button}
          </button>
        </div>
      </div>
    </div>
  );
}
