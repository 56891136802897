import React from 'react';
import './style.css';
import Fab from '@mui/material/Fab';

export default function AddRemoveButton({
  ariaLabel,
  onClick,
  icon,
  className,
}) {
  return (
    <div>
      <Fab
        aria-label={ariaLabel}
        sx={{
          width: 0,
          height: 0,
          backgroundColor: 'transparent',
        }}
        onClick={onClick}
      >
        <img
          src={icon}
          alt={`Icono ${ariaLabel}`}
          className={`buttonItemIcon buttonItemHover ${className}`}
        />
      </Fab>
    </div>
  );
}
