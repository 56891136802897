import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
} from '@material-ui/core';
import './style.css';

// import NewColaborador from "../../Modal/ColaboradoresModal/NewColaborador";
// import SuccessModal from "../../Modal/SuccessModal";
import Loading from '../../Loading';

function createData(
  name,
  nickname,
  id,
  sector,
  shift,
  edit,
  seeMore,
  avatar,
  fromHour,
  toHour,
  percentage,
  email,
) {
  return {
    name,
    nickname,
    id,
    sector,
    shift,
    edit,
    seeMore,
    avatar,
    fromHour,
    toHour,
    percentage,
    email,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', label: 'Nombre' },
  // { id: "alias", numeric: false, label: "Alias" },
  { id: 'id', numeric: false, label: 'ID Tipie' },
  { id: 'sector', numeric: false, label: 'Sector' },
  { id: 'turno', numeric: false, label: 'Turno' },
  { id: 'edit', numeric: false, label: null },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: 10,
  },
  paper: {
    width: '100%',
    boxShadow: '0px 3px 6px #00000029',
    marginBottom: 50,
  },
  table: {
    minWidth: 750,
    marginBottom: 20,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  editColumn: {
    color: '#E98A15',
    fontFamily: 'Inter',
    fontWeight: 500,
    cursor: 'pointer',
    fontSize: 16,
    textAlign: 'center',
  },
  disabledSeeMore: {
    color: '#E98A15',
    fontFamily: 'Inter',
    fontWeight: 500,
    cursor: 'pointer',
    fontSize: 16,
    textAlign: 'center',
    backgroundColor: '#c5c4c485',
  },
  normalColumn: {
    color: '#303336',
    fontFamily: 'Inter',
    fontSize: 16,
  },
  normalColumnAlias: {
    color: '#303336',
    fontFamily: 'Inter',
    fontSize: 16,
    cursor: 'pointer',
    width: '20%',
  },
  disabledColumn: {
    color: '#303336',
    backgroundColor: '#c5c4c485',
    fontFamily: 'Inter',
    fontSize: 16,
  },
}));

export default function EnhancedTable(props) {
  const [loading, setLoading] = React.useState(true);
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Editar alias
  // const [editAlias, setEditAlias] = React.useState(false);
  // const [userId, setUserId] = React.useState(NaN);

  // Modal para agregar empleado
  // const [visibleNew, setVisibleNew] = React.useState(false);
  // const [visibleSuccessModal, setVisibleSuccessModal] = React.useState(false);
  // const [modalTitle, setModalTitle] = React.useState('');
  // const [modalBtn, setModalBtn] = React.useState('');

  // Orden alfabético
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  //* Paginación
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const translatePagination = ({ from, to, count }) => {
    return `${from}-${to} de ${count}`;
  };

  //* Configuración datos de la tabla
  const rows = [];

  React.useEffect(() => {
    if (props.employeesList) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  });

  if (props.searchArray.length !== 0) {
    props.searchArray.map((x) => {
      x.edit = 'Editar';
      const fullname = `${x.user.name} ${x.user.last_name}`;
      rows.push(
        createData(
          fullname,
          x.nickname,
          x.id,
          x.sector?.name,
          x.shift?.name,
          x.edit,
          x.seeMore,
          x.user.profile_photo,
          x.shift?.from_hour,
          x.shift?.to_hour,
          x.sector?.percentage,
          x.user?.email,
        ),
      );

      x.edit = 'Editar';
      x.seeMore = 'Ver más';
    });
  } else {
    props.employeesList.map((x) => {
      x.edit = 'Editar';
      const fullname = `${x.user.name} ${x.user.last_name}`;
      rows.push(
        createData(
          fullname,
          x.nickname,
          x.id,
          x.sector?.name,
          x.shift?.name,
          x.edit,
          x.seeMore,
          x.user.profile_photo,
          x.shift?.from_hour,
          x.shift?.to_hour,
          x.sector?.percentage,
          x.user?.email,
        ),
      );

      x.edit = 'Editar';
      x.seeMore = 'Ver más';
      //  rows.push(createData(fullName, x.nickname, x.id, x.sector.name, x.shift.name, x.edit, x.seeMore))
    });
  }

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  // Editar alias
  // const handleEditAlias = (identif) => {
  //   setEditAlias(true);
  //   setUserId(identif);
  // };

  // const handleBlur = (e, identif) => {
  // mandar al back el e.target.value
  //   rows.forEach((x) => {
  //     if (x.id === identif) {
  //       if (e.target.value.length > 0) {
  //         x.alias = e.target.value;
  //       }
  //     }
  //   });
  //   setEditAlias(false);
  // }

  // const handleChange = (e, identif) => {
  //   if (e.keyCode === 13) {
  // mandar al back el e.target.value
  //     rows.forEach((x) => {
  //       if (x.id === identif) {
  //         if (e.target.value.length > 0) {
  //           x.alias = e.target.value;
  //         }
  //       }
  //     });
  //     setEditAlias(false);
  //   }
  // }
  // modal nuevo empleado
  // useEffect(() => {
  //   if (visibleNew) {
  //     setVisibleNew(false);
  //   } else if (visibleSuccessModal) {
  //     setVisibleSuccessModal(false);
  //   }
  // }, [visibleNew, visibleSuccessModal]);

  // const showModalNew = () => {
  //   setVisibleNew(true);
  // }
  // const successNewColaborador = () => {
  //   setVisibleNew(false);
  //   setModalTitle('¡Agregaste un nuevo colaborador!');
  //   setModalBtn('Aceptar');
  //   setVisibleSuccessModal(true);
  // };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={classes.root}>
      {props.employeesList.length !== 0 ? (
        <Paper className={classes.paper}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby='tableTitle'
              aria-label='enhanced table'
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow tabIndex={-1} key={row.id}>
                        <TableCell
                          component='th'
                          scope='row'
                          className={
                            row.name === '-'
                              ? classes.disabledColumn
                              : classes.normalColumn
                          }
                        >
                          {row.name}
                        </TableCell>

                        {/* <TableCell align="left" className={row.user === '-' ? classes.disabledColumn : classes.normalColumn}>{row.nickname === null || row.nickname.length == 1 ? '-' : row.nickname}</TableCell> */}
                        <TableCell
                          align='left'
                          className={
                            row.user === '-'
                              ? classes.disabledColumn
                              : classes.normalColumn
                          }
                        >
                          {row.id}
                        </TableCell>
                        <TableCell
                          align='left'
                          className={
                            row.user === '-'
                              ? classes.disabledColumn
                              : classes.normalColumn
                          }
                        >
                          {row.sector}
                        </TableCell>
                        <TableCell
                          align='left'
                          className={
                            row.user === '-'
                              ? classes.disabledColumn
                              : classes.normalColumn
                          }
                        >
                          {row.shift}
                        </TableCell>
                        <TableCell
                          align='left'
                          className={
                            row.name === '-'
                              ? classes.disabledSeeMore
                              : classes.editColumn
                          }
                          onClick={() =>
                            props.handleEdit(
                              row.name,
                              row.nickname,
                              row.id,
                              row.shift,
                              row.sector,
                              row.avatar,
                              row.fromHour,
                              row.toHour,
                              row.percentage,
                              row.email,
                            )
                          }
                        >
                          {row.name === '-' ? row.seeMore : row.edit}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            labelDisplayedRows={translatePagination}
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage='Mostrar'
          />
        </Paper>
      ) : (
        <div className='noEmployeesMainDiv'>
          {/* <div className="noEmployeesNav">
              <ul className="noEmployeesUl">
                <li className="noEmployeesLi">
                  Nombre
                  <div className="arrow-up"></div>
                </li>
                <li className="noEmployeesLi">
                  Alias
                  <div className="arrow-up"></div>
                </li>
                <li className="noEmployeesLi">
                  ID Tipie
                  <div className="arrow-up"></div>
                </li>
                 <li className="noEmployeesLi">
                  Sector
                  <div className="arrow-up"></div>
                </li>
                 <li className="noEmployeesLi">
                  Turno
                  <div className="arrow-up"></div>
                </li>
              </ul>
            </div> */}
          <div className='noEmployeesDiv'>
            <h5 className='noEmployeesText'>Todavía no hay colaboradores</h5>
          </div>
        </div>
      )}

      {/* Modal nuevo colaborador */}
      {/* <NewColaborador visible={visibleNew} successNewColaborador={successNewColaborador} />
      <SuccessModal visible={visibleSuccessModal} title={modalTitle} button={modalBtn} /> */}
    </div>
  );
}
