import TopNavBar from '../../components/TopNavBar';

export default function Support() {
  const mailSoporte = 'soporte@tipieapp.com';
  return (
    <div>
      <TopNavBar />
      <main style={{ margin: '100px 25px' }}>
        <h1 className='contactHeader'>Soporte</h1>
        <p>
          En caso de encontrar un problema técnico como usuario, podés
          escribirnos a{' '}
          <a className='contactLink' href={`mailto:${mailSoporte}`}>
            {mailSoporte}
          </a>
          .
        </p>
      </main>
    </div>
  );
}
