import React from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useHistory } from 'react-router-dom';
import { LoginService } from '../../../services/loginService';
import ModalActionBar from '../../ModalActionBar';
import CustomizedInputs from '../../Forms/CustomizedInputs';

export default function ConfirmPasswordModal(props) {
  // const [displayPassword, setDisplayPassword] = React.useState('none');
  const [password, setPassword] = React.useState('');
  const [disablePassword, setDisablePassword] = React.useState(true);
  const [errorPassword, setErrorPassword] = React.useState(false);

  const [display, setDisplay] = React.useState('none');
  const [displayLogout, setDisplayLogout] = React.useState('none');
  const [showLoading, setShowLoading] = React.useState(false);

  // EYE ICON
  const [visibilityPassword, setVisibilityPassword] = React.useState(false);

  const togglePassword = () => {
    if (visibilityPassword) {
      setVisibilityPassword(false);
    } else {
      setVisibilityPassword(true);
    }
  };
  // EYE ICON
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  React.useEffect(() => {
    if (password.length > 1) {
      setDisablePassword(false);
    } else {
      setDisablePassword(true);
    }
  }, [password]);

  const validatePassword = async () => {
    setDisablePassword(true);
    setShowLoading(true);
    const authBody = {
      userdata: localStorage.getItem('userdata'),
      password: password.trim(),
    };

    try {
      const res = await LoginService(authBody);
      if (res.status_code === 200) {
        setErrorPassword(false);
      }
      setShowLoading(false);
      setPassword('');
      return res;
    } catch (error) {
      setPassword('');
      setErrorPassword(true);
      setDisablePassword(false);
      setShowLoading(false);
    }
  };

  const handleDisplay = () => {
    setDisplay('none');
    setDisplayLogout('none');
    if (props.hideVisiblePassword) {
      props.hideVisiblePassword();
    }
  };

  const handlePrimaryClick = async () => {
    if (await validatePassword()) {
      if (props.onSuccess) {
        props.onSuccess();
        handleDisplay();
      }
    }
  };

  React.useEffect(() => {
    if (props.visiblePassword) {
      setDisplay('block');
    }
  }, [props.visiblePassword]);

  React.useEffect(() => {
    if (errorPassword) {
      setDisplayLogout('block');
    }
  }, [errorPassword]);

  const history = useHistory();

  const handleLogout = () => {
    localStorage.setItem('loggedUser', null);
    localStorage.setItem('username', null);
    history.push('login');
    if (props.hideVisiblePassword) {
      props.hideVisiblePassword();
    }
  };

  const handleLogoutModal = () => {
    setDisplayLogout('none');
    if (props.hideVisiblePassword) {
      props.hideVisiblePassword();
    }
    setDisplay('block');
  };

  return (
    <>
      {/* Modal confirmar contraseña */}
      <div
        style={{
          display,
          position: 'fixed',
          zIndex: 22,
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          overflow: 'auto',
          backgroundColor: 'rgb(0,0,0)',
          backgroundColor: 'rgba(0,0,0,0.4)',
        }}
        onClick={handleDisplay}
      >
        <div
          className='modalEditTurnoContent'
          onClick={(e) => {
            // do not close modal if anything inside modal content is clicked
            e.stopPropagation();
          }}
        >
          <h2 className='modalEditTurnoTitle'>Confirmar operación</h2>
          <div className='modalEditTurnoInput'>
            <CustomizedInputs
              label='Contraseña'
              password={!visibilityPassword ? 'password' : null}
              handlePassword={handlePassword}
              value={password}
            />
            {visibilityPassword ? (
              <VisibilityOffIcon className='eyeIcon' onClick={togglePassword} />
            ) : (
              <VisibilityIcon className='eyeIcon' onClick={togglePassword} />
            )}
          </div>

          <ModalActionBar
            onPrimaryClick={handlePrimaryClick}
            primaryDisabled={disablePassword}
            primaryLabel='Confirmar'
            onSecondaryClick={handleDisplay}
            loading={showLoading}
            btnLoading={props.btnLoading}
          />
        </div>
      </div>

      {/* Logout */}
      <div
        style={{
          display: displayLogout,
          position: 'fixed',
          zIndex: 22,
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          overflow: 'auto',
          backgroundColor: 'rgb(0,0,0)',
          backgroundColor: 'rgba(0,0,0,0.4)',
        }}
        onClick={handleLogoutModal}
      >
        <div
          className='modalEditTurnoContent'
          onClick={(e) => {
            // do not close modal if anything inside modal content is clicked
            e.stopPropagation();
          }}
        >
          <h2 className='modalEditTurnoTitle'>Contraseña incorrecta</h2>
          <p>
            Si olvidaste la contraseña podes restablecerla cerrando la sesión.
          </p>

          <ModalActionBar
            onPrimaryClick={handleLogout}
            primaryLabel='Cerrar sesión'
            secondaryLabel='Volver a intentarlo'
            onSecondaryClick={handleLogoutModal}
          />
        </div>
      </div>
    </>
  );
}
