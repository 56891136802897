/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Redirect } from 'react-router-dom';

import TopNavBar from '../../components/TopNavBar';
import SideBar from '../../components/Sidebar';
import CommerceMenu from '../../components/CommerceDropdown';
import ColaboradoresModal from '../../components/Modal/ColaboradoresModal';
import ConfirmEndShift from '../../components/Modal/ConfirmEndShift';
import ProfilePic from '../../components/ProfilePic';

import './style.css';

export default function EndShift({ content }) {
  const [commerceName, setCommerceName] = React.useState('');
  const [shiftName, setShiftName] = React.useState('');
  const [currentShiftToHour, setCurrentShiftToHour] = React.useState('');
  const [currentShiftFromHour, setCurrentShiftFromHour] = React.useState('');
  const [amount, setAmount] = React.useState('');
  const [twoSectors, setTwoSectors] = React.useState(false);
  const [visibleColaboradores, setVisibleColaboradores] = React.useState(false);
  const [visibleShift, setVisibleShift] = React.useState(false);
  const [sector, setSector] = React.useState(null);
  const [sectors, setSectors] = React.useState([]);
  const [approvedEmployeesDataList, setApprovedEmployeesDataList] =
    React.useState([]);
  const [firstLogin, setFirstLogin] = React.useState(false);
  const [activeShift, setActiveShift] = React.useState(null);
  const [isSearching, setIsSearching] = React.useState(false);
  const [filteredArray, setFilteredArray] = React.useState([]);
  const [goBack, setGoBack] = React.useState(false);

  const activeShiftData = content[0].apiMerchant[0]?.id;
  const approvedEmployees = content[0].employeesList;

  React.useEffect(() => {
    const commerceData = content[0].apiUsers;
    const commerceConfig = content[0].apiMerchant;
    const sectorConfig = commerceData.sectors;

    if (commerceConfig.length > 0) {
      setVisibleShift(false); // Doesn't open the shift selector

      const { shiftId } = commerceConfig[0];
      const currentShift = commerceData.shifts.filter(
        (x) => x.id === shiftId,
      )[0];
      setShiftName(currentShift?.name);
      setCurrentShiftToHour(currentShift?.to_hour);
      setCurrentShiftFromHour(currentShift?.from_hour);
      setAmount(commerceConfig[0].amount_partial);

      // Asign employee array for the turn to each sector
      if (!commerceConfig[0].employee_partial) {
        commerceConfig[0].employee_partial = [];
      }

      const shiftEmployees = [];

      commerceData.employees.forEach((registeredEmployee) => {
        const isPresent = commerceConfig[0].employee_partial.find(
          (activeEmployeeId) => {
            return activeEmployeeId === registeredEmployee.user_id;
          },
        );
        shiftEmployees.push({
          ...registeredEmployee,
          active: !!isPresent, // just the boolean, I don't want the whole object
        });
      });

      const updatedApprovedEmployeesDataList = [];

      sectorConfig.forEach((s) => {
        s.employees = shiftEmployees.filter(
          (employee) => employee.sector_id === s.id,
        );

        const approvedEmployeesData = approvedEmployees
          .filter((approvedEmployee) =>
            s.employees.some(
              (employee) =>
                employee.id === approvedEmployee.id && employee.status === 'APPROVED' && employee.active,
            ),
          )
          .map((employee) => employee.user);
        updatedApprovedEmployeesDataList.push(...approvedEmployeesData);
      });
      setApprovedEmployeesDataList(updatedApprovedEmployeesDataList);
    }
    setCommerceName(commerceData.name);
    setSectors(sectorConfig);
  }, [content]);

  const showModalColaboradores = (sec) => {
    setSector(sectors.find((element) => element.name === sec));
    setVisibleColaboradores(true);
    setActiveShift(activeShiftData);
  };

  const showModalShift = () => {
    setVisibleShift(true);
  };

  React.useEffect(() => {
    if (sectors.length === 2) {
      setTwoSectors(true);
    } else if (sectors.length === 3) {
      setTwoSectors(false);
    }
  }, [sectors]);

  React.useEffect(() => {
    if (visibleColaboradores) {
      setVisibleColaboradores(false);
    } else if (visibleShift) {
      setVisibleShift(false);
    }
  }, [visibleColaboradores, visibleShift]);

  const handleSearch = (e) => {
    const searchWord = e.target.value.toLowerCase();
    setFilteredArray(
      sectors.filter((x) => {
        return (
          x.name?.toLowerCase().includes(searchWord) ||
          x.employees.some(
            (employee) =>
              employee.user.name?.toLowerCase().includes(searchWord) ||
              employee.user.last_name?.toLowerCase().includes(searchWord),
          )
        );
      }),
    );
    setIsSearching(true);
  };

  const handleGoBack = () => {
    setGoBack(true);
  };

  if (goBack) {
    return <Redirect to='/home' />;
  }

  return (
    <div className='endShiftMainDiv'>
      <TopNavBar>
        <CommerceMenu>
          <ion-icon name='person-outline' className='userIcon' />
          <h4 className='topNavBarChildrenTitle'>{commerceName}</h4>
          <ion-icon name='chevron-down-outline' className='arrowDownIcon' />
        </CommerceMenu>
      </TopNavBar>
      <SideBar />
      <div className='mainContentEndShiftDiv'>
        <div className='endShiftContainer'>
          <div className='endShiftGoBackDiv' onClick={handleGoBack}>
            <ion-icon name='chevron-back-outline' size={16} />
            <p className='endShiftGoBackParagraph'>Volver</p>
          </div>
          <div className='endShiftHeaderDiv'>
            <div className='endShiftTitleDiv'>
              <h1 className='endShiftTitle'>Confirmar los colaboradores</h1>
              <p className='endShiftParagraph'>
                Por favor, verificá que estos sean los colaboradores que
                trabajaron en este turno antes de cerrarlo. Recordá que si hay
                algún sector sin ningún colaborador activo, su % se va a dividir
                por partes iguales en los demás sectores.
              </p>

              <div className='endShiftDivInput'>
                <label className='endShiftLabel'>
                  <ion-icon name='search-outline' />
                </label>
                <input
                  type='text'
                  className='endShiftInput'
                  placeholder='Buscar por colaborador o sector'
                  onChange={handleSearch}
                />
              </div>
            </div>
            <button
              type='button'
              className='endShiftBtn'
              onClick={showModalShift}
            >
              Cerrar turno
            </button>
          </div>
        </div>

        <div className='endShiftColaboradoresDiv'>
          {isSearching
            ? filteredArray.map((item) => (
                <div className='endShiftColaboradoresData' key={item.id}>
                  <p className='endShiftSectorTitle'>{`${item.name}: ${
                    item.employees.filter(
                      (employee) => employee.status === 'APPROVED' && employee.active,
                    ).length
                  }`}</p>
                  <p className='endShiftSectorPercentage'>{`(${item.percentage}% de las propinas)`}</p>
                  <p
                    className='endShiftSectorEdit'
                    onClick={() => showModalColaboradores(item.name)}
                  >
                    Editar colaboradores
                  </p>
                  {item.employees.map((employee) =>
                    employee.status === 'APPROVED' && employee.active ? (
                      <div
                        className='endShiftColaboradorItem'
                        key={employee.id}
                      >
                        <ProfilePic
                          name={`${employee.user.name[0]}${employee.user.last_name[0]}`}
                        />
                        <div className='endShiftColaboradorNameDiv'>
                          <p className='endShiftColaboradorNameText'>
                            {employee.user.name}
                          </p>
                          <p className='endShiftColaboradorNameText'>
                            {employee.user.last_name}
                          </p>
                          {employee.nickname ? (
                            <p className='endShiftColaboradorNameText endShiftColaboradorItemNickname'>{`Alias: ${employee.nickname}`}</p>
                          ) : null}
                        </div>
                      </div>
                    ) : null,
                  )}
                </div>
              ))
            : sectors.map((sect) => (
                <div className='endShiftColaboradoresData' key={sect.id}>
                  <p className='endShiftSectorTitle'>{`${sect.name}: ${
                    sect.employees.filter(
                      (employee) => employee.status === 'APPROVED' && employee.active,
                    ).length
                  }`}</p>
                  <p className='endShiftSectorPercentage'>{`(${sect.percentage}% de las propinas)`}</p>
                  <p
                    className='endShiftSectorEdit'
                    onClick={() => showModalColaboradores(sect.name)}
                  >
                    Editar colaboradores
                  </p>
                  {sect.employees.map((employee) =>
                    employee.status === 'APPROVED' && employee.active ? (
                      <div
                        className='endShiftColaboradorItem'
                        key={employee.id}
                      >
                        <ProfilePic
                          name={`${employee.user.name[0]}${employee.user.last_name[0]}`}
                        />
                        <div className='endShiftColaboradorNameDiv'>
                          <p className='endShiftColaboradorNameText'>
                            {employee.user.name}
                          </p>
                          <p className='endShiftColaboradorNameText'>
                            {employee.user.last_name}
                          </p>
                          {employee.nickname ? (
                            <p className='endShiftColaboradorNameText endShiftColaboradorItemNickname'>{`Alias: ${employee.nickname}`}</p>
                          ) : null}
                        </div>
                      </div>
                    ) : null,
                  )}
                </div>
              ))}
        </div>
      </div>

      {/* COLABORADORES MODAL  */}
      <ColaboradoresModal
        sector={sector}
        visible={visibleColaboradores}
        activeShift={activeShift}
        firstLogin={firstLogin}
      />

      {/* CONFIRMAR CIERRE DE TURNO  */}
      <ConfirmEndShift
        visible={visibleShift}
        goBack={handleGoBack}
        shiftName={shiftName}
        presentEmployeesData={approvedEmployeesDataList}
        shiftToHour={currentShiftToHour}
        shiftfromHour={currentShiftFromHour}
        account={amount}
        sectors={sectors}
      />
    </div>
  );
}
