import React from 'react';

import TopNavBar from '../../components/TopNavBar';
import CommerceMenu from '../../components/CommerceDropdown';
import SideBar from '../../components/Sidebar';
import Loading from '../../components/Loading';
import ErrorHoc from '../../components/ErrorMessage';

import { AbstractService } from '../../services/abstractService';
import Unauthorized from '../../hoc/unauthorized';

import ShiftHistory from './Historial';

import './style.css';

class Shift {
  constructor(shiftData, index) {
    this.ops = [];
    this.key = index;
    this.shiftId = shiftData.shiftId;
    this.shiftName = shiftData.shiftName;
    this.timestamp = shiftData.timestamp;
    this.totalAmount = shiftData.totalAmount;
    this.isActive = shiftData.isActive;
    this.isAwaitingResponse = false;

    this.abortLoad = () => {
      if (this.isAwaitingResponse) {
        this.isAwaitingResponse = false;
      }
    };

    this.fetchOps = async (handlerFx) => {
      const { ops } = this;
      if (ops.length > 0) {
        if (handlerFx) {
          handlerFx(ops);
        }
        return;
      }

      const { shiftId, isActive } = this;
      const user = localStorage.getItem('loggedUser');
      const configHistory = {
        url: `${process.env.REACT_APP_BFF_WEB_URL}/commerce/history/${shiftId}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${user}`,
          'Content-Type': 'application/json',
          type: isActive ? 'active' : 'historical',
        },
      };

      try {
        this.isAwaitingResponse = true;
        const Ops = await AbstractService(configHistory);
        if (this.isAwaitingResponse) {
          this.ops = Ops[0]?.operations_list ?? Ops;
          if (handlerFx) {
            handlerFx(this.ops);
          }
        }
      } catch (err) {
      } finally {
        this.isAwaitingResponse = false;
      }
    };
  }
}

export default function Historial() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [commerceData, setCommerceData] = React.useState({});
  const [activeShiftData, setActiveShiftData] = React.useState({});
  const [generalHistory, setGeneralHistory] = React.useState(null);
  const [formattedHistory, setFormattedHistory] = React.useState(null);
  const [hasError, setHasError] = React.useState(false);
  const [unauthorized, setUnauthorized] = React.useState(false);

  React.useEffect(() => {
    if (commerceData.name) {
      const user = localStorage.getItem('loggedUser');

      if (!user) {
        setUnauthorized(true);
        setHasError(false);
        setIsLoading(false);
        return;
      }

      const configHistory = {
        url: `${process.env.REACT_APP_BFF_WEB_URL}/commerce/history`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${user}`,
          'Content-Type': 'application/json',
        },
      };

      AbstractService(configHistory)
        .then((res) => setGeneralHistory(res))
        .catch((err) => {
          setHasError(true);
        });

      return;
    }

    const storedCommerceData = JSON.parse(
      localStorage.getItem('commerce_data'),
    );

    const storedShiftData = JSON.parse(
      localStorage.getItem('commerce_config'),
    )[0];

    const activeShiftPreset = storedCommerceData.shifts.find(
      (x) => x.id === storedShiftData?.shiftId,
    );

    setCommerceData(storedCommerceData);

    setActiveShiftData({
      shiftId: storedShiftData?.id,
      shiftName: activeShiftPreset?.name,
      timestamp: storedShiftData?.timestamp,
      totalAmount: storedShiftData?.amount_partial,
      isActive: true,
    });
  }, [commerceData, activeShiftData]);

  React.useEffect(() => {
    if (!generalHistory) {
      return;
    }

    // Transform the general history into shift objects, excluding the active shift.
    const oldShiftHistory = generalHistory
      .map(
        (x, i) =>
          new Shift(
            {
              shiftId: x.shift_id,
              shiftName: x.shift_name,
              timestamp: x.timestamp_start,
              totalAmount: x.total_amount,
              isActive: false,
            },
            i + 1,
          ),
      ) // Start indexing from 1 to avoid conflict with the active shift at index 0
      .sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1)); // Sort historical shifts

    // Convert the active shift data to a Shift object and prepend it to the sorted history.
    // Ensure activeShiftData is valid before converting.
    if (activeShiftData && activeShiftData.shiftId) {
      const activeShift = new Shift(activeShiftData, 0); // Active shift gets index 0
      setFormattedHistory([activeShift, ...oldShiftHistory]);
    } else {
      // If there's no active shift, just use the historical shifts
      setFormattedHistory(oldShiftHistory);
    }
  }, [generalHistory, activeShiftData]);

  React.useEffect(() => {
    if (!formattedHistory) {
      return;
    }

    setIsLoading(false);
  }, [formattedHistory]);

  if (unauthorized) {
    return <Unauthorized />;
  }
  if (isLoading) {
    return <Loading />;
  }
  if (hasError) {
    return <ErrorHoc views />;
  }

  return (
    <div className='historialDiv'>
      <TopNavBar>
        <CommerceMenu>
          <ion-icon name='person-outline' className='userIcon' />
          <h4 className='topNavBarChildrenTitle'>{commerceData.name}</h4>
          <ion-icon name='chevron-down-outline' className='arrowDownIcon' />
        </CommerceMenu>
      </TopNavBar>

      <div className='mainContentHistorialDiv'>
        <SideBar />

        {isLoading ? <Loading /> : <ShiftHistory history={formattedHistory} />}
      </div>
    </div>
  );
}
