import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const CssTextField = withStyles({
  root: {
    width: '100%',
    '& label.Mui-focused': {
      color: '#6A6A6A',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#E98A15',
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  rootError: {
    '& label.Mui-focused': {
      color: '#6A6A6A',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#C51111',
    },
  },
  input: {
    color: '#E98A15',
    fontFamily: 'Roboto, Regular',
  },
  inputError: {
    color: '#C51111',
    fontFamily: 'Roboto, Regular',
  },
}));

export default function CustomizedInputs(props) {
  const classes = useStyles();
  const [isFocus, setIsFocus] = useState(false);
  const [error, setError] = useState(true);

  const handleFocus = (props) => {
    if (error) {
      setIsFocus(true);
      setError(false);
      props.deleteError();
    }
  };

  useEffect(() => {
    setError(props.error);
  }, [props.error]);

  const handleEnter = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();

      if (props.handleSubmit) {
        props.handleSubmit(e);
      }
    }
  };

  return (
    <form className={error ? classes.rootError : classes.root} noValidate>
      <CssTextField
        autoComplete='off'
        inputProps={{ className: error ? classes.inputError : classes.input }}
        id='custom-css-standard-input'
        label={props.label}
        type={
          props.password || props.confirmPassword || props.newPassword
            ? 'password'
            : null
        }
        onFocus={() => handleFocus(props)}
        onKeyDown={handleEnter}
        onChange={
          props.handleUsername
            ? props.handleUsername
            : props.handleNewPassword
            ? props.handleNewPassword
            : props.handleConfirmPassword
            ? props.handleConfirmPassword
            : props.handlePassword
        }
        value={props.value ?? null}
      />
    </form>
  );
}
