import './style.css';
import { Link } from 'react-router-dom';
import logo2 from '../../assets/Logo.svg';

export default function TopNavBar(props) {
  const user = localStorage.getItem('loggedUser');
  return (
    <nav className={props.landing ? 'topNavLanding' : 'topNav'}>
      <Link to={user ? '/home' : '/'} className='tipieLogoNav'>
        <img
          src={logo2}
          alt='Tipie Logo'
          className={props.landing ? 'tipieLogoNavLanding' : 'tipieLogoNav'}
        />
      </Link>
      {props.children ?? null}
    </nav>
  );
}
