/* eslint-disable default-case */
import { useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
// import CustomizedInputs from '../../CustomizedInputs';
import CircularProgress from '@mui/material/CircularProgress';
import { AbstractService } from '../../../../services/abstractService';
import './style.css';

export default function ResetPassword(props) {
  const [btnBackground, setBtnBackground] = useState('#D1D1D1');
  const [disabled, setDisabled] = useState(true);
  const [isFocus, setIsFocus] = useState(false);
  const [error, setError] = useState(false);
  const [disableResend, setDisableResend] = useState(false);
  const [showLoadingOtp, setShowLoadingOtp] = useState(false);
  const [resendCount, setResendCount] = useState(0);

  const [otp, setOtp] = useState('');

  const refOne = useRef();
  const refTwo = useRef();
  const refThree = useRef();
  const refFour = useRef();
  const refFive = useRef();

  const handleOtpValue = (e, textInput) => {
    if (e.target.value.length === 1) {
      switch (textInput) {
        case 'one':
          refTwo.current.focus();
          setOtp(e.target.value + otp.slice(1, 6));
          break;
        case 'two':
          refThree.current.focus();
          setOtp(otp.slice(0, 1) + e.target.value + otp.slice(2, 6));
          break;
        case 'three':
          refFour.current.focus();
          setOtp(otp.slice(0, 2) + e.target.value + otp.slice(3, 6));
          break;
        case 'four':
          refFive.current.focus();
          setOtp(otp.slice(0, 3) + e.target.value + otp.slice(4, 6));
          break;
        case 'five':
          setOtp(otp.slice(0, 4) + e.target.value + otp.slice(5, 6));
          break;
      }
    } else if (e.target.value.length < 1) {
      setDisabled(true);
      setBtnBackground('#D1D1D1');
      switch (textInput) {
        case 'two':
          refOne.current.focus();
          break;
        case 'three':
          refTwo.current.focus();
          break;
        case 'four':
          refThree.current.focus();
          break;
        case 'five':
          refFour.current.focus();
          break;
      }
    }
  };

  const handleResend = () => {
    // setDisableResend(true);
    // props.resend();
    props.handleSubmitResetPassword();
    setResendCount(resendCount + 1);
    if (resendCount > 1) {
      setDisableResend(true);
    }
  };

  const handleFocus = () => {
    setIsFocus(true);
    setError(false);
  };

  useEffect(() => {
    if (otp.length === 5) {
      setDisabled(false);
      setBtnBackground('#E98A15');
    } else {
      setBtnBackground('#D1D1D1');
      setDisabled(true);
    }
  }, [otp]);

  const handleContinue = async () => {
    setDisabled(true);
    setShowLoadingOtp(true);
    setBtnBackground('#D1D1D1');
    try {
      const body = {
        receptor_email: props.usernameResetPassword,
        otp,
        commerce_id: props.accountId,
      };
      const sendOtpValidate = await AbstractService({
        url: `${process.env.REACT_APP_BFF_WEB_URL}/users/otp/validate`,
        method: 'post',
        data: body,
      });
      if (sendOtpValidate.status_code === 200) {
        props.pushToken(sendOtpValidate.session.payload.token);
        props.validationTrue();
      } else {
        setError(true);
      }
      setShowLoadingOtp(false);
    } catch (error) {
      setError(true);
      setShowLoadingOtp(false);
    }
  };

  return (
    <div>
      <p className='resetPasswordCodeParagraph'>Código de 5 dígitos</p>
      <div className='resetPasswordDiv'>
        <input
          className={
            isFocus && error
              ? 'resetPasswordInputError'
              : !error && isFocus
              ? 'resetPasswordInputFocus'
              : 'resetPasswordInput'
          }
          ref={refOne}
          maxLength='1'
          onFocus={handleFocus}
          onInput={(e) => handleOtpValue(e, 'one')}
        />
        <input
          className={
            isFocus && error
              ? 'resetPasswordInputError'
              : !error && isFocus
              ? 'resetPasswordInputFocus'
              : 'resetPasswordInput'
          }
          ref={refTwo}
          maxLength='1'
          onFocus={handleFocus}
          onInput={(e) => handleOtpValue(e, 'two')}
        />
        <input
          className={
            isFocus && error
              ? 'resetPasswordInputError'
              : !error && isFocus
              ? 'resetPasswordInputFocus'
              : 'resetPasswordInput'
          }
          ref={refThree}
          maxLength='1'
          onFocus={handleFocus}
          onInput={(e) => handleOtpValue(e, 'three')}
        />
        <input
          className={
            isFocus && error
              ? 'resetPasswordInputError'
              : !error && isFocus
              ? 'resetPasswordInputFocus'
              : 'resetPasswordInput'
          }
          ref={refFour}
          maxLength='1'
          onFocus={handleFocus}
          onInput={(e) => handleOtpValue(e, 'four')}
        />
        <input
          className={
            isFocus && error
              ? 'resetPasswordInputError'
              : !error && isFocus
              ? 'resetPasswordInputFocus'
              : 'resetPasswordInput'
          }
          ref={refFive}
          maxLength='1'
          onFocus={handleFocus}
          onInput={(e) => handleOtpValue(e, 'five')}
        />
      </div>
      <div
        className={
          error ? 'resetPasswordErrorDiv' : 'resetPasswordErrorDivNone'
        }
      >
        <ion-icon name='alert-circle-outline' style={{ color: '#C51111' }} />
        <p className='resetPasswordErrorText'>El código no es correcto</p>
      </div>
      {resendCount > 1 ? (
        <div className='resetPasswordErrorDiv'>
          <ion-icon name='alert-circle-outline' style={{ color: '#C51111' }} />
          <p className='resetPasswordErrorText'>
            Se excedieron los reintentos de envío, vuelva a intentar más tarde.
          </p>
        </div>
      ) : null}
      <div className='resetPasswordBtnDiv'>
        {resendCount <= 1 ? (
          <button
            className={
              disableResend
                ? 'resetPasswordResendDisabled'
                : 'resetPasswordResend'
            }
            disabled={disableResend}
            onClick={handleResend}
          >
            Reenviar código
          </button>
        ) : (
          <button
            className='resetPasswordResend'
            // disabled={disableResend}
            onClick={props.handleGoBack}
          >
            Volver
          </button>
        )}

        <button
          disabled={disabled}
          style={{
            marginRight: 30,
            marginTop: 8,
            marginBottom: 8,
            width: 167,
            padding: 0,
            backgroundColor: btnBackground,
            color: 'white',
            fontFamily: 'Inter, Semi Bold',
            fontWeight: 500,
            fontSize: 16,
            border: 'none',
            borderRadius: 24,
            cursor: disabled || error ? 'not-allowed' : 'pointer',
          }}
          onClick={handleContinue}
        >
          {showLoadingOtp ? (
            <div
              style={{
                paddingTop: 15,
                paddingBottom: 15,
              }}
            >
              <CircularProgress color='inherit' size={14} />
            </div>
          ) : (
            <p>Continuar</p>
          )}
        </button>
      </div>
    </div>
  );
}
