import React from 'react';
import Plus from '../../../../assets/PLUS-SIGN.jpeg';
import Minus from '../../../../assets/MINUS-SIGN.jpeg';

function conditionallyAffixZero(n, step = 0) {
  if (n === '') {
    return '0';
  }

  const intVal = parseInt(n) + step;
  return intVal;
}

export default function SectorConfigurator({
  name,
  percentage,
  updatedSectors,
  id,
  toggleDisabled,
}) {
  const [cutReadout, setCutReadout] = React.useState(percentage);
  const [disabledAdd, setDisabledAdd] = React.useState(false);
  const [disabledSub, setDisabledSub] = React.useState(false);
  const [finalArray, setFinalArray] = React.useState(updatedSectors);

  const addStep = () => updateReadout(cutReadout, 1);
  const substractStep = () => updateReadout(cutReadout, -1);

  const handleUpdate = (id, cut) => {
    finalArray.forEach((x) => {
      if (x.id === id) {
        x.percentage = cut;
        //   return {
        //   ...x,
        //   percentage: cut
        // }
      }
    });

    let sum = 0;
    finalArray.forEach((s) => (sum += parseInt(s.percentage)));

    if (sum == 100) {
      toggleDisabled(false);
    } else {
      toggleDisabled(true);
    }
  };

  React.useEffect(() => {
    if (parseInt(cutReadout) == 0) {
      setDisabledAdd(false);
      setDisabledSub(true);
    } else if (parseInt(cutReadout) == 100) {
      setDisabledAdd(true);
      setDisabledSub(false);
    } else {
      setDisabledAdd(false);
      setDisabledSub(false);
    }

    handleUpdate(id, cutReadout);
  }, [cutReadout, disabledAdd, disabledSub]);

  const updateNumeric = (val) => {
    if (val == '00') {
      return;
    }
    setCutReadout(val);
    handleUpdate(id, cutReadout);
  };

  const updateReadout = (val, step = 0) => {
    if (!parseInt(val) && parseInt(val) !== 0) {
      return;
    }

    if (val == 0 || val == 100) {
      setCutReadout(parseInt(val) + step);
      return;
    }

    setCutReadout(conditionallyAffixZero(val, step));

    handleUpdate(id, cutReadout);
  };

  const handleOnInput = (e) => {
    const numberRegex = /^\d+$/;
    const number = parseInt(e.target.value);

    if (numberRegex.test(number)) {
      if (e.target.value.length > 2) {
        if (number > 100) {
          const finalInput = [];
          finalInput.push(e.target.value[0]);
          finalInput.push(e.target.value[1]);
          return (e.target.value = finalInput.toString().replace(',', ''));
        }
      } else {
        return (e.target.value = e.target.value);
      }
    } else {
      return (e.target.value = '');
    }
  };

  const handleOnBlur = (e) => {
    if (e === '' || cutReadout === '') {
      return (e.target.value = percentage);
    }
  };

  return (
    <section className='SectorCutSection'>
      <h3 className='SectorCutName'>{name}:</h3>
      <div className='SectorCutInputFrame'>
        <div className='SectorCutButtonHolder'>
          <img
            src={Plus}
            style={{
              width: '55%',
              marginBottom: 10,
              cursor: !disabledAdd ? 'pointer' : 'default',
            }}
            onClick={!disabledAdd ? addStep : null}
            alt='Más'
          />
          <img
            src={Minus}
            style={{
              width: '55%',
              cursor: !disabledSub ? 'pointer' : 'default',
            }}
            onClick={!disabledSub ? substractStep : null}
            alt='Menos'
          />
        </div>
        <label className='SectorCutLabel'>
          Propina:
          <input
            className='SectorCutInput'
            type='text'
            inputMode='numeric'
            value={cutReadout}
            onInput={(e) => handleOnInput(e)}
            onChange={(e) => updateNumeric(e.target.value)}
            onBlur={(e) => handleOnBlur(e)}
          />
        </label>
        <span className='SectorCutDecoration'>%</span>
      </div>
    </section>
  );
}
